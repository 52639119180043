import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as aiSearchApi from 'api/aiSearch';
import * as aiSearchTransformers from 'api/aiSearch/transformers';
import { PromptResult, PromptResultResponseData } from 'api/aiSearch/types';
import { useUser } from 'components/Context/User';
import { AlertBar, Badge } from 'componentsNew';
import { AlertBarType } from 'componentsNew/AlertBar';
import { ReactComponent as CrossIcon } from 'componentsNew/Icon/Cross.svg';
import { Sheet } from 'layoutNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import { GAonAISendPromptClick } from 'utils/analytics';
import { GAonAIFeedbackClick } from 'utils/analytics';

import { AIChatBubblePrompt } from './AIChatBubblePrompt';
import { AIChatBubbleResult } from './AIChatBubbleResult';
import { AIChatDisclaimer } from './AIChatDisclaimer';
import { AIChatInput } from './AIChatInput';
import { AIChatIntroduction } from './AIChatIntroduction';

const elementId = 'ai-chat-sheet';

export type ChatItem =
  | { type: 'prompt'; prompt: string }
  | { type: 'result'; result: PromptResult };

type AIChatSheetProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const AIChatSheet = ({ open, setOpen }: AIChatSheetProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatInput, setChatInput] = useState<string>('');
  const [chatItems, setChatItems] = useState<ChatItem[]>([]);

  const [error, setError] = useState<{ open: boolean; message: string }>({
    open: false,
    message: '',
  });

  const user = useUser();
  const mountedRef = useRef<boolean>(true);
  const chatBottomRef = useRef<HTMLDivElement | null>(null);

  const isUserLoading = useMemo(
    () => user.firstLoad || user.isLoading,
    [user.firstLoad, user.isLoading]
  );

  const isUserProfileComplete = useMemo(
    () => Boolean(user.divisionId) && Boolean(user.countryId),
    [user.countryId, user.divisionId]
  );

  const fetchPromptResult = useCallback(
    async (text: string) => {
      try {
        const response = await aiSearchApi.sendPrompt({
          objectId: user.userId || '',
          prompt: text,
          dateTime: new Date().toString(),
          countryId: user.countryId || '',
          divisionId: user.divisionId || '',
          departmentId: user.departmentId || '',
          siteId: user.siteId || '',
          companyId: user.companyId || '',
          regionId: user.regionId || '',
        });
        if (!mountedRef.current) {
          return null;
        }
        const data = response.data.data as PromptResultResponseData;
        return aiSearchTransformers.transformPromptResult(data);
      } catch (e) {
        if (!mountedRef.current) {
          return null;
        }
        setError({
          open: true,
          message: translations.aiChatErrorGeneric,
        });
        return null;
      }
    },
    [
      user.userId,
      user.companyId,
      user.countryId,
      user.departmentId,
      user.divisionId,
      user.regionId,
      user.siteId,
    ]
  );

  const handlePromptSubmit = useCallback(async () => {
    if (isLoading || !chatInput || !isUserProfileComplete) {
      return;
    }
    GAonAISendPromptClick(chatInput);

    const chatItemPrompt: ChatItem = {
      type: 'prompt',
      prompt: chatInput,
    };
    const chatItemsWithPrompt = [...chatItems, chatItemPrompt];
    setChatItems(chatItemsWithPrompt);
    setChatInput('');

    setIsLoading(true);
    const result = await fetchPromptResult(chatItemPrompt.prompt);
    setIsLoading(false);

    if (!result) {
      return;
    }
    const chatItemResult: ChatItem = {
      type: 'result',
      result,
    };
    const chatItemsWithResult = [...chatItemsWithPrompt, chatItemResult];
    setChatItems(chatItemsWithResult);
  }, [
    chatInput,
    isLoading,
    isUserProfileComplete,
    chatItems,
    fetchPromptResult,
  ]);

  const setChatBottomRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      chatBottomRef.current = node;
      node.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    if (chatBottomRef.current && chatItems.length) {
      chatBottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatItems.length]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <AlertBar
        snackbar
        open={error.open}
        type={AlertBarType.Critical}
        text={error.message}
        onClose={() => setError({ ...error, open: false })}
      />
      <Sheet
        id={elementId}
        open={open}
        width="33.125rem"
        showCloseIcon={false}
        onClose={() => setOpen(false)}
        sx={() => ({
          backgroundColor: '#FFFFFFDD',
          backdropFilter: 'blur(4px)',
          padding: 0,
        })}
      >
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            color: theme.colors.text.brand,
            backgroundColor: theme.colors.surface.primary,
            padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
            gap: theme.spacing(0.5),
          })}
        >
          <Typography variant="h3" component="h2">
            {translations.aiChatTitle}
          </Typography>
          <Badge
            size="small"
            color="default"
            variant="filled"
            label={translations.aiChatSubtitle}
          />
          <Link
            id={`${elementId}-feedback`}
            target="_blank"
            typography="caption"
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=ycG98EhRhk-sQO3ZduGBTPyMUsgW0WBMjdyPw1IVrlBURThXVkRaVEowMzNTOVJQNjlQNVBRWEhBTy4u"
            onClick={GAonAIFeedbackClick}
            sx={(theme) => ({
              marginLeft: 'auto',
              marginRight: theme.spacing('sm'),
              fontWeight: 'bold',
            })}
          >
            {translations.aiChatFeedbackButton}
          </Link>
          <IconButton
            id={`${elementId}-close`}
            size="small"
            aria-label={translations.close}
            onClick={() => setOpen(false)}
          >
            <CrossIcon />
          </IconButton>
        </Stack>

        <TransitionGroup>
          {!chatItems.length && (
            <Collapse>
              <Stack
                sx={(theme) => ({
                  flexDirection: 'row',
                  justifyContent: 'center',
                  padding: theme.spacing('md'),
                })}
              >
                <AIChatIntroduction />
              </Stack>
            </Collapse>
          )}
        </TransitionGroup>

        {chatItems.length > 0 && (
          <Stack
            sx={(theme) => ({
              flexDirection: 'column',
              gap: theme.spacing('xs'),
              padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
              overflowY: 'auto',
            })}
          >
            {chatItems.map((chatItem, index) =>
              chatItem.type === 'prompt' ? (
                <AIChatBubblePrompt
                  key={`${elementId}-item-${index}`}
                  id={`${elementId}-item-${index}`}
                  prompt={chatItem.prompt}
                />
              ) : (
                <AIChatBubbleResult
                  key={`${elementId}-item-${index}`}
                  id={`${elementId}-item-${index}`}
                  result={chatItem.result}
                />
              )
            )}
            {isLoading && (
              <AIChatBubbleResult id={`${elementId}-item-loading`} loading />
            )}
            <Box ref={setChatBottomRef} />
          </Stack>
        )}

        <Stack
          sx={(theme) => ({
            gap: theme.spacing('xs'),
            padding: theme.spacing('md'),
            marginTop: 'auto',
          })}
        >
          {!isUserLoading && !isUserProfileComplete && (
            <AlertBar
              open
              fullWidth
              type={AlertBarType.Warning}
              title={translations.aiChatAlertIncompleteProfileTitle}
              text={translations.aiChatAlertIncompleteProfileText}
            />
          )}
          <AIChatInput
            loading={isLoading}
            disabled={!isUserProfileComplete}
            value={chatInput}
            onChange={setChatInput}
            onSubmit={handlePromptSubmit}
          />
          <AIChatDisclaimer />
        </Stack>
      </Sheet>
    </>
  );
};

export { AIChatSheet };
