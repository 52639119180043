import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import { Component } from 'react';

class FormButtons extends Component {
  render() {
    const yesButton = {
      color: 'black',
      title: 'Yes',
    };

    const noButton = {
      color: 'white',
      title: 'No',
    };

    return (
      <div className="m-b-0 article-button-wrapper">
        <Button
          onClick={this.props.submit}
          className="sm-m-b-2 m-r-2"
          color="black"
        >
          Submit
        </Button>
        <ButtonModalWrapper
          title="Are you sure you want to leave this page before saving?"
          modalChildren={null}
          yesButton={yesButton}
          noButton={noButton}
        >
          <Button
            onClick={this.props.cancel}
            className="sm-m-b-2"
            color="transparent"
          >
            Cancel
          </Button>
        </ButtonModalWrapper>
        {this.props.onDelete && (
          <ButtonModalWrapper
            title="Are you sure you want to delete this banner?"
            modalChildren={null}
            yesButton={yesButton}
            noButton={noButton}
          >
            <Button
              onClick={this.props.onDelete}
              className="sm-m-b-2"
              align="right"
              color="transparent"
            >
              Delete
            </Button>
          </ButtonModalWrapper>
        )}
      </div>
    );
  }
}

export default FormButtons;
