import './FilterBox.scss';

import { listCategories } from 'api/cms/portals';
import Error from 'components/Error';
import Select from 'components/Select';
import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GAonInsightsDatabaseFilter } from 'utils/analytics';

class SelectFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
      isFilterFilledOutside: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });

      if (
        this.props.selected.useFilter &&
        !this.props.selected.length &&
        !this.state.isFilterFilledOutside
      ) {
        this.setFilterByName(this.props.selected.useFilter);
      }
    }
  }

  setFilterByName = (name) => {
    const filter = this.props.options.find((obj) => obj.name === name);
    if (filter && !this.props.selected.length) {
      this.setState({ selected: filter, isFilterFilledOutside: true }, () => {
        this.props.onChange(filter);
      });
    }
  };

  render() {
    const { onChange, placeholder, multiple, options = [] } = this.props;
    const { selected } = this.state;

    return (
      <Select
        data={options.map((o) => ({
          name: o.name,
          value: o.value,
        }))}
        className={selected.length ? 'select--checked' : ''}
        onChange={onChange}
        placeholder={placeholder}
        selected={selected}
        multiple={multiple}
        deSelectEnabled={true}
      />
    );
  }
}

class FilterBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insightsCategories: null,
      insightsCategoriesLogicalNames: null,
      filterValues: null,
      error: false,
      errorCode: null,
    };

    this.aliasObjViceversa = {
      geographicMarkets: 'geographicmarket',
      productGroups: 'productgroup',
      topics: 'topic',
      types: 'type',
      verticalMarkets: 'verticalmarket',
      yearsRange: 'year',
    };

    this.aliasObj = {
      geographicmarket: 'geographicMarkets',
      productgroup: 'productGroups',
      topic: 'topics',
      type: 'types',
      verticalmarket: 'verticalMarkets',
      year: 'yearsRange',
    };
  }

  async componentDidMount() {
    const categoriesResponse = await this.getInsightsCategories();

    if (categoriesResponse) {
      let categories = this.addYearFilterToCategories(
        categoriesResponse.data.data.attributes.children
      );

      this.setInsightCategoriesState(categories);
      this.setInsightCategoriesLogicalNamesAndSelectedState(categories);
      this.setInitialState();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.props.location.search === ''
    ) {
      this.cleanFilters();
    }
  }

  getInsightsCategories = async () => {
    try {
      return await listCategories('insights-categories');
    } catch (e) {
      return this.setState({
        error: true,
        errorMessage: e.message,
      });
    }
  };

  getCategoryAlias = (category, viceversa) => {
    const alias = viceversa ? this.aliasObjViceversa : this.aliasObj;
    return alias[category];
  };

  getInsightsCategoriesSelected = (logicalNames, categories) => {
    let query = queryString.parse(this.props.location.search);
    if (query.search) {
      // delete search from data for filters
      delete query.search;
    }
    const categoriesKeys = Object.keys(logicalNames || {});
    let urlObj = {};
    let selectedCategories = {};

    // set filter title and id from url string
    for (let key in query) {
      let id = query[key];
      let categoryAlias = this.getCategoryAlias(key);
      if (categoryAlias) {
        let filterCategory = _.find(categories, (item) => {
          return item.attributes.logicalName === categoryAlias;
        });
        let filterObj = _.find(filterCategory.attributes.children, ['id', id]);
        if (filterObj) {
          urlObj[categoryAlias] = [
            {
              title: filterObj.attributes.title,
              id: filterObj.id,
            },
          ];
        }
      }
    }

    categoriesKeys.forEach((category) => {
      selectedCategories[category] = urlObj[category] || [];
    });

    return selectedCategories;
  };

  enableFilters = (filterMap) => {
    const enabledFiltersArr = [
      'types',
      'verticalMarkets',
      'productGroups',
      'geographicMarkets',
      'topics',
      'yearsRange',
    ];
    let enabledFilters = new Map();

    for (let enabledFilter of enabledFiltersArr) {
      enabledFilters.set(enabledFilter, filterMap.get(enabledFilter));
    }

    return enabledFilters;
  };

  setInsightCategoriesState = (categories) => {
    let unorderedObj = {};
    let orderedObj = new Map();

    categories.forEach((category) => {
      const { logicalName, sortIndex } = category.attributes;
      const children = (category.attributes.children || []).map((child) => {
        return {
          name: child.attributes.title,
          value: child.id,
        };
      });
      unorderedObj[logicalName] = {
        sortIndex: sortIndex,
        children: children,
      };
    });

    Object.keys(unorderedObj)
      .sort((a, b) => {
        return unorderedObj[a].sortIndex - unorderedObj[b].sortIndex;
      })
      .forEach((key) => {
        orderedObj.set(key, unorderedObj[key].children);
      });

    const enabledFilters = this.enableFilters(orderedObj);
    this.setState({ insightsCategories: enabledFilters });

    // set 2 additional lists for start page
    if (enabledFilters) {
      const topics = enabledFilters.get('topics');
      const internetOfThings = topics.find(
        (obj) => obj.name === 'Internet of Things'
      ).value;
      const smartHomes = topics.find((obj) => obj.name === 'Smart homes').value;
      const additionalLists = {
        iot: internetOfThings,
        sh: smartHomes,
      };
      this.props.onAdditionalLists(additionalLists);
    }
  };

  setInsightCategoriesSelectedState = (logicalNames, categories) => {
    const selectedCategories = this.getInsightsCategoriesSelected(
      logicalNames,
      categories
    );
    let obj = {};
    for (let key in selectedCategories) {
      let selectedCategory = selectedCategories[key];
      // if it has values
      if (selectedCategory) {
        obj[key] = [];
        selectedCategory.forEach((child) => {
          let childData = {
            name: child.title,
            value: child.id,
          };
          obj[key].push(childData);
        });
      }
    }
    this.setState({ insightsCategoriesSelected: obj });
  };

  setInsightCategoriesLogicalNamesAndSelectedState = (categories) => {
    let obj = {};
    let emptyObj = {};

    categories.forEach((category) => {
      const { logicalName, title } = category.attributes;
      obj[logicalName] = { id: category.id, title };
      emptyObj[logicalName] = [];
    });

    if (this.props.isCreating) {
      this.setState({
        insightsCategoriesLogicalNames: obj,
        insightsCategoriesSelected: emptyObj,
      });
    } else {
      // we set categories selected state only after we set logical names state
      this.setState({ insightsCategoriesLogicalNames: obj }, () => {
        this.setInsightCategoriesSelectedState(obj, categories);
      });
    }
  };

  setInitialState = () => {
    const filterValues = {
      ...this.state.insightsCategoriesSelected,
    };

    this.setState({ filterValues });
    const filterValue = {
      type: 'filter',
      value: filterValues,
    };
    this.props.onMount(filterValue);
  };

  setUrlOnFiltersUpdate = (values) => {
    let urlString = '';
    for (let key in values) {
      if (values[key].length) {
        urlString += `${this.getCategoryAlias(key, true)}=`;
        urlString += `${values[key][0].value}&`;
      }
    }
    urlString = urlString.slice(0, -1);
    const query = queryString.parse(this.props.history.location.search);

    if (query && query['search']) {
      let search = query['search'];
      if (urlString.length) {
        urlString += `&search=${search}`;
      } else {
        urlString += `search=${search}`;
      }
    }

    const path = this.props.history.location.pathname;
    this.setState({ filterString: `${urlString}` });
    this.props.history.replace(`${path}?${urlString}`);
  };

  setFilters = (filterKey, selected) => {
    GAonInsightsDatabaseFilter(filterKey);
    let selectedOptions = [];
    if (selected) {
      selectedOptions.push(selected);
    }

    const filterValues = {
      ...this.state.filterValues,
      [filterKey]: selectedOptions.map((s) => ({
        value: s.value,
        name: s.name.replace(/ \([0-9]*\)/g, ''),
      })),
    };

    this.setUrlOnFiltersUpdate(filterValues);
    this.setState({ filterValues });
    const filterValue = {
      type: 'filter',
      value: filterValues,
    };
    this.props.onChange(filterValue);
  };

  cleanFilters = () => {
    const filterList = this.state.filterValues;
    for (let key in filterList) {
      filterList[key] = [];
    }
    this.setUrlOnFiltersUpdate(filterList);
    this.setState({ filterValues: filterList });
    const filterValue = {
      type: 'filter',
      value: 'resetFilters',
    };
    this.props.onChange(filterValue);
  };

  setYearFilter = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2016;
    let yearRange = [];

    for (let i = startYear; i <= currentYear; i++) {
      yearRange.push({
        attributes: {
          title: `${i}`,
        },
        id: `${i}`,
      });
    }

    const yearFilter = {
      attributes: {
        children: yearRange,
        logicalName: 'yearsRange',
        sortIndex: 99,
        title: 'Year',
      },
      id: `yearsrangefrom${startYear}to${currentYear}`,
    };

    return yearFilter;
  };

  addYearFilterToCategories = (categories) => {
    const yearFilter = this.setYearFilter();
    categories.push(yearFilter);
    return categories;
  };

  render() {
    const filterOptions = this.state.insightsCategories;
    const filterPlaceholders = this.state.insightsCategoriesLogicalNames;
    const filterValues = this.state.filterValues;
    const { error, errorMessage } = this.state;
    const { useFilter } = this.props;
    const isLoading = !filterOptions;

    if (error) {
      return <Error message={errorMessage} />;
    }

    if (!filterOptions || !filterValues) {
      return null;
    }

    return (
      <div className="filter-box">
        <div className="filter-box__reset-panel">
          <span className="filter-box__reset-title">Refine your search</span>
          <button
            className="filter-box__reset-button"
            onClick={() => {
              this.cleanFilters();
            }}
          >
            Reset
          </button>
        </div>
        <div className="filter-box__fields-holder">
          {filterOptions &&
            Array.from(filterOptions.keys()).map((key) => (
              <div className="filter-box__field" key={key}>
                <p>{filterPlaceholders[key].title}</p>
                <SelectFilter
                  isLoading={isLoading}
                  options={filterOptions && filterOptions.get(key)}
                  placeholder={
                    filterPlaceholders[key].title === 'Geographic Market'
                      ? 'Any'
                      : 'All'
                  }
                  multiple={false}
                  selected={filterValues[key]}
                  onChange={(selected) => this.setFilters(key, selected)}
                  useFilter={useFilter}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

FilterBox.propTypes = {
  onChange: PropTypes.func,
  onMount: PropTypes.func,
  useFilter: PropTypes.string,
};

export default withRouter(FilterBox);
