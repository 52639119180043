import Checkbox from 'components/Checkbox';
import { HeroBannerConsumer } from 'components/Context/HeroBanner';
import Flex from 'components/Flex';

const OverrideDivisionalMessage = (props) => (
  <HeroBannerConsumer>
    {({ onToggleOverrideValue }) => (
      <>
        <Flex>
          <Checkbox
            defaultChecked={props.defaultChecked}
            id="overrideDivision"
            onChange={() => onToggleOverrideValue()}
          />
          <label htmlFor="overrideDivision" className="m-l-3">
            Override Divisional Message
          </label>
        </Flex>
        <hr className="m-t-6 m-b-6" />
      </>
    )}
  </HeroBannerConsumer>
);

export default OverrideDivisionalMessage;
