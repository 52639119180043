import './TopicsBox.scss';

import { getSmartHomesArticles } from 'api/cms/portals/';
import { getInternetOfThingsArticles } from 'api/cms/portals/';
import classNames from 'classnames';
import CommentLikeSummary from 'components/Comments/CommentLikeSummary';
import { UserConsumer } from 'components/Context/User';
import EnhancedLink from 'components/EnhancedLink';
import Fetcher from 'components/Fetcher';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import { Component } from 'react';
import TextClamp from 'react-string-clamp';
import { getDateOrDaysAgo } from 'utils/misc/time';

import ContentLoader from './ContentLoader';

class InsightsDatabaseTopicsBox extends Component {
  render() {
    const {
      className,
      isInitialState,
      smartHomesRequest,
      internetOfThingsRequest,
    } = this.props;
    const topicsBoxClassName = classNames('insights-database-topics-box', {
      [className]: className,
    });
    return (
      <>
        {isInitialState && (
          <div className={topicsBoxClassName}>
            {internetOfThingsRequest ? (
              <Fetcher
                source={getInternetOfThingsArticles}
                param={internetOfThingsRequest}
                onLoading={ContentLoader}
              >
                {({ response, meta }) =>
                  meta.total > 0 && (
                    <>
                      <div className="insights-database-topics-box__block">
                        <div className="insights-database-topics-box__panel">
                          <span className="insights-database-topics-box__title">
                            Discover Internet of Things
                          </span>
                          <span
                            className="insights-database-topics-box__main-link"
                            onClick={() => {
                              this.props.setFilter('Internet of Things');
                            }}
                          >
                            View all
                          </span>
                        </div>
                        <ul className="insights-database-topics-box__items">
                          {response.map((item, index) => (
                            <li
                              className="insights-database-topics-box__item"
                              key={index}
                            >
                              <EnhancedLink
                                className="insights-database-topics-box__item-link"
                                to={item.linkUrl}
                              >
                                {item.imageUrl && (
                                  <div className="insights-database-topics-box__image-holder">
                                    <Image
                                      src={`${item.imageUrl}/350`}
                                      alt={item.imageAltText}
                                      className="insights-database-topics-box__image"
                                    />
                                  </div>
                                )}
                                <div className="insights-database-topics-box__date-panel">
                                  <span className="insights-database-topics-box__type">
                                    {item.types &&
                                      item.types.map((type) => (
                                        <span key={type.id}>{type.title}</span>
                                      ))}
                                  </span>
                                  <span className="insights-database-topics-box__date">
                                    {getDateOrDaysAgo(item.published, false)}
                                  </span>
                                </div>
                                <TextClamp
                                  className="insights-database-topics-box__subtitle"
                                  lines={2}
                                  text={item.title}
                                />
                              </EnhancedLink>
                              <UserConsumer>
                                {(userContext) => {
                                  return (
                                    <div className="insights-database-topics-box__likes-and-comments">
                                      <CommentLikeSummary
                                        id={item.id}
                                        title={item.title}
                                        type={item.type}
                                        loggedInUserId={userContext.userId}
                                        article={item}
                                      />
                                    </div>
                                  );
                                }}
                              </UserConsumer>
                            </li>
                          ))}
                        </ul>
                        <span
                          className="insights-database-topics-box__main-link-mobile"
                          onClick={() => {
                            this.props.setFilter('Internet of Things');
                          }}
                        >
                          View all
                        </span>
                      </div>
                    </>
                  )
                }
              </Fetcher>
            ) : (
              <ContentLoader />
            )}
            {smartHomesRequest ? (
              <Fetcher source={getSmartHomesArticles} param={smartHomesRequest}>
                {({ response, meta }) =>
                  meta.total > 0 && (
                    <>
                      <div className="insights-database-topics-box__block">
                        <div className="insights-database-topics-box__panel">
                          <span className="insights-database-topics-box__title">
                            Discover Smart Homes
                          </span>
                          <span
                            className="insights-database-topics-box__main-link"
                            onClick={() => {
                              this.props.setFilter('Smart homes');
                            }}
                          >
                            View all
                          </span>
                        </div>
                        <ul className="insights-database-topics-box__items">
                          {response.map((item, index) => (
                            <li
                              className="insights-database-topics-box__item"
                              key={index}
                            >
                              <EnhancedLink
                                className="insights-database-topics-box__item-link"
                                to={item.linkUrl}
                              >
                                {item.imageUrl && (
                                  <div className="insights-database-topics-box__image-holder">
                                    <Image
                                      src={`${item.imageUrl}/350`}
                                      alt={item.imageAltText}
                                      className="insights-database-topics-box__image"
                                    />
                                  </div>
                                )}
                                <div className="insights-database-topics-box__date-panel">
                                  <span className="insights-database-topics-box__type">
                                    {item.types &&
                                      item.types.map((type) => (
                                        <span key={type.id}>{type.title}</span>
                                      ))}
                                  </span>
                                  <span className="insights-database-topics-box__date">
                                    {getDateOrDaysAgo(item.published, false)}
                                  </span>
                                </div>
                                <TextClamp
                                  className="insights-database-topics-box__subtitle"
                                  lines={2}
                                  text={item.title}
                                />
                              </EnhancedLink>
                              <UserConsumer>
                                {(userContext) => {
                                  return (
                                    <div className="insights-database-topics-box__likes-and-comments">
                                      <CommentLikeSummary
                                        id={item.id}
                                        title={item.title}
                                        type={item.type}
                                        loggedInUserId={userContext.userId}
                                        article={item}
                                      />
                                    </div>
                                  );
                                }}
                              </UserConsumer>
                            </li>
                          ))}
                        </ul>
                        <span
                          className="insights-database-topics-box__main-link-mobile"
                          onClick={() => {
                            this.props.setFilter('Smart homes');
                          }}
                        >
                          View all
                        </span>
                      </div>
                    </>
                  )
                }
              </Fetcher>
            ) : (
              (internetOfThingsRequest && !smartHomesRequest) ?? (
                <ContentLoader />
              )
            )}
          </div>
        )}
      </>
    );
  }
}

InsightsDatabaseTopicsBox.propTypes = {
  smartHomesRequest: PropTypes.string,
  internetOfThingsRequest: PropTypes.string,
  isInitialState: PropTypes.bool,
  className: PropTypes.string,
};

export default InsightsDatabaseTopicsBox;
