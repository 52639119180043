import './Iframe.scss';

import PropTypes from 'prop-types';

const Iframe = (props) => (
  <div className="iframe-container">
    <iframe title={props.title} {...props} />
  </div>
);

Iframe.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  allowFullScreen: PropTypes.bool,
};

Iframe.defaultProps = {
  allowFullScreen: true,
};

export default Iframe;
