import * as cmsContentManagementApi from 'api/cms/contentManagement';
import { FeedbackCount, FeedbackCountResponse } from 'api/feedback/types';
import { differenceInCalendarDays } from 'date-fns';
import { translations } from 'translations';

import { ArticleStatus } from './ManageLibraryFilter';
import {
  ManageLibraryTableField,
  ManageLibraryTableItem,
  SortOrder,
} from './ManageLibraryTable';

const getFormattedTitle = (title: string) => {
  if (title.indexOf('UNPUBLISHED__') === -1) {
    return title;
  }
  return title.substring(title.lastIndexOf('__') + 2);
};

const getFormattedDate = (dateStr: string, includeTime?: boolean) => {
  const date = new Date(dateStr);
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    return translations.notAvailable;
  }

  return includeTime
    ? date.toLocaleString(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
      })
    : date.toLocaleDateString();
};

const isDateSoon = (dateStr: string, nbrOfDays?: number) => {
  const date = new Date(dateStr);
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    return translations.notAvailable;
  }
  const now = new Date();
  const diff = differenceInCalendarDays(date, now);
  return diff < (nbrOfDays || 30);
};

const sortTableItems = (
  items: ManageLibraryTableItem[],
  sortField: ManageLibraryTableField,
  sortOrder: SortOrder
) => {
  switch (sortField) {
    case ManageLibraryTableField.Title:
      return items.sort((a, b) => {
        const _a = a.title || '';
        const _b = b.title || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageLibraryTableField.ExpiryDate:
      return items.sort((a, b) => {
        const _a = new Date(a.expiryDate || '');
        const _b = new Date(b.expiryDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageLibraryTableField.ModifiedDate:
      return items.sort((a, b) => {
        const _a = new Date(a.modifiedDate || '');
        const _b = new Date(b.modifiedDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageLibraryTableField.DeletionDate:
      return items.sort((a, b) => {
        const _a = new Date(a.deletionDate || '');
        const _b = new Date(b.deletionDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageLibraryTableField.InformationOwner:
      return items.sort((a, b) => {
        const _a = a.informationOwner?.name || '';
        const _b = b.informationOwner?.name || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    default:
      return items;
  }
};

const getFetchArticlesRequest = (
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  },
  sort: { field: ManageLibraryTableField; order: SortOrder },
  pagination: { page: number; rowsPerPage: number }
) => {
  let request = null;

  switch (filter.articleStatus) {
    case ArticleStatus.Published:
      request = filter.isAdmin
        ? cmsContentManagementApi.getPublishedLibraryPagesForAdmin
        : cmsContentManagementApi.getPublishedLibraryPages;
      break;
    case ArticleStatus.Unpublished:
      request = filter.isAdmin
        ? cmsContentManagementApi.getUnpublishedLibraryPagesForAdmin
        : cmsContentManagementApi.getUnpublishedLibraryPages;
      break;
    case ArticleStatus.Drafts:
      request = filter.isAdmin
        ? cmsContentManagementApi.getDraftLibraryPagesForAdmin
        : cmsContentManagementApi.getDraftLibraryPages;
      break;
    case ArticleStatus.Ownerless:
      request = filter.isAdmin
        ? cmsContentManagementApi.getOwnerlessLibraryPagesForAdmin
        : null;
      break;
  }
  if (!request) {
    return null;
  }

  let sortParam = `sort=`;

  if (sort.order === SortOrder.Desc) {
    sortParam = `${sortParam}-`;
  }
  switch (sort.field) {
    case ManageLibraryTableField.Title:
      sortParam = `${sortParam}title`;
      break;
    case ManageLibraryTableField.ExpiryDate:
      sortParam = `${sortParam}expiryDate`;
      break;
    case ManageLibraryTableField.ModifiedDate:
      sortParam = `${sortParam}modifiedDate`;
      break;
    case ManageLibraryTableField.DeletionDate:
      sortParam = `${sortParam}deletionDate`;
      break;
    case ManageLibraryTableField.InformationOwner:
      sortParam = `${sortParam}informationOwner`;
      break;
  }

  const limitParam = `page[limit]=${pagination.rowsPerPage}`;
  const offsetParam = `page[offset]=${pagination.page - 1}`;
  const queryParams = `?${sortParam}&${limitParam}&${offsetParam}`;

  return { request, queryParams };
};

const getFeedbackCountByArticleId = (response: FeedbackCountResponse) => {
  return response.data.data.reduce((prev, current) => {
    prev[current.articleId] = current;
    return prev;
  }, {} as Record<string, FeedbackCount>);
};

export {
  getFeedbackCountByArticleId,
  getFetchArticlesRequest,
  getFormattedDate,
  getFormattedTitle,
  isDateSoon,
  sortTableItems,
};
