import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { jobs } = settings.api;

export const headers = {
  'api-version': '2.0',
};

export const getPositions = (query = '', headers) =>
  createRequest({
    method: 'get',
    url: `${jobs}/positions${query}`,
    headers,
    errorMessages: {
      '*': 'Failed to get positions, please contact support for assistance.',
    },
  });

export const getPosition = (id, headers) =>
  createRequest({
    method: 'get',
    url: `${jobs}/positions/${id}`,
    headers,
    errorMessages: {
      '*': 'Failed to get position, please contact support for assistance.',
    },
  });

export const getSimilar = (query = '', headers) =>
  createRequest({
    method: 'get',
    url: `${jobs}/positions/similar${query}`,
    headers,
    errorMessages: {
      '*': 'Failed to get similar jobs, please contact support for assistance.',
    },
  });
