import './Breadcrumbs.scss';

import classNames from 'classnames';
import { CMSConsumer } from 'components/Context/Library';
import { Desktop } from 'components/Responsive';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items, path, includeHome, className, ghost }) => {
  const breadcrumbsClassName = classNames('breadcrumbs', {
    [className]: className,
  });
  if (ghost) {
    return (
      <Desktop>
        <ul className={breadcrumbsClassName}>
          <li>...</li>
        </ul>
      </Desktop>
    );
  }

  return (
    <Desktop>
      <ul className={breadcrumbsClassName}>
        {includeHome && (
          <li>
            <Link to="/">Home</Link>
          </li>
        )}
        {items.map(({ title, id }, i) => {
          return (
            <li key={i}>
              <Link to={id ? `${path}/${id}` : path}>{title.trim()}</Link>
            </li>
          );
        })}
      </ul>
    </Desktop>
  );
};

Breadcrumbs.propTypes = {
  ghost: PropTypes.bool,
  path: PropTypes.string,
  includeHome: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
    })
  ),
};

Breadcrumbs.defaultProps = {
  items: [],
  includeHome: false,
};

const LibraryBreadcrumbs = () => (
  <ul className="breadcrumbs">
    <CMSConsumer>
      {({ breadcrumbs, updateBreadcrumb }) =>
        breadcrumbs.map(({ title, link }, i, arr) => (
          <li key={link}>
            <Link
              to={`/library${link}`}
              onClick={() => {
                const newBreadcrumb = arr
                  .filter((_, index) => index <= i)
                  .filter((node) => node.type !== 'department');
                updateBreadcrumb(`/library${link}`, newBreadcrumb);
              }}
            >
              {title}
            </Link>
          </li>
        ))
      }
    </CMSConsumer>
  </ul>
);

export { Breadcrumbs, LibraryBreadcrumbs };
export default memo(Breadcrumbs);
