import { AlertDialog } from 'componentsNew';
import { AlertDialogType } from 'componentsNew/AlertDialog';
import { translations } from 'translations';

type DialogRepublishProps = {
  id: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DialogRepublish = ({
  id,
  open,
  loading,
  onClose,
  onConfirm,
}: DialogRepublishProps) => {
  return (
    <AlertDialog
      id={id}
      open={open}
      type={AlertDialogType.Warning}
      title={translations.manageContentActionsRepublishDialogTitle}
      paragraphs={[translations.manageContentActionsRepublishDialogText]}
      primaryButton={{
        loading: loading,
        disabled: loading,
        text: translations.manageContentActionsRepublish,
        onClick: onConfirm,
      }}
      secondaryButton={{
        disabled: loading,
        text: translations.cancel,
        onClick: onClose,
      }}
      {...(!loading && { onClose })}
    />
  );
};

export { DialogRepublish };
