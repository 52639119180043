import classNames from 'classnames';
import { ReactComponent as BellIcon } from 'componentsNew/Icon/Bell.svg';
import { ReactComponent as BellAlertIcon } from 'componentsNew/Icon/BellAlert.svg';

const NotificationButton = ({ onClick, newNotification }: any) => (
  <div
    className={classNames('notifications__icon-button', {
      [`notifications__icon-button--new-notification`]:
        Boolean(newNotification),
    })}
    onClick={onClick}
  >
    {newNotification ? <BellAlertIcon /> : <BellIcon />}
  </div>
);

export default NotificationButton;
