import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { InputProps } from '@mui/material/Input';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/system/styleFunctionSx';
import { AlertBar, Chip } from 'componentsNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translations } from 'translations';

import { AlertBarType } from './AlertBar';

export type SearchSelectOption<T = {}> = {
  name: string;
  value: string;
  element?: React.ReactNode;
  data?: T;
};

export type SearchSelectProps<T = {}> = {
  id?: string;
  limit?: number;
  multiple?: boolean;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  noResultsText?: string;
  selectedOptions: SearchSelectOption<T>[];
  inputProps?: InputProps['inputProps'];
  sx?: SxProps<Theme>;
  search:
    | ((value: string) => SearchSelectOption<T>[])
    | ((value: string) => Promise<SearchSelectOption<T>[]>);
  onChange: (selectedOptions: SearchSelectOption<T>[]) => void;
};

const SearchSelect = <T,>({
  id,
  limit = -1,
  multiple,
  error,
  disabled,
  placeholder,
  noResultsText,
  selectedOptions,
  inputProps,
  sx,
  search,
  onChange,
}: SearchSelectProps<T>) => {
  const [options, setOptions] = useState<SearchSelectOption<T>[]>([]);
  const [searchError, setSearchError] = useState<boolean>(false);

  const mountedRef = useRef<boolean>(true);

  const handleInputChange = useCallback(
    async (value: string) => {
      if (searchError) {
        if (!mountedRef.current) return;
        setSearchError(false);
      }
      try {
        const options = await search(value);
        setOptions(options);
      } catch {
        if (!mountedRef.current) return;
        setSearchError(true);
        setOptions([]);
      }
    },
    [search, searchError]
  );

  const handleSelect = useCallback(
    (value: SearchSelectOption<T> | SearchSelectOption<T>[] | null) => {
      let newSelectedOptions = value || [];
      if (!Array.isArray(newSelectedOptions)) {
        newSelectedOptions = [newSelectedOptions];
      }
      onChange(newSelectedOptions);
    },
    [onChange]
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const limitReached = useMemo(
    () => limit > -1 && selectedOptions && selectedOptions.length >= limit,
    [limit, selectedOptions]
  );

  const autocompleteOptions = useMemo(() => {
    return options.concat(selectedOptions || []);
  }, [options, selectedOptions]);

  const autocompleteValue = useMemo(
    () =>
      multiple
        ? selectedOptions || []
        : selectedOptions && Boolean(selectedOptions[0])
        ? selectedOptions[0]
        : null,
    [multiple, selectedOptions]
  );

  return (
    <Box
      sx={[
        { '.MuiAutocomplete-noOptions': { display: 'none' } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AlertBar
        snackbar
        open={searchError}
        type={AlertBarType.Critical}
        text={translations.searchSelectorFetchError}
        onClose={() => setSearchError(false)}
      />
      <Autocomplete
        multiple={multiple}
        id={id}
        size="small"
        fullWidth
        autoSelect
        disablePortal
        disabled={disabled}
        popupIcon={null}
        value={autocompleteValue}
        options={autocompleteOptions}
        filterSelectedOptions
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.name}
        blurOnSelect={(selectedOptions || []).length === limit - 1}
        isOptionEqualToValue={(option, selected) =>
          option.value === selected.value
        }
        onChange={(_e, value) => handleSelect(value)}
        onInputChange={(_e, value) => handleInputChange(value)}
        noOptionsText={noResultsText || translations.searchSelectorNoResults}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                ...inputProps,
                disabled: limitReached,
              }}
              placeholder={limitReached ? undefined : placeholder}
              error={error}
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.element || option.name}
            </li>
          );
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              color="primary"
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
            />
          ));
        }}
      />
    </Box>
  );
};

export { SearchSelect };
