import CircularProgress from '@mui/material/CircularProgress';
import withConsumer from 'components/Context/withConsumer';
import Error from 'components/Error';
import OnlyWithRole from 'components/OnlyWithRole';
import PageTitle from 'components/PageTitle';
import { SnackbarConsumer } from 'contextNew/Snackbar';
import { Component, createContext } from 'react';
import {
  deleteAllAttachments,
  deleteImageGalleryImages,
  deleteRemovedAttachments,
  replaceImage,
  saveImageGalleryFiles,
} from 'utils/cms/handleAttachments';

const { Provider, Consumer } = createContext();

/*
  This component acts as a form manager. Given a set of fields in the format
  [{key, defaultValue, required}] it populates default state (article) and updated
  state (updatedArticle). For each field there is a component which can be added
  as a child to createOrEditArticle. They use the context defined above to populate
  defaults and update their values.

  The context provides the following data
  - article - contains the actual back-end data as it currently stands (or default values in create)
  - updatedArticle - contains the updated values
  - showInitialError - set to true once the publish button is pressed. It is used to suppress errors
    before an initial publish or update action.
  - isCreating - if we are creating or editing an article
  - onChangeArticle - used to update any key in the updatedArticle
  - onToggleArticleValue - used to toggle any boolean value in the updatedArticle

  A majority of inputs keep track of their own states and only require default values, these should
  be taken from the article. Some do not keep track of their own state, they should use updatedArticle
  as their value. I.e  <Author defaultValue={article.author} /> vs <Author value={updatedArticle.author} />

  Intermediate fields can be used to create temporary variables that are needed for the
  form but are not used in the actual back-end data. These fields are marked intermediate=true
  and may have a getIntermediateDefault function which sets their default value in edit mode
  once the article is loaded. See publishedInGlobal for an example.

  Required fields should have a displayName defined. A validator function may optionally be provided.
  The function takes the updatedArticle and the CreateOrEditArticle component props as parameters and
  may return a validation error string. Null should be returned if no validation-error was encountered.
*/
class CreateOrEditArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      article: null,
      updatedArticle: null,
      isLoading: false,
      isFetchingArticle: true,
      error: null,
      validationParameters: {},
      validators: {},
      showInitialError: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { isCreating, fields } = this.props;

    const validationParameters = {};
    const validators = {};
    fields.forEach(({ key, required, maxLength, fieldValidator }) => {
      validationParameters[key] = { required, maxLength };
      validators[key] = { fieldValidator };
    });

    if (isCreating) {
      const article = fields.reduce((acc, { key, defaultValue }) => {
        acc[key] = defaultValue === undefined ? null : defaultValue;

        if (key === 'publishedDate') {
          acc[key] = new Date().toISOString();
        }
        return acc;
      }, {});

      // make sure we work on a copy to avoid strange errors
      const updatedArticle = JSON.parse(JSON.stringify(article));

      this.setState({
        isFetchingArticle: false,
        updatedArticle,
        article,
        validationParameters,
        validators,
      });
    } else {
      this.setState({ validationParameters, validators }, this.fetchContent);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  showError = (message, error) => {
    const { snackbarContext } = this.props;
    const errorMessage = error ? `${message} (${error.message})` : message;
    snackbarContext.showSnackbar({ text: errorMessage, type: 'error' });
  };

  fetchContent = async () => {
    try {
      const { onFetch, fields } = this.props;
      const response = await onFetch();
      const article = response.data.data;

      // add temporary intermediate values, they dont exist in the actual back-end data
      // but are necessary to handle the form properly
      fields.forEach(
        ({
          key,
          intermediate,
          defaultValue,
          getIntermediateDefault,
          defaultValueOnEdit,
        }) => {
          if (intermediate) {
            if (getIntermediateDefault) {
              article[key] = getIntermediateDefault(article);
            } else {
              article[key] = defaultValue === undefined ? null : defaultValue;
            }
          } else if (defaultValueOnEdit) {
            article[key] =
              defaultValueOnEdit === undefined ? null : defaultValueOnEdit;
          }
        }
      );

      // make sure we work on a copy to avoid strange errors
      const articleCopy = JSON.parse(JSON.stringify(article));

      const updatedArticle = fields.reduce((acc, { key }) => {
        acc[key] = articleCopy[key];

        return acc;
      }, {});

      this.asyncSetState({
        article,
        updatedArticle,
        isFetchingArticle: false,
      });
    } catch (articleLoadingError) {
      this.showError('Could not load article', articleLoadingError);

      this.asyncSetState({ articleLoadingError, isFetchingArticle: false });
    }
  };

  validateFields = (fields, updatedArticle) => {
    const missingFields = [];
    const invalidFields = [];
    const heroVideoAndImage = [];

    fields.forEach(
      ({ key, required, validator, fieldValidator, displayName, type }) => {
        const value = updatedArticle[key];

        if (required === true && !value) {
          missingFields.push(displayName);
        }

        if ((key === 'heroImage' || key === 'heroVideo') && type === 'story') {
          if (!value) {
            heroVideoAndImage.push(displayName);
          }
        }

        if (key === 'imageGalleryItems' && value) {
          for (const item of value) {
            if (item.isFile && item.image.error) {
              invalidFields.push(
                'Please verify that the images for the image gallery are either .jpg, .jpeg or .PNG and each image file has a maximum size of 2MB'
              );
              break;
            }
          }
        }

        if (validator instanceof Function) {
          const validationResult = validator(updatedArticle, this.props);
          if (validationResult !== null) {
            missingFields.push(validationResult);
          }
        }

        if (fieldValidator instanceof Function) {
          const validationResult = fieldValidator(updatedArticle[key]);
          if (validationResult !== null) {
            invalidFields.push(validationResult);
          }
        }
      }
    );

    if (heroVideoAndImage.length > 1) {
      missingFields.push('Hero image or hero video');
    }

    if (missingFields.length > 0) {
      this.showError(
        <span>
          The following fields appear to be missing:{' '}
          <strong>{missingFields.join(', ')}</strong>
        </span>
      );
    }

    if (invalidFields.length > 0) {
      this.showError(<span>{invalidFields.join(', ')}</span>);
    }

    return missingFields.length === 0 && invalidFields.length === 0;
  };

  transformFields = (updatedArticle, fields) => {
    fields.forEach(({ transform }) => {
      if (transform instanceof Function) {
        // Currently used to remove fields which are not relevant
        // due to display type in the customized block.
        transform(updatedArticle, this.props);
      }
    });
  };

  isFirstWizardPageValidated = (updatedArticle) => {
    const { fields } = this.props;

    // Sort out the fields we need for the first wizard page
    const sortedFields = fields.filter((field) => {
      return (
        field.key === 'title' ||
        field.key === 'preamble' ||
        field.key === 'goodToKnowBody'
      );
    });

    if (!this.validateFields(sortedFields, updatedArticle)) {
      this.setState({ showInitialError: true });
      return;
    }
    return true;
  };

  onSaveArticle = async (saveAsDraft) => {
    const { isCreating, fields, snackbarContext, useNewCreateEdit } =
      this.props;
    const { article, updatedArticle } = this.state;

    const articleToSave = { ...updatedArticle };
    articleToSave.isDraft = saveAsDraft;

    if (!this.validateFields(fields, articleToSave)) {
      this.setState({ showInitialError: true });
      return;
    }

    setTimeout(() => {
      if (this._isMounted) {
        snackbarContext.showSnackbar({
          text: isCreating ? 'Creating article...' : 'Updating article...',
        });
      }
    }, 5000);

    this.setState({ isLoading: true });

    if (!isCreating) {
      await deleteRemovedAttachments(articleToSave.body, article.body);
    }

    const { heroImage, heroImageLeft, heroImageRight } = articleToSave;

    if (heroImage && typeof heroImage === 'object') {
      const { url, width, height } = await replaceImage(
        article.heroImage,
        heroImage
      );

      articleToSave.heroVideo = null;
      articleToSave.heroImage = url;
      articleToSave.heroImageWidth = width;
      articleToSave.heroImageHeight = height;
    }

    if (heroImageLeft && typeof heroImageLeft === 'object') {
      const { url, width } = await replaceImage(
        article.heroImageLeft,
        heroImageLeft
      );

      articleToSave.heroVideoLeft = null;
      articleToSave.heroImageLeft = url;
      articleToSave.heroImageWidthLeft = width;
    }

    if (heroImageRight && typeof heroImageRight === 'object') {
      const { url, width } = await replaceImage(
        article.heroImageRight,
        heroImageRight
      );

      articleToSave.heroVideo = null;
      articleToSave.heroImageRight = url;
      articleToSave.heroImageWidthRight = width;
    }

    const savedImages = article['imageGalleryItems'] || [];
    const imageGalleryItems = articleToSave['imageGalleryItems'] || [];

    const hasImageGalleryChanged =
      imageGalleryItems.length !== savedImages.length ||
      imageGalleryItems.some((item) => item.path === undefined);

    if (hasImageGalleryChanged) {
      try {
        const savedImagesToDelete = savedImages.filter(
          (savedImage) =>
            !imageGalleryItems.some(
              (item) => item.image.path === savedImage.path
            )
        );

        const savedImagesToKeep = savedImages.filter((savedImage) =>
          imageGalleryItems.some((item) => item.image.path === savedImage.path)
        );

        const imageFilesToSave = imageGalleryItems
          .map((item) => (item.isFile ? item.image.file : null))
          .filter(Boolean);

        if (savedImagesToDelete.length > 0) {
          await deleteImageGalleryImages(savedImagesToDelete);
        }

        let newSavedImages = [];
        if (imageFilesToSave.length > 0) {
          const currentHighestSortIndex = Math.max(
            ...savedImages.map((path) => path.sortIndex),
            0
          );
          newSavedImages = await saveImageGalleryFiles(
            imageFilesToSave,
            currentHighestSortIndex
          );
        }

        articleToSave.imageGalleryItems =
          savedImagesToKeep.concat(newSavedImages);
      } catch (error) {
        this.asyncSetState({ isLoading: false });
        return this.showError(error.message);
      }
    }

    // delete intermediate values before sending to backend
    fields.forEach(({ key, intermediate }) => {
      if (intermediate) {
        delete articleToSave[key];
      }
    });
    this.transformFields(articleToSave, fields);

    if (useNewCreateEdit) {
      return this.createEditArticle(articleToSave, article);
    }
    return this.oldCreateEditArticle(articleToSave, article);
  };

  onSaveTextMedia = async () => {
    const { isCreating, fields, snackbarContext } = this.props;
    const { article, updatedArticle } = this.state;
    const payload = JSON.parse(JSON.stringify(article));

    if (!this.validateFields(fields, updatedArticle)) {
      this.setState({ showInitialError: true });
      return;
    }

    Object.keys(updatedArticle).forEach((key) => {
      payload[key] = updatedArticle[key];
    });

    const timeoutId = setTimeout(() => {
      if (this._isMounted) {
        snackbarContext.showSnackbar({
          text: isCreating ? 'Creating media...' : 'Updating media...',
        });
      }
    }, 5000);

    this.setState({ isLoading: true, timeoutId: timeoutId });

    if (!isCreating) {
      await deleteRemovedAttachments(payload.body, article.body);
    }

    const { heroImage, heroImageLeft, heroImageRight } = updatedArticle;

    if (heroImage && typeof heroImage === 'object') {
      const { url, width, height } = await replaceImage(
        article.heroImage,
        heroImage
      );

      payload.heroVideo = null;
      payload.heroImage = url;
      payload.heroImageWidth = width;
      payload.heroImageHeight = height;
    }

    if (heroImageLeft && typeof heroImageLeft === 'object') {
      const { url, width } = await replaceImage(
        article.heroImageLeft,
        heroImageLeft
      );

      payload.heroVideoLeft = null;
      payload.heroImageLeft = url;
      payload.heroImageWidthLeft = width;
    }

    if (heroImageRight && typeof heroImageRight === 'object') {
      const { url, width } = await replaceImage(
        article.heroImageRight,
        heroImageRight
      );

      payload.heroVideoRight = null;
      payload.heroImageRight = url;
      payload.heroImageWidthRight = width;
    }

    // delete intermediate values before sending to backend
    fields.forEach(({ key, intermediate }) => {
      if (intermediate) {
        delete payload[key];
      }
    });

    this.transformFields(payload, fields);
    return this.createEditMedia(payload);
  };

  onDeleteTextMedia = () => {
    const { onDelete, onAfterDelete, snackbarContext } = this.props;
    const { _doc, body, heroImage, heroImageLeft, heroImageRight } =
      this.state.article;

    const timeoutId = setTimeout(() => {
      if (this._isMounted) {
        snackbarContext.showSnackbar({
          text: this.props.deleteLoadingText || 'Deleting article...',
        });
      }
    }, 5000);

    this.setState({ isLoading: true, timeoutId: timeoutId });
    const deleteAttachmentPromises = [];

    heroImage &&
      deleteAttachmentPromises.push(deleteAllAttachments(body, heroImage));
    heroImageLeft &&
      deleteAttachmentPromises.push(deleteAllAttachments(body, heroImageLeft));
    heroImageRight &&
      deleteAttachmentPromises.push(deleteAllAttachments(body, heroImageRight));
    return Promise.all(deleteAttachmentPromises)
      .then(() => {
        return onDelete(_doc);
      })
      .then((response) => {
        snackbarContext.showSnackbar({
          text: this.props.deleteSuccessText || 'Article has been deleted',
          type: 'success',
        });
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        this.setState({ isLoading: false });
        onAfterDelete(response, _doc);
      })
      .catch((error) => {
        this.asyncSetState({ isLoading: false });
        this.showError(
          this.props.deleteFailureText || 'Could not delete article',
          error
        );
      });
  };

  createEditMedia = (updatedMedia) => {
    const { isCreating, onCreate, onEdit, onAfterCreate, snackbarContext } =
      this.props;

    const { timeoutId } = this.state;

    const message = isCreating ? 'create' : 'update';
    const changeFn = isCreating ? onCreate : onEdit;

    return changeFn(updatedMedia)
      .then((response) => {
        snackbarContext.showSnackbar({
          text: `Media has been ${message}d`,
          type: 'success',
        });
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        this.setState({ isLoading: false });
        onAfterCreate(response);
      })
      .catch((error) => {
        this.asyncSetState({ isLoading: false });

        if (error.response?.status === 409) {
          return this.showError(
            'A page with this title already exists. Please change the title or contact support avenue@assaabloy.com'
          );
        }

        return this.showError(`Could not ${message} media`, error);
      });
  };

  // currently all but portals use this but it should be fixed
  oldCreateEditArticle = async (updatedArticle, article) => {
    const { isCreating, onCreate, onEdit, onAfterCreate, snackbarContext } =
      this.props;

    let articleId = article._doc;
    if (isCreating) {
      try {
        const createArticleResponse = await onCreate({
          ...updatedArticle,
          // temporary fix. The guest author contains an image, so its quite large
          // so its expensive to send twice. Ideally future solution makes it possible
          // to only use create with the BE...
          guestAuthor: null,
        });
        articleId = createArticleResponse.data.data._doc;
      } catch (e) {
        this.asyncSetState({ isLoading: false });

        if (e.response?.status === 409) {
          return this.showError(
            'A page with this title already exists. Please change the title or contact support avenue@assaabloy.com'
          );
        }
        return this.showError('Could not create article', e);
      }
    }
    try {
      const newArticle = await onEdit(articleId, updatedArticle);
      const { path } = newArticle.data.data;
      if (updatedArticle.isDraft) {
        snackbarContext.showSnackbar({
          text: 'The draft was saved successfully. Manage your drafts on your Profile Settings page.',
          type: 'success',
        });
      } else if (isCreating) {
        snackbarContext.showSnackbar({
          text: 'Article has been created',
          type: 'success',
        });
      } else {
        if (article.isDraft && !updatedArticle.isDraft) {
          snackbarContext.showSnackbar({
            text: 'Article has been published',
            type: 'success',
          });
        } else {
          snackbarContext.showSnackbar({
            text: 'Article has been saved',
            type: 'success',
          });
        }
      }

      onAfterCreate(
        {
          ...updatedArticle,
          id: articleId,
          path,
        },
        article
      );
    } catch (e) {
      this.asyncSetState({ isLoading: false });

      if (e.response?.status === 409) {
        return this.showError(
          'A page with this title already exists. Please change the title or contact support avenue@assaabloy.com'
        );
      }

      if (e.response?.status === 423) {
        return this.showError(
          'One or more document is open in another program. Please close the documents and try again.'
        );
      }
      return this.showError('Could not update article', e);
    }
  };

  createEditArticle = (updatedArticle, article) => {
    const { isCreating, onCreate, onEdit, onAfterCreate, snackbarContext } =
      this.props;
    const message = isCreating ? 'create' : 'update';
    const changeFn = isCreating ? onCreate : onEdit;

    return changeFn(updatedArticle)
      .then((response) => {
        if (updatedArticle.isDraft) {
          snackbarContext.showSnackbar({
            text: 'The draft was saved successfully. Manage your drafts on your Profile Settings page.',
            type: 'success',
          });
        } else if (article.isDraft && !updatedArticle.isDraft) {
          snackbarContext.showSnackbar({
            text: 'Article has been published',
            type: 'success',
          });
        } else {
          snackbarContext.showSnackbar({
            text: `Article has been ${message}d`,
            type: 'success',
          });
        }

        onAfterCreate(response);
      })
      .catch((error) => {
        this.asyncSetState({ isLoading: false });

        if (error.response?.status === 409) {
          return this.showError(
            'A page with this title already exists. Please change the title or contact support avenue@assaabloy.com'
          );
        }

        if (error.response?.status === 423) {
          return this.showError(
            'One or more document is open in another program. Please close the documents and try again.'
          );
        }

        return this.showError(`Could not ${message} article`, error);
      });
  };

  onDeleteArticle = () => {
    this.setState({ isLoading: true });

    const { onDelete, onAfterDelete, snackbarContext } = this.props;
    const { _doc, body, heroImage, heroImageLeft, heroImageRight } =
      this.state.article;

    setTimeout(() => {
      if (this._isMounted) {
        snackbarContext.showSnackbar({
          text: this.props.deleteLoadingText || 'Deleting article...',
        });
      }
    }, 5000);

    const deleteAttachmentPromises = [];

    heroImage &&
      deleteAttachmentPromises.push(deleteAllAttachments(body, heroImage));
    heroImageLeft &&
      deleteAttachmentPromises.push(deleteAllAttachments(body, heroImageLeft));
    heroImageRight &&
      deleteAttachmentPromises.push(deleteAllAttachments(body, heroImageRight));
    return Promise.all(deleteAttachmentPromises)
      .then(() => {
        return onDelete(_doc);
      })
      .then((response) => {
        snackbarContext.showSnackbar({
          text: this.props.deleteSuccessText || 'Article has been deleted',
          type: 'success',
        });
        onAfterDelete(response);
      })
      .catch((error) => {
        this.asyncSetState({ isLoading: false });
        this.showError(
          this.props.deleteFailureText || 'Could not delete article',
          error
        );
      });
  };

  onChangeArticle = (values) => {
    this.setState(({ updatedArticle }) => ({
      updatedArticle: { ...updatedArticle, ...values },
    }));
  };

  onToggleArticleValue = (key) => {
    this.setState(({ updatedArticle }) => ({
      updatedArticle: { ...updatedArticle, [key]: !updatedArticle[key] },
    }));
  };

  onAfterCancel = () => {
    const { article } = this.state;
    const { onAfterCancel } = this.props;
    onAfterCancel(article);
  };

  render() {
    const {
      viewableByRole,
      location: { pathname },
      ContentLoader,
      isCreating,
      pageTitle,
      children,
    } = this.props;

    const {
      isLoading,
      isFetchingArticle,
      articleLoadingError,
      showInitialError,
      article,
      updatedArticle,
      validationParameters,
      validators,
    } = this.state;

    if (isFetchingArticle) {
      return <ContentLoader />;
    }

    if (articleLoadingError) {
      return (
        <Error
          errorMessage={articleLoadingError.message}
          status={articleLoadingError.response.status}
          redirectOn404={true}
        />
      );
    }

    return (
      <OnlyWithRole
        redirectTo={pathname.replace(/\/(edit|create)$/g, '')}
        viewableByRole={viewableByRole}
      >
        <PageTitle
          titles={[
            pageTitle,
            isCreating ? null : article.title,
            isCreating ? 'Create' : 'Edit',
          ]}
        />
        <Provider
          value={{
            article,
            updatedArticle,
            validationParameters,
            validators,
            showInitialError,
            isCreating,
            onDeleteArticle: this.onDeleteArticle,
            onDeleteTextMedia: this.onDeleteTextMedia,
            onSaveArticle: this.onSaveArticle,
            isFirstWizardPageValidated: this.isFirstWizardPageValidated,
            onChangeArticle: this.onChangeArticle,
            onToggleArticleValue: this.onToggleArticleValue,
            onSaveTextMedia: this.onSaveTextMedia,
            onAfterCancel: this.onAfterCancel,
          }}
        >
          <div style={{ position: 'relative' }}>
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  background: 'rgba(255, 255, 255, 0.5',
                  height: '100%',
                  width: '100%',
                  zIndex: 2,
                }}
              >
                <CircularProgress
                  size={64}
                  sx={{
                    position: 'absolute',
                    flexShrink: 0,
                    top: 'calc(50% - 32px)',
                    left: 'calc(50% - 32px)',
                  }}
                />
              </div>
            )}
            {children}
          </div>
        </Provider>
      </OnlyWithRole>
    );
  }
}

export { Consumer };

export default withConsumer(
  SnackbarConsumer,
  CreateOrEditArticle,
  'snackbarContext'
);
