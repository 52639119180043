import './SearchField.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Suggestion from 'api/models/Suggestion';
import classnames from 'classnames';
import { UserContext } from 'components/Context/User';
import { ReactComponent as CancelIcon } from 'components/Icon/Cancel.svg';
import { ReactComponent as SearchIcon } from 'components/Icon/Search.svg';
import { KeyboardKey } from 'constants/keyboard';
import { History } from 'history';
// TODO: Should not use untyped packages in typescript files
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import debounce from 'lodash.debounce';
import SuggestionsList from 'pages/Portals/Search/Suggestions/SuggestionsList/SuggestionsList';
import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import settings from 'settings';
import {
  GAonClickPortalSearchResultSuggestion,
  GAonPortalSearchSubmit,
  getPortalType,
} from 'utils/analytics';
import { formatSearchQuery, getSuggestions } from 'utils/misc/autoSuggest';

interface SearchFieldProps {
  submitQuery: (searchQuery: string) => void;
  history: History;
  portalType: string;
}

const SearchField = ({
  submitQuery,
  history,
  portalType,
}: SearchFieldProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [justSelectedSuggestionIndex, setJustSelectedSuggestionIndex] =
    useState<number | null>(null);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [disableSearchQuery, setDisableSearchQuery] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const user = useContext(UserContext);
  const { portals } = settings;

  useEffect(() => {
    if (!searchRef.current) return;
    searchRef.current.focus();
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (disableSearchQuery) return;
    const searchQuery = history.location.search.split('=').pop();
    if (!searchQuery) return;
    setSearchQuery(decodeURIComponent(searchQuery));
  }, [disableSearchQuery, history.location.search]);

  const updateUrl = (history: History, searchQuery: string) => {
    const startPageId = portals.find((p: any) => p.url === 'innovation').id;
    const baseUrl = `/portals/innovation/${startPageId}`;
    if (history.location.search === `?search=${searchQuery}`) return;
    history.push({
      pathname: baseUrl,
      search: `?search=${searchQuery}`,
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
  };

  const handleInputFocus = () => {
    if (isMounted) {
      onSuggestionsFetchRequested('innovationportal', searchQuery);
    }
  };

  const handleInputBlur = () => {
    setSuggestions([]);
    setJustSelectedSuggestionIndex(null);
  };

  const handleSubmitQuery = (value: string) => {
    const portal = getPortalType(portalType);
    setSuggestions([]);
    submitQuery(value);
    updateUrl(history, value);
    GAonPortalSearchSubmit(value, portal, portalType);
  };

  const resetQuery = () => {
    if (isMounted) {
      setSuggestions([]);
      setSearchQuery('');
    }
  };

  const onSuggestionsFetchRequested = useMemo(() => {
    return debounce((type: string, searchQuery: string) => {
      const {
        divisionId: division,
        countryId: country,
        regionId: region,
        departmentId: department,
      } = user;
      const filter = {
        division,
        country: division && country ? country : undefined,
        region: division && region ? region : undefined,
        department: department,
        type,
        portal: '',
        innovationSearch: true,
      };
      if (type === 'innovationportal') {
        filter.type = '[portalarticle,document]';
        filter.portal = 'innovation';
      }

      getSuggestions(searchQuery, filter).then((suggestions: Suggestion[]) => {
        if (suggestions && suggestions.length > 20) {
          suggestions.length = 20;
        }
        setSuggestions(suggestions || []);
      });
    }, 150);
  }, [user]);

  useEffect(() => {
    onSuggestionsFetchRequested('innovationportal', searchQuery);
  }, [searchQuery, onSuggestionsFetchRequested]);

  const handleKeyDown = (e: KeyboardEvent) => {
    setDisableSearchQuery(true);

    if (e.key === KeyboardKey.Enter) {
      if (!justSelectedSuggestionIndex) {
        const inputValue =
          justSelectedSuggestionIndex !== null &&
          suggestions[justSelectedSuggestionIndex]
            ? suggestions[justSelectedSuggestionIndex].attributes.title
            : searchQuery;

        const strippedValue = formatSearchQuery(inputValue);
        handleSubmitQuery(strippedValue);
        return;
      }

      const portalType = getPortalType(
        suggestions[justSelectedSuggestionIndex].portalType
      );

      const title = suggestions[justSelectedSuggestionIndex].attributes.title;
      GAonClickPortalSearchResultSuggestion(title, portalType);
      const suggestionUrl =
        suggestions[justSelectedSuggestionIndex].get('linkUrl');
      const type = suggestions[justSelectedSuggestionIndex].type;
      if (type === 'tool') {
        window.open(suggestionUrl, '_blank');
      } else if (
        suggestionUrl &&
        Boolean(~suggestionUrl.indexOf(window.location.origin))
      ) {
        history.push(suggestionUrl.replace(window.location.origin, ''));
      } else {
        window.location.href = suggestionUrl;
      }

      return;
    }

    if (e.key === KeyboardKey.ArrowUp) {
      e.preventDefault();

      if (!justSelectedSuggestionIndex) {
        const lastSuggestionIndex = suggestions.length - 1;
        setJustSelectedSuggestionIndex(lastSuggestionIndex);
      } else {
        setJustSelectedSuggestionIndex(justSelectedSuggestionIndex - 1);
      }
    } else if (e.key === KeyboardKey.ArrowDown) {
      e.preventDefault();

      if (
        justSelectedSuggestionIndex === null ||
        justSelectedSuggestionIndex >= suggestions.length - 1
      ) {
        setJustSelectedSuggestionIndex(0);
      } else {
        setJustSelectedSuggestionIndex(justSelectedSuggestionIndex + 1);
      }
    } else {
      setJustSelectedSuggestionIndex(null);
    }
  };

  const hoverSearchResult = (index: number) => {
    setJustSelectedSuggestionIndex(index);
  };

  const cancelBtnClasses = classnames('innovation-search-bar__cancel-btn', {
    'innovation-search-bar__cancel-btn--active': searchQuery.length,
  });

  const inputValue: string =
    justSelectedSuggestionIndex !== null &&
    suggestions[justSelectedSuggestionIndex]
      ? suggestions[justSelectedSuggestionIndex].attributes.title
      : searchQuery;

  return (
    <ClickAwayListener onClickAway={handleInputBlur}>
      <div className="innovation-search-bar">
        <div className="innovation-search-bar__field">
          <input
            type="text"
            className="innovation-search-bar__input"
            placeholder="Search for tools, instructions, webinars..."
            value={inputValue}
            onChange={handleInputChange}
            ref={searchRef}
            onClick={handleInputFocus}
            onKeyDown={handleKeyDown}
          />
          <span className={cancelBtnClasses} onClick={resetQuery}>
            <CancelIcon />
          </span>
        </div>
        <div
          className="innovation-search-bar__search"
          onClick={() => handleSubmitQuery(inputValue)}
        >
          <SearchIcon />
        </div>
        <SuggestionsList
          suggestions={suggestions}
          searchQuery={searchQuery}
          justSelectedSuggestionIndex={justSelectedSuggestionIndex}
          history={history}
          hoverSearchResult={hoverSearchResult}
        />
      </div>
    </ClickAwayListener>
  );
};

export default SearchField;
