import { createTheme as createMuiTheme } from '@mui/material/styles';

import { createMuiBorder } from './border';
import { createMuiBreakpoints } from './breakpoints';
import { createMuiColors } from './colors';
import { createMuiComponents } from './components';
import { createMuiElevation } from './elevation';
import { createMuiPalette } from './palette';
import { createMuiSpacing } from './spacing';
import { createMuiTypography } from './typography';

/**
 * The theme is the first layer in which we have the opportunity to
 * customize our components and styles for Material UI. See docs:
 * https://mui.com/material-ui/customization/theming/
 */

const muiPalette = createMuiPalette();
const muiColors = createMuiColors(muiPalette);
const muiBreakpoints = createMuiBreakpoints();
const muiSpacing = createMuiSpacing();
const muiBorder = createMuiBorder();
const muiElevation = createMuiElevation();
const muiTypography = createMuiTypography(muiPalette);
const muiComponents = createMuiComponents(
  muiPalette,
  muiColors,
  muiSpacing,
  muiBorder,
  muiElevation,
  muiTypography
);

const createTheme = () =>
  createMuiTheme({
    palette: muiPalette,
    colors: muiColors,
    breakpoints: muiBreakpoints,
    spacing: muiSpacing,
    border: muiBorder,
    elevation: muiElevation,
    typography: muiTypography,
    components: muiComponents,
  });

export { createTheme };
