import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Tooltip } from 'componentsNew';
import { ReactComponent as HeartIcon } from 'componentsNew/Icon/Heart.svg';
import { ReactComponent as HeartFilledIcon } from 'componentsNew/Icon/HeartFilled.svg';
import { translations } from 'translations';

type LikeButtonProps = {
  id: string;
  likesCount: number;
  isLikedByMe: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  onClick: () => void;
};

const LikeButton = ({
  id,
  likesCount,
  isLikedByMe,
  sx,
  color,
  onClick,
}: LikeButtonProps) => {
  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xxxs'),
          flexShrink: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Tooltip title={translations.like}>
        <IconButton
          id={`${id}-button`}
          onClick={onClick}
          sx={(theme) => ({
            padding: 0,
            '> svg > path': {
              ...(isLikedByMe && {
                fill: color || theme.colors.icon.brandBase,
              }),
              ...(!isLikedByMe &&
                likesCount > 0 && {
                  stroke: color || theme.colors.icon.brandBase,
                }),
            },
            '&:hover': {
              backgroundColor: 'unset',
              '> svg > path': {
                fill: color || theme.colors.icon.brandBase,
                ...(!isLikedByMe && {
                  stroke: color || theme.colors.icon.brandBase,
                }),
              },
            },
          })}
        >
          {isLikedByMe ? <HeartFilledIcon /> : <HeartIcon />}
        </IconButton>
      </Tooltip>
      <Typography
        id={`${id}-count`}
        variant="caption"
        sx={(theme) => ({
          color: color || theme.colors.text.tertiary,
        })}
      >
        {likesCount}
      </Typography>
    </Stack>
  );
};

export { LikeButton };
