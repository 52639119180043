import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { translations } from 'translations';

import { ReactComponent as PlusIcon } from '../Icon/Plus.svg';

const elementId = 'image-picker';

type ImagePickerProps = {
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ImagePicker = ({ disabled, onChange }: ImagePickerProps) => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        maxWidth: '250px',
        height: 'fit-content',
        padding: theme.spacing('md'),
        backgroundColor: theme.colors.surface.secondary,
        border: `1px dashed ${theme.colors.border.surfaceSecondary}`,
        borderRadius: theme.border.radius.md,
        alignItems: 'baseline',
        gap: theme.spacing('sm'),
      })}
    >
      <Button
        id={`${elementId}-upload-button`}
        variant="outlined"
        component="label"
        disabled={disabled}
        sx={(theme) => ({
          'svg > path': {
            fill: theme.colors.icon.brandBase,
          },
        })}
        startIcon={<PlusIcon />}
      >
        {translations.imagePickerButtonText}
        <VisuallyHiddenInput
          type="file"
          accept=".jpg, .jpeg, .png"
          multiple={true}
          onChange={onChange}
        />
      </Button>
      <Stack>
        <Typography variant="caption">
          {translations.imagePickerInfo1}
        </Typography>
        <Typography variant="caption">
          {translations.imagePickerInfo2}
        </Typography>
      </Stack>
    </Stack>
  );
};
