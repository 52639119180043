import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import settings from 'settings';
import * as timeUtils from 'utils/misc/time';

import * as helpers from './helpers';

export enum GlobalWebcastMode {
  PreLive = 'PRE',
  Live = 'LIVE',
  PostLive = 'POST',
}

const GlobalWebcastContext = React.createContext<{
  isGlobalWebcastActivated: boolean;
  isGlobalWebcastIframeMessageLoading: boolean;
  isGlobalWebcastBlockingHomeFeed: boolean;
  iframeUrl?: string;
  iframeHeight: number | null;
  iframeLayoutMode: GlobalWebcastMode | null;
} | null>(null);

const { Consumer: GlobalWebcastConsumer } = GlobalWebcastContext;

type GlobalWebcastProviderProps = {
  children: React.ReactNode;
};

export function GlobalWebcastProvider({
  children,
}: GlobalWebcastProviderProps) {
  const testParams = helpers.getTestParamsFromUrl();

  const isGlobalWebcastActivated = useMemo(
    () =>
      testParams.enabled ||
      (timeUtils.isBeforeNow(settings.globalWebcast.timeForActivation) &&
        timeUtils.isAfterNow(settings.globalWebcast.timeForDeactivation)),
    [testParams.enabled]
  );

  const iframeUrl = useMemo(() => {
    if (testParams.enabled && testParams.iframeUrl) {
      return testParams.iframeUrl;
    }
    return timeUtils.isAfterNow(settings.globalWebcast.timeForIframeSwitch)
      ? settings.globalWebcast.iframeUrlAM
      : settings.globalWebcast.iframeUrlPM;
  }, [testParams.enabled, testParams.iframeUrl]);

  const [iframeHeight, setIframeHeight] = useState<number | null>(null);

  const [iframeLayoutMode, setIframeLayoutMode] =
    useState<GlobalWebcastMode | null>(null);

  const [
    isGlobalWebcastIframeMessageLoading,
    setIsGlobalWebcastIframeMessageLoading,
  ] = useState<boolean>(isGlobalWebcastActivated);

  const initialIframeLayoutModeRef = useRef<GlobalWebcastMode | null>(null);

  // Home feed should be blocked for users that land
  // on Avenue during live global webcast stream
  const isGlobalWebcastBlockingHomeFeed = useMemo(() => {
    if (!isGlobalWebcastActivated) {
      return false;
    }
    if (isGlobalWebcastIframeMessageLoading) {
      return true;
    }
    return (
      initialIframeLayoutModeRef.current === null ||
      initialIframeLayoutModeRef.current === GlobalWebcastMode.Live
    );
  }, [isGlobalWebcastActivated, isGlobalWebcastIframeMessageLoading]);

  const iframeMessageListener = useCallback((message: any) => {
    if (message.data.type !== 'contentChange') {
      return;
    }
    const height = message.data.height;
    const isValidHeight = Number.isInteger(height);
    if (isValidHeight) {
      setIframeHeight(height);
    }

    const layoutMode = message.data.layout;
    const isValidLayoutMode =
      Object.values(GlobalWebcastMode).includes(layoutMode);
    if (!isValidLayoutMode) {
      return;
    }
    if (!initialIframeLayoutModeRef.current) {
      initialIframeLayoutModeRef.current = layoutMode;
      setIsGlobalWebcastIframeMessageLoading(false);
    }
    setIframeLayoutMode(layoutMode);
  }, []);

  // Sets a listener for messages broadcasted from the global
  // webcast iframe. These will include information about iframe
  // layout mode (pre-live, live or post-live) and height
  useEffect(() => {
    window.addEventListener('message', iframeMessageListener);
    return () => window.removeEventListener('message', iframeMessageListener);
  }, [iframeMessageListener]);

  // Sets a timer to not wait more than 5 seconds for a
  // message from the iframe
  useEffect(() => {
    setTimeout(() => {
      setIsGlobalWebcastIframeMessageLoading(false);
    }, 20000);
  }, [isGlobalWebcastActivated]);

  const contextValue = useMemo(
    () => ({
      isGlobalWebcastActivated,
      isGlobalWebcastIframeMessageLoading,
      isGlobalWebcastBlockingHomeFeed,
      iframeUrl,
      iframeHeight,
      iframeLayoutMode: iframeLayoutMode,
    }),
    [
      isGlobalWebcastActivated,
      isGlobalWebcastIframeMessageLoading,
      isGlobalWebcastBlockingHomeFeed,
      iframeUrl,
      iframeHeight,
      iframeLayoutMode,
    ]
  );

  return (
    <GlobalWebcastContext.Provider value={contextValue}>
      {children}
    </GlobalWebcastContext.Provider>
  );
}

export const useGlobalWebcast = () => {
  const context = useContext(GlobalWebcastContext);
  if (!context) {
    throw new Error('GlobalWebcastContext is not defined');
  }
  return context;
};

export { GlobalWebcastConsumer };
