import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { translations } from 'translations';

const ProductOfTheWeekGalleryHeader = () => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        backgroundColor: theme.colors.surface.secondary,
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
        borderBottom: `1px solid ${theme.colors.border.surfacePrimary}`,
      })}
    >
      <Typography
        variant="h1"
        sx={(theme) => ({
          color: theme.colors.text.brand,
        })}
      >
        {translations.productOfTheWeekGalleryTitle}
      </Typography>
    </Stack>
  );
};

export { ProductOfTheWeekGalleryHeader };
