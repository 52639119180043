import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';

const FormButtons = ({ submit, cancel }) => {
  const yesButton = {
    color: 'black',
    title: 'Yes',
  };

  const noButton = {
    color: 'white',
    title: 'No',
  };
  return (
    <div>
      <Button onClick={submit} className="sm-m-b-2 m-r-2" color="black">
        Submit
      </Button>
      <ButtonModalWrapper
        title="Are you sure you want to leave this page before saving?"
        modalChildren={null}
        yesButton={yesButton}
        noButton={noButton}
      >
        <Button onClick={cancel} className="sm-m-b-2" color="transparent">
          Cancel
        </Button>
      </ButtonModalWrapper>
    </div>
  );
};

export default FormButtons;
