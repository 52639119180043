import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';
import InfoBox from 'components/InfoBox';
import { InternalScreenInfoMessage } from 'constants/system';
import { useState } from 'react';

const EnableScreen = ({ onChange, screenEnabled }: EnableScreenProps) => {
  const [enabled, setEnabled] = useState<boolean>(screenEnabled);

  const setEnabledInternalScreen = (newEnabledState: boolean) => {
    setEnabled(newEnabledState);
    onChange(newEnabledState);
  };

  return (
    <Flex>
      <Checkbox
        defaultChecked={enabled}
        id="enableScreen"
        onChange={() => setEnabledInternalScreen(!enabled)}
      />
      <label htmlFor="enableScreen" className="m-l-3">
        Make available for internal screens
      </label>
      <InfoBox message={InternalScreenInfoMessage} />
    </Flex>
  );
};

export default EnableScreen;
