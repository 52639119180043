import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const EditText = ({ autoFocus, onChange, value, size, placeholder }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, [autoFocus]);
  return (
    <input
      type="text"
      onFocus={(e) => e.target.select()}
      onBlur={(e) => e.target.blur()}
      onClick={(e) => e.stopPropagation()}
      onSubmit={(e) => e.target.blur()}
      ref={inputRef}
      size={size}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

EditText.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  placeholder: PropTypes.string,
};

EditText.defaultProps = {
  size: 'small',
  placeholder: 'placeholder',
};

export default EditText;
