import './EmbedPlayerenceButton.scss';

import PlayerenceModal from 'components/Editable/Files/PlayerenceModal';
import { ReactComponent as ClipboardCheckOutlined } from 'componentsNew/Icon/ClipboardCheckOutlined.svg';

type EmbedPlayerenceButtonProps = {
  onEmbedURL: (url: string) => void;
  title: string;
  close: () => void;
};

const EmbedPlayerenceButton = ({
  onEmbedURL,
  title,
  close,
}: EmbedPlayerenceButtonProps) => {
  return (
    <PlayerenceModal
      onSubmit={(data) => {
        onEmbedURL(data);
        close();
      }}
    >
      <div className="embed-playerence-button__container">
        <button>
          <ClipboardCheckOutlined height={42} width={42} />
        </button>
        <p className="embed-playerence-button__label">{title}</p>
      </div>
    </PlayerenceModal>
  );
};

export default EmbedPlayerenceButton;
