import './EmbedMSFormsButton.scss';

import MSFormsModal from 'components/Editable/Files/MSFormsModal';
import { ReactComponent as Checkbox } from 'componentsNew/Icon/Checkbox.svg';

type EmbedMSFormsButtonProps = {
  onEmbedURL: (url: string) => void;
  title: string;
  close: () => void;
};

const EmbedMSFormsButton = ({
  onEmbedURL,
  title,
  close,
}: EmbedMSFormsButtonProps) => {
  return (
    <MSFormsModal
      onSubmit={(data) => {
        onEmbedURL(data);
        close();
      }}
    >
      <div className="embed-msforms-button__container">
        <button>
          <Checkbox height={42} width={42} />
        </button>
        <p className="embed-msforms-button__label">{title}</p>
      </div>
    </MSFormsModal>
  );
};

export default EmbedMSFormsButton;
