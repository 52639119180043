import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

const GlobalWebcastLinkBox = () => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.colors.icon.brandBase,
        color: theme.colors.text.actionDefault,
        fontWeight: '600',
        lineHeight: '1.6',
        padding: '0px 0px 0px 3px',
        borderRadius: '8px',
      })}
    >
      <Box
        sx={(theme) => ({
          background: theme.colors.surface.informative,
          padding: '8px 16px 8px 16px',
          borderRadius: '4px',
        })}
      >
        <Box
          sx={() => ({
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          {translations.globalWebcastLinkBoxTitle}
          <GlobalWebcastLink to="/feed/news">
            {translations.globalWebcastLinkTextNews}
          </GlobalWebcastLink>
          <GlobalWebcastLink to="/jobs">
            {translations.globalWebcastLinkTextJobs}
          </GlobalWebcastLink>
        </Box>
      </Box>
    </Box>
  );
};

type WebcastLinkProps = {
  to: string;
  children: string;
};

const GlobalWebcastLink = ({ to, children }: WebcastLinkProps) => {
  return (
    <Link
      to={to}
      component={ReactRouterLink}
      sx={(theme) => ({
        '&:hover': {
          color: theme.colors.text.actionHover,
        },
        maxWidth: 'max-content',
        color: theme.colors.text.actionDefault,
      })}
    >
      {children}
    </Link>
  );
};

export { GlobalWebcastLinkBox };
