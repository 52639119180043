import { AlertDialog } from 'componentsNew';
import { AlertDialogType } from 'componentsNew/AlertDialog';
import { translations } from 'translations';

type DialogRenewProps = {
  id: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DialogRenew = ({
  id,
  open,
  loading,
  onClose,
  onConfirm,
}: DialogRenewProps) => {
  return (
    <AlertDialog
      id={id}
      open={open}
      type={AlertDialogType.Information}
      title={translations.manageContentActionsRenewDialogTitle}
      paragraphs={[translations.manageContentActionsRenewDialogText]}
      primaryButton={{
        loading: loading,
        disabled: loading,
        text: translations.manageContentActionsRenew,
        onClick: onConfirm,
      }}
      secondaryButton={{
        disabled: loading,
        text: translations.cancel,
        onClick: onClose,
      }}
      {...(!loading && { onClose })}
    />
  );
};

export { DialogRenew };
