import { InputProps } from '@mui/material/Input';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

import { ReactComponent as SelectIcon } from './SelectIcon.svg';

const EMPTY_VALUE = 'none' as const;

export type SelectItem = { name: string; value: string };

export type SelectProps = {
  id?: string;
  selected: SelectItem | null | undefined;
  items: SelectItem[];
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  inputProps?: InputProps['inputProps'];
  sx?: SxProps<Theme>;
  onChange: (item?: SelectItem | null) => void;
  onBlur?: () => void;
};

const Select = ({
  id,
  selected,
  items,
  error,
  disabled,
  fullWidth,
  allowEmpty = false,
  placeholder,
  inputProps,
  sx,
  onChange,
  onBlur,
}: SelectProps) => {
  return (
    <MuiSelect
      id={id}
      size="small"
      variant="outlined"
      fullWidth={fullWidth}
      value={selected?.value || EMPTY_VALUE}
      error={error}
      disabled={disabled}
      inputProps={inputProps}
      MenuProps={{ disablePortal: true }}
      IconComponent={SelectIcon}
      sx={[
        (theme) => ({
          ...(!selected && { color: theme.colors.text.tertiary }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onChange={(e) => {
        const item = items.find((item) => item.value === e.target.value);
        onChange(item);
      }}
      onBlur={onBlur}
    >
      {placeholder && !selected && (
        <MuiMenuItem value={EMPTY_VALUE} disabled={!allowEmpty}>
          {placeholder}
        </MuiMenuItem>
      )}
      {items.map((item, index) => (
        <MuiMenuItem key={item.value || `select-${index}`} value={item.value}>
          {item.name}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
};

export { Select };
