import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { CmsContentType } from 'api/cms/types';
import { Badge, Checkbox, TableCellWithSort } from 'componentsNew';
import { ReactComponent as ExclaimationTriangleFilledIcon } from 'componentsNew/Icon/ExclaimationTriangleFilled.svg';
import notFoundImage from 'images/not-found.jpg';
import { useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonChangeManageFeedSort,
  GAonClickManageFeedArticle,
} from 'utils/analytics';

import * as helpers from './helpers';
import { ArticleStatus } from './ManageFeedFilter';
import { ManageFeedTableSkeleton } from './ManageFeedTableSkeleton';

const elementId = 'profile-manage-feed-table';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ManageFeedTableField {
  Title = 'title',
  PublishedDate = 'publishedDate',
  ModifiedDate = 'modifiedDate',
  LikesCount = 'likesCount',
  CommentsCount = 'commentsCount',
  ViewsCount = 'viewsCount',
  InformationOwner = 'informationOwner',
}

export type ManageFeedTableItem = {
  id: string;
  title: string;
  type: CmsContentType;
  publishedDate?: string;
  modifiedDate?: string;
  likesCount?: number;
  commentsCount?: number;
  viewsCount?: number;
  newCommentsCount?: number;
  informationOwner?: {
    name: string;
    oid: string;
  };
  linkPath: string;
};

type ManageFeedTableProps = {
  sx?: SxProps<Theme>;
  isLoading: boolean;
  items: ManageFeedTableItem[];
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  };
  sort: {
    field: ManageFeedTableField;
    order: SortOrder;
  };
  selection: {
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  };
  onSortChange: (sort: {
    field: ManageFeedTableField;
    order: SortOrder;
  }) => void;
  onSelectionChange: (selection: {
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }) => void;
};

const ManageFeedTable = ({
  sx,
  isLoading,
  items,
  filter,
  sort,
  selection,
  onSortChange,
  onSelectionChange,
}: ManageFeedTableProps) => {
  const handleSortClick = useCallback(
    (field: ManageFeedTableField) => {
      onSortChange({
        field: field,
        order:
          sort?.field === field && sort.order === SortOrder.Asc
            ? SortOrder.Desc
            : SortOrder.Asc,
      });
      GAonChangeManageFeedSort(field);
    },
    [onSortChange, sort]
  );

  const handleItemSelectionChange = useCallback(
    (id: string, checked: boolean) => {
      const selectionById = { ...selection.byId, [id]: checked };
      onSelectionChange({
        byId: selectionById,
        isSomeSelected: items.some((item) => selectionById[item.id]),
        isAllSelected: !items.some((item) => !selectionById[item.id]),
      });
    },
    [selection.byId, items, onSelectionChange]
  );

  const handleBulkSelectionChange = useCallback(
    (checked: boolean) => {
      const byId = items.reduce((selectionById, item) => {
        selectionById[item.id] = checked;
        return selectionById;
      }, {} as Record<string, boolean>);

      onSelectionChange({
        byId,
        isSomeSelected: checked,
        isAllSelected: checked,
      });
    },
    [items, onSelectionChange]
  );

  if (isLoading) {
    return <ManageFeedTableSkeleton />;
  }

  if (!items.length) {
    return (
      <Stack alignItems="center" padding="1rem">
        <Box component="img" src={notFoundImage} maxHeight="300px" />
        <Typography marginTop={1}>{translations.manageContentEmpty}</Typography>
      </Stack>
    );
  }

  return (
    <TableContainer sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Table id={elementId} aria-label={translations.profileManageFeed}>
        <TableHead>
          <TableRow>
            <TableCell width="3.5rem">
              <Checkbox
                checked={selection.isAllSelected || false}
                indeterminate={
                  selection.isSomeSelected && !selection.isAllSelected
                }
                onChange={(
                  _e: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => handleBulkSelectionChange(checked)}
              />
            </TableCell>
            <TableCellWithSort
              width="30%"
              sorted={sort?.field === ManageFeedTableField.Title}
              onSort={() => handleSortClick(ManageFeedTableField.Title)}
            >
              {translations.manageContentTitle}
            </TableCellWithSort>
            {filter.articleStatus === ArticleStatus.Published && (
              <>
                <TableCellWithSort
                  sorted={sort?.field === ManageFeedTableField.PublishedDate}
                  onSort={() =>
                    handleSortClick(ManageFeedTableField.PublishedDate)
                  }
                >
                  {translations.manageContentPublishedDate}
                </TableCellWithSort>
                {filter.isAdmin && (
                  <TableCellWithSort
                    sorted={
                      sort?.field === ManageFeedTableField.InformationOwner
                    }
                    onSort={() =>
                      handleSortClick(ManageFeedTableField.InformationOwner)
                    }
                  >
                    {translations.manageContentInformationOwner}
                  </TableCellWithSort>
                )}
                <TableCell>{translations.manageContentLikes}</TableCell>
                <TableCell>{translations.manageContentComments}</TableCell>
                <TableCell align="right">
                  {translations.manageContentViews}
                </TableCell>
              </>
            )}
            {filter.articleStatus === ArticleStatus.Drafts && (
              <TableCellWithSort
                sorted={sort?.field === ManageFeedTableField.ModifiedDate}
                onSort={() =>
                  handleSortClick(ManageFeedTableField.ModifiedDate)
                }
              >
                {translations.manageContentModifiedDate}
              </TableCellWithSort>
            )}
            {filter.articleStatus === ArticleStatus.Scheduled && (
              <TableCellWithSort
                sorted={sort?.field === ManageFeedTableField.PublishedDate}
                onSort={() =>
                  handleSortClick(ManageFeedTableField.PublishedDate)
                }
              >
                {translations.manageContentPublishTime}
              </TableCellWithSort>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`${item.id}-${index}`}
              id={`${elementId}-row-${index}`}
            >
              <TableCell width="3.5rem">
                <Checkbox
                  checked={selection.byId[item.id] || false}
                  onChange={(
                    _e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleItemSelectionChange(item.id, checked)}
                />
              </TableCell>
              <TableCell>
                <Stack>
                  <Link
                    variant="body2"
                    fontWeight="bold"
                    to={item.linkPath}
                    component={ReactRouterLink}
                    sx={{ textDecoration: 'none' }}
                    onClick={() => GAonClickManageFeedArticle(item.title)}
                  >
                    {item.title}
                  </Link>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({ color: theme.colors.text.tertiary })}
                  >
                    {helpers.getArticleTypeTranslation(item.type)}
                  </Typography>
                </Stack>
              </TableCell>
              {filter.articleStatus === ArticleStatus.Published && (
                <>
                  <TableCell>
                    {!item.publishedDate ? (
                      translations.notAvailable
                    ) : helpers.isExpiryDateSoon(item.publishedDate) ? (
                      <Badge
                        size="small"
                        color="warning"
                        icon={<ExclaimationTriangleFilledIcon />}
                        label={helpers.getFormattedDate(item.publishedDate)}
                        title={
                          translations.manageContentPublishedDateExpiryInfo
                        }
                      />
                    ) : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={helpers.getFormattedDate(item.publishedDate)}
                      />
                    )}
                  </TableCell>
                  {filter.isAdmin && (
                    <TableCell>
                      {item.informationOwner ? (
                        <Link
                          to={`/people/${item.informationOwner.oid}`}
                          component={ReactRouterLink}
                          sx={{ textDecoration: 'none' }}
                        >
                          {item.informationOwner.name}
                        </Link>
                      ) : (
                        translations.notAvailable
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {item.likesCount === undefined ? (
                      translations.notAvailable
                    ) : item.likesCount === 0 ? null : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={item.likesCount}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.commentsCount === undefined ? (
                      translations.notAvailable
                    ) : item.commentsCount ===
                      0 ? null : (item.newCommentsCount || 0) > 0 ? (
                      <Badge
                        size="small"
                        color="success"
                        variant="outlined"
                        label={item.commentsCount}
                        type="status"
                      />
                    ) : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={item.commentsCount}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {item.viewsCount === undefined
                      ? translations.notAvailable
                      : item.viewsCount === 0
                      ? null
                      : item.viewsCount}
                  </TableCell>
                </>
              )}
              {filter.articleStatus === ArticleStatus.Drafts && (
                <TableCell>
                  {!item.modifiedDate ? (
                    translations.notAvailable
                  ) : (
                    <Badge
                      size="small"
                      color="default"
                      variant="outlined"
                      label={helpers.getFormattedDate(item.modifiedDate)}
                    />
                  )}
                </TableCell>
              )}
              {filter.articleStatus === ArticleStatus.Scheduled && (
                <TableCell>
                  {!item.publishedDate ? (
                    translations.notAvailable
                  ) : (
                    <Badge
                      size="small"
                      color="default"
                      variant="outlined"
                      label={helpers.getFormattedDate(item.publishedDate, true)}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ManageFeedTable };
