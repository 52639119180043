import './Center.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Center = ({ horizontal, children, className, tag: Tag, ...rest }) => {
  const centerClassNames = classNames('center', {
    'center--horizontal': horizontal,
    [className]: className,
  });
  return (
    <Tag className={centerClassNames} {...rest}>
      {children}
    </Tag>
  );
};

Center.propTypes = {
  horizontal: PropTypes.bool,
};

Center.defaultProps = {
  horizontal: false,
  tag: 'div',
};

export default Center;
