import { PaletteOptions } from '@mui/material/styles';
// eslint-disable-next-line no-restricted-imports
import createPalette from '@mui/material/styles/createPalette';

/**
 * The palette lets you to modify the color of the components to suit your brand.
 * You may override the default palette values or create new. Some of the default
 * values are "primary", "secondary", "error", "warning", "info" and "success".
 * Docs here: https://mui.com/material-ui/customization/palette/
 */

const tempPalette = createPalette({});

const paletteOptions: PaletteOptions = {
  primary: tempPalette.augmentColor({
    // "Primary" in ASSA ABLOY Global Design System
    color: {
      100: '#006495', // N/A in AA GDS
      200: '#006495', // N/A in AA GDS
      300: '#006495', // N/A in AA GDS
      400: '#006495', // N/A in AA GDS
      500: '#006495', // N/A in AA GDS
      600: '#006495',
      700: '#034B70', // N/A in AA GDS
      800: '#003450',
      900: '#001E30',
      base: '#034B70',
    },
    mainShade: 'base',
  }),
  secondary: tempPalette.augmentColor({
    // "Accent" in ASSA ABLOY Global Design System
    color: {
      100: '#F1F7F9',
      200: '#E6EFF4',
      300: '#C8E0EA',
      400: '#A7D0E2',
      500: '#7CC1DE',
      600: '#7CC1DE', // N/A in AA GDS
      700: '#7CC1DE', // N/A in AA GDS
      800: '#7CC1DE', // N/A in AA GDS
      900: '#7CC1DE', // N/A in AA GDS
      base: '#00A0D0',
    },
    mainShade: 'base',
  }),

  // "Success" in ASSA ABLOY Global Design System
  success: tempPalette.augmentColor({
    color: {
      100: '#F5F9F1',
      200: '#EDF4E6',
      300: '#D8E8C9',
      400: '#C3DFAA',
      500: '#AAD786',
      600: '#62AD20',
      700: '#366B00',
      800: '#275000',
      900: '#193800',
      base: '#3A8600',
    },
    mainShade: 'base',
  }),

  // "Warning" in ASSA ABLOY Global Design System
  warning: tempPalette.augmentColor({
    color: {
      100: '#F9F6F1',
      200: '#FEF3DC',
      300: '#FEE6B4',
      400: '#FDD98C',
      500: '#FACA61',
      600: '#C38400',
      700: '#815600',
      800: '#614000',
      900: '#442B00',
      base: '#FFAE00',
    },
    mainShade: 'base',
  }),
  // "Critical" in ASSA ABLOY Global Design System
  error: tempPalette.augmentColor({
    color: {
      100: '#F9F1F1',
      200: '#FEDCDC',
      300: '#FEB4B4',
      400: '#FD8C8C',
      500: '#FA6161',
      600: '#F04444',
      700: '#93000A',
      800: '#690005',
      900: '#410002',
      base: '#BA1A1A',
    },
    mainShade: 'base',
  }),
  // "Neutral" in ASSA ABLOY Global Design System
  neutral: tempPalette.augmentColor({
    color: {
      100: '#FAFAFA',
      200: '#F5F5F5',
      300: '#E6E6E6',
      400: '#D5D5D5',
      500: '#ADADAD',
      600: '#909090',
      700: '#6C6C6C',
      800: '#464646',
      900: '#262626',
      base: '#1A1A1A', // N/A in AA GDS
    },
  }),
  info: undefined,
  common: {
    black: '#1A1A1A',
    white: '#FFFFFF',
  },
};

const createMuiPalette = () => {
  return createPalette(paletteOptions);
};

export { createMuiPalette };
