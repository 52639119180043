import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormFieldWrapper } from 'componentsNew';
import { FormFieldWrapperProps } from 'componentsNew/FormFieldWrapper';

type PreferencesFormFieldWrapperProps = FormFieldWrapperProps;

const PreferencesFormFieldWrapper = ({
  id,
  label,
  error,
  info,
  children,
}: PreferencesFormFieldWrapperProps) => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: { xs: 'column', sm: 'row' },
        rowGap: theme.spacing('xxxs'),
      })}
    >
      <Typography
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
          color: theme.colors.text.tertiary,
          height: { sm: '40px' },
          width: '8rem',
        })}
      >
        {label}
      </Typography>
      <FormFieldWrapper
        id={id}
        label={label}
        error={error}
        info={info}
        sx={{ flexGrow: 1 }}
      >
        {children}
      </FormFieldWrapper>
    </Stack>
  );
};

export { PreferencesFormFieldWrapper };
