import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as RobotWithBubbleBadgeIcon } from 'componentsNew/Icon/RobotWithBubbleBadge.svg';
import { translations } from 'translations';

const AIChatIntroduction = () => {
  return (
    <Stack
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.colors.surface.primary,
        boxShadow: theme.elevation.sm,
        borderRadius: theme.border.radius.md,
        padding: theme.spacing('sm'),
        gap: theme.spacing('xxs'),
        maxWidth: '27.375rem',
        marginTop: '3.375rem',
        width: '100%',
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '-3.375rem',
          left: `calc(50% - 4.72575rem/2)`,
        }}
      >
        <RobotWithBubbleBadgeIcon height="5rem" width="4.72575rem" />
      </Box>
      <Stack>
        <Typography
          variant="body2"
          sx={(theme) => ({
            fontWeight: 700,
            lineHeight: 1.75,
            color: theme.colors.text.brand,
          })}
        >
          {translations.aiChatIntroTitle}
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
          })}
        >
          {translations.aiChatIntroText1}
        </Typography>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
          })}
        >
          {translations.aiChatIntroText2}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          variant="body2"
          sx={(theme) => ({
            fontWeight: 700,
            lineHeight: 1.75,
            color: theme.colors.text.brand,
          })}
        >
          {translations.aiChatIntroListTitle}
        </Typography>
        <Box
          component="ul"
          sx={(theme) => ({
            margin: `${theme.spacing('xxxs')} ${theme.spacing('sm')}`,
            paddingInlineStart: '0.5rem',
            marginBlockStart: 0,
            marginBlockEnd: 0,
          })}
        >
          <Typography
            variant="body2"
            component="li"
            sx={(theme) => ({
              color: theme.colors.text.tertiary,
            })}
          >
            {translations.aiChatIntroListText1}
          </Typography>
          <Typography
            variant="body2"
            component="li"
            sx={(theme) => ({
              color: theme.colors.text.tertiary,
            })}
          >
            {translations.aiChatIntroListText2}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export { AIChatIntroduction };
