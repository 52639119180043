import { createAttachment, deleteAttachment } from 'api/cms/attachment';
import {
  createHeroItem,
  deleteHeroItem,
  getHeroItem,
  updateHeroItem,
} from 'api/cms/HeroBanner';
import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import withConsumer from 'components/Context/withConsumer';
import { AvenueRoute } from 'constants/routes';
import { SnackbarConsumer } from 'contextNew/Snackbar';
import { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { replaceImage } from 'utils/cms/handleAttachments';

const { Consumer: HeroBannerConsumer, Provider } = createContext();

const DEFAULT_DIVISION_NAME = 'Global (all employees)';

class HeroBannerProvider extends Component {
  state = {
    size: HeroBannerSize.Medium,
    title: '',
    description: '',
    linkUrl: '',
    linkButtonText: '',
    values: {},
    isLoading: false,
    updatedHero: null,
    data: [],
    updatedCheckBox: false,
    id: -1,
    heroImage: null,
  };

  getDefaultValue = (publishedInGlobal, publishedInDivisions) => {
    // a null value means we have published in global, but internally
    // the publish selector needs a value
    if (publishedInGlobal) {
      return {
        divisions: [{ name: DEFAULT_DIVISION_NAME, value: 'division-34' }],
        segments: [],
        countries: [],
        departments: [],
        sites: [],
        companies: [],
      };
    }

    return {
      divisions:
        publishedInDivisions && publishedInDivisions.length
          ? publishedInDivisions.map((division) => ({
              value: division.id,
              name: division.name,
            }))
          : [],
      segments: [],
      countries: [],
      departments: [],
      sites: [],
      companies: [],
    };
  };

  onToggleOverrideValue = () => {
    if (this._isMounted) {
      this.setState(({ updatedCheckBox }) => ({
        updatedCheckBox: !updatedCheckBox,
      }));
    }
  };

  onChange =
    (onChangeDivision) =>
    ({ divisions, countries }) => {
      let publishedInGlobal = false;
      let publishedInDivisions = null;
      let publishedInCountries = null;

      if (
        divisions &&
        divisions.some((division) => division === DEFAULT_DIVISION_NAME)
      ) {
        publishedInGlobal = true;
      } else {
        if (divisions && divisions.length) {
          publishedInDivisions = divisions.map((division) => ({
            id: division.value,
            name: division.name,
          }));
        }

        if (countries && countries.length) {
          publishedInCountries = countries.map((c) => ({
            id: c.value,
            name: c.name,
          }));
        }
      }
      if (this._isMounted) {
        onChangeDivision({
          publishedInGlobal,
          publishedInDivisions,
          publishedInCountries,
        });
      }
    };

  onChangeDivision = (values) => {
    if (this._isMounted) {
      this.setState({ values });
    }
  };

  onChangeImage = (updatedHero) => {
    if (this._isMounted) {
      this.setState({ updatedHero });
    }
  };

  changeTextValue = (title) => {
    if (this._isMounted) {
      this.setState({ title });
    }
  };

  changeSizeValue = (size) => {
    if (this._isMounted) {
      this.setState({ size });
    }
  };

  changeLinkValue = (linkUrl) => {
    if (this._isMounted) {
      this.setState({ linkUrl });
    }
  };

  changeLinkButtonValue = (linkButtonText) => {
    if (this._isMounted) {
      this.setState({ linkButtonText });
    }
  };

  changeDescriptionValue = (description) => {
    if (this._isMounted) {
      this.setState({ description });
    }
  };

  onAfterCancel = () => {
    this.props.history.push(AvenueRoute.ProfileManageBanners);
  };

  getHeroBannerData = async () => {
    const { pathname } = this.props.location;
    this.setState({ isLoading: true });

    const pathArray = pathname.split('/');
    if (pathArray && pathArray.length > 2) {
      // Third item is the Banner Id
      const id = pathArray[2];

      const data = await getHeroItem(id);
      this.setState({
        size: data.data.data.size || HeroBannerSize.Medium,
        title: data.data.data.title,
        description: data.data.data.description,
        linkUrl: data.data.data.linkUrl,
        linkButtonText: data.data.data.linkButtonText,
        updatedCheckBox: data.data.data.overrideDivisionalMessage,
        values: {
          publishedInGlobal:
            !data.data.data.publishedInCountries &&
            !data.data.data.publishedInDivisions,
          publishedInCountries: data.data.data.publishedInCountries,
          publishedInDivisions: data.data.data.publishedInDivisions,
        },
        id,
        heroImage: data.data.data.heroImage,
        heroAltText: data.data.data.heroAltText,
        isLoading: false,
      });
    }
  };

  deleteHeroBannerData = async () => {
    const { snackbarContext, history } = this.props;
    const { id, heroImage } = this.state;
    if (!id || id === -1) return;

    try {
      this.setState({ isLoading: true });
      await deleteHeroItem(id);
      if (heroImage) {
        await deleteAttachment(heroImage.replace('/static/node/', ''));
      }
      snackbarContext.showSnackbar({
        text: 'Hero banner has been deleted',
        type: 'success',
      });
      history.push(AvenueRoute.ProfileManageBanners);
    } catch {
      snackbarContext.showSnackbar({
        text: 'Failed to delete hero banner',
        type: 'error',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  saveHeroBannerData = async () => {
    const { snackbarContext } = this.props;
    const {
      size,
      title,
      description,
      linkUrl,
      linkButtonText,
      updatedHero,
      updatedCheckBox,
      values,
    } = this.state;

    const { publishedInDivisions, publishedInCountries, publishedInGlobal } =
      values;

    if (!title) {
      snackbarContext.showSnackbar({ text: 'Title is missing', type: 'error' });
      return;
    }

    if (
      (!publishedInDivisions && !publishedInGlobal) ||
      publishedInDivisions.length < 1
    ) {
      snackbarContext.showSnackbar({
        text: 'Global or division is missing',
        type: 'error',
      });
      return;
    }
    this.setState({ isLoading: true });

    snackbarContext.showSnackbar({
      text:
        this.state.id === -1 ? 'Creating Hero Banner' : 'Updating Hero Banner',
    });

    const newHeroAltText = updatedHero?.heroAltText;
    let newHeroImage = null;

    // Create new attachment if user uploaded new image
    if (updatedHero?.heroImage?.blob) {
      let uploadImageResponse = null;
      const { blob, width, height } = updatedHero.heroImage;
      const data = new FormData();
      data.append('file', blob);
      data.append('width', width);
      data.append('height', height);

      if (this.state.id === -1) {
        uploadImageResponse = await createAttachment(data);
        newHeroImage = uploadImageResponse.data.data.url;
      } else {
        uploadImageResponse = await replaceImage(
          this.state.heroImage,
          updatedHero.heroImage
        );
        newHeroImage = uploadImageResponse.url;
      }
    }

    const herodata = {
      publishedInDivisions: publishedInDivisions,
      publishedInCountries: publishedInCountries,
      size: size,
      title: title,
      description: description,
      linkUrl: linkUrl,
      linkButtonText: linkButtonText,
      overrideDivisionalMessage: updatedCheckBox,
      publishedDate: new Date().toISOString(),
      heroImage: newHeroImage || this.state.heroImage,
      heroAltText: newHeroAltText || this.state.heroAltText,
    };

    let heroresponse = null;
    if (this.state.id === -1) {
      heroresponse = await createHeroItem(herodata);
    }
    await updateHeroItem(
      this.state.id === -1 ? heroresponse.data.data._doc : this.state.id,
      herodata
    );
    snackbarContext.showSnackbar({
      text:
        this.state.id === -1
          ? 'Hero Banner has been created'
          : 'Hero Banner has been updated',
      type: 'success',
    });

    this.setState({
      isLoading: false,
      heroImage: newHeroImage || this.state.heroImage,
      heroAltText: newHeroAltText || this.state.heroAltText,
    });

    this.props.history.push(AvenueRoute.ProfileManageBanners);
  };

  async componentDidMount() {
    this._isMounted = true;
    const { pathname } = this.props.location;
    if (pathname.includes('/hero-banner') && pathname.includes('/edit')) {
      await this.getHeroBannerData();
    }
  }

  async componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    if (pathname !== prevProps.location.pathname) {
      if (pathname.includes('/hero-banner') && pathname.includes('/edit')) {
        await this.getHeroBannerData();
      } else if (
        pathname.includes('/hero-banner') &&
        pathname.includes('/create')
      ) {
        this.setState({
          size: HeroBannerSize.Medium,
          title: '',
          description: '',
          linkUrl: '',
          linkButtonText: '',
          updatedCheckBox: false,
          values: {
            publishedInCountries: [],
            publishedInDivisions: [],
          },
          id: -1,
          heroImage: null,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>
        <Provider
          value={{
            ...this.state,
            ...this.props,
            DEFAULT_DIVISION_NAME: DEFAULT_DIVISION_NAME,
            onChange: this.onChange,
            getDefaultValue: this.getDefaultValue,
            onChangeDivision: this.onChangeDivision,
            changeSizeValue: this.changeSizeValue,
            changeTextValue: this.changeTextValue,
            changeLinkValue: this.changeLinkValue,
            changeLinkButtonValue: this.changeLinkButtonValue,
            onCancel: this.onAfterCancel,
            onDelete: this.deleteHeroBannerData,
            onPublish: this.saveHeroBannerData,
            changeDescriptionValue: this.changeDescriptionValue,
            onChangeImage: this.onChangeImage,
            onToggleOverrideValue: this.onToggleOverrideValue,
          }}
        >
          {this.props.children}
        </Provider>
      </>
    );
  }
}
export default withConsumer(
  SnackbarConsumer,
  withRouter(HeroBannerProvider),
  'snackbarContext'
);
export { HeroBannerConsumer };
