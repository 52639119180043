import React from 'react';

const replaceTranslationAliases = (
  translation: string,
  replaceValueByAliasKey: Record<string, string | number>
) => {
  const aliasRegex = new RegExp(/{{(.*?)}}/g);
  const aliasesInTranslation = translation.match(aliasRegex) || [];
  let newTranslation = translation;

  aliasesInTranslation.forEach((aliasInTranslation) => {
    const aliasKey = aliasInTranslation.slice(2, -2);
    const replaceValue = replaceValueByAliasKey[aliasKey];
    if (!replaceValue) return;

    newTranslation = newTranslation.replace(
      aliasInTranslation,
      `${replaceValue}`
    );
  });
  return newTranslation;
};

const replaceTranslationAliasesWithElements = (
  translation: string,
  replaceValueByAliasKey: Record<string, React.ReactNode>,
  returnWithSpaces?: boolean
): React.ReactNode[] => {
  const aliasRegex = new RegExp(/{{(.*?)}}/g);
  const translationArray = translation.split(' ');

  const elementsArray = translationArray.map((translationItem) => {
    const isAlias = aliasRegex.test(translationItem);
    if (!isAlias) return translationItem;

    const aliasKey = translationItem.slice(2, -2);
    const replaceValue = replaceValueByAliasKey[aliasKey];
    if (!replaceValue) return translationItem;
    return replaceValue;
  });

  if (!returnWithSpaces) {
    return elementsArray;
  }

  return elementsArray.map((element, index) => [
    <React.Fragment key={`element-${index}`}>{element}</React.Fragment>,
    ' ',
  ]);
};

const truncateTabLabel = (label: string, maxLength: number): string => {
  if (label.length <= maxLength) return label;

  return `${label.substring(0, maxLength).trim()}...`;
};

export {
  replaceTranslationAliases,
  replaceTranslationAliasesWithElements,
  truncateTabLabel,
};
