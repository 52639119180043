import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReactComponent as InformationCircleFilledIcon } from 'componentsNew/Icon/InformationCircleFilled.svg';

type InfoBoxProps = {
  ariaLabel?: string;
  children: React.ReactNode;
};

const InfoBox = ({ ariaLabel, children }: InfoBoxProps) => {
  return (
    <Stack
      component="section"
      aria-label={ariaLabel}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.secondary,
        border: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
        borderRadius: theme.border.radius.md,
        padding: theme.spacing('md'),
        rowGap: theme.spacing('sm'),
      })}
    >
      <Box
        sx={(theme) => ({
          flexShrink: 0,
          svg: { path: { fill: theme.colors.icon.brandBase } },
        })}
      >
        <InformationCircleFilledIcon />
      </Box>
      {children}
    </Stack>
  );
};

export { InfoBox };
