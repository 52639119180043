import { Translations } from './translations';

const en: Translations = {
  additionalFeedbackReceiversHeaderText: 'Notifications about comments',
  additionalFeedbackReceiversInfoText:
    'The person who creates and edits this article will get notifications about comments. Here you can add additional people who should also get notifications about comments. (Maximum 5)',
  aiChatAlertIncompleteProfileText:
    'It seems you have not been assigned to a division or country. Please contact support for assistance.',
  aiChatAlertIncompleteProfileTitle: 'Incomplete profile information',
  aiChatButton: 'Open AI chat',
  aiChatButtonHint: 'Improve your search with AI',
  aiChatDisclaimer:
    'AI can make mistakes. Make sure to double check important information.',
  aiChatErrorGeneric: 'Something went wrong, please try again later',
  aiChatFeedbackButton: 'Feedback',
  aiChatInputPlaceholder: 'Ask Avenue AI',
  aiChatIntroListTitle: 'Type of questions you can ask me:',
  aiChatIntroListText1: 'How should the ASSA ABLOY logotype be used?',
  aiChatIntroListText2: 'You can ask me in your own language',
  aiChatIntroTitle: 'Hi, I am Abby.',
  aiChatIntroText1: 'I know everything there is in Avenue - but nothing else.',
  aiChatIntroText2: 'Go ahead and ask me!',
  aiChatResultInfoText: 'I am a beta version and can make mistakes.',
  aiChatResultInfoTextWithFeedbackLink:
    'Please help me improve by giving {{feedback}}.',
  aiChatTitle: 'Avenue AI',
  aiChatSend: 'Send',
  aiChatSourceList: 'Sources',
  aiChatSubtitle: 'Beta',
  allJobs: 'All Jobs',
  articleBodyPlaceholder: 'Body',
  articleCategoryPlaceholder: 'Category',
  articleContactPlaceholder: 'Contact',
  articleContentTypeBlog: 'Blog',
  articleContentTypeNews: 'News',
  articleContentTypePress: 'Press',
  articleContentTypeStory: 'Story',
  articleDivisionPlaceholder: 'Division Name',
  articleEndDatePlaceholder: 'End Date',
  articleFeed: 'News feed',
  articleFeedAll: 'All divisions',
  articleFeedbackCommentError:
    'Your feedback failed to be submitted, please try again',
  articleFeedbackCommentSuccess:
    'Your feedback has been sent to the information owner',
  articleFeedbackPlaceholder: 'What is on your mind?',
  articleFeedbackRatingTitle: 'Was this page helpful?',
  articleFeedbackRating0:
    'Share with the information owner that you are not satisfied with the content of the article',
  articleFeedbackRating1:
    'Share with the information owner that you are neither happy nor unhappy with the content of the article',
  articleFeedbackRating2:
    'Share with the information owner that you are happy with the content of the article',
  articleFeedDefault: 'For you',
  articleFeedDepartment: 'Function',
  articleFeedNoDepartmentTextWithLink:
    'We are unable to show you function specific news since you have not selected a function for your profile. Please go to {{Profile settings}} to complete it.',
  articleFeedNoDepartmentTitle: 'Oh no, you are missing out!',
  articleFeedNoItemsTextWithLink:
    'We have nothing to show here right now. Explore news, blogs, stories and more in the {{Feed}}.',
  articleFeedNoItemsTextWithLinkEditor: 'Post news {{here}}!',
  articleFeedNoItemsTitle: 'No news available',
  articleFeedReadMore: 'All News',
  articleFeedReadMoreAriaLabel: 'Go to feed',
  articleFormInformationOwner1: 'Assign owner of article',
  articleFormInformationOwner2:
    'The owner of the article is displayed in the article footer and receives notifications about comments.',
  articlePageCreatedBy: 'Published: {{name}}',
  articlePageEditedBy: 'Last edit: {{date}} by {{name}}',
  articlePreamblePlaceholder: 'Preamble (max 300 characters)',
  articleReleaseYearPlaceholder: 'Release Year',
  articleStartDatePlaceholder: 'Start Date',
  articleTitlePlaceholder: 'Title (max 90 characters)',
  cancel: 'Cancel',
  clear: 'Clear',
  close: 'Close',
  company: 'Company',
  contact: 'Contact',
  comment: 'Comment',
  commentInputTitle: 'Comments',
  commentInputPlaceHolder: 'Post a new comment',
  commentInputSubmit: 'Post Comment',
  commentInputSubmitModalTitle: 'Do you want to publish this comment?',
  commentInputSubmitModalText:
    'This comment will be visible to other employees',
  country: 'Country',
  create: 'Create',
  dateTimePickerPlaceholder: 'Select date & time',
  delete: 'Delete',
  department: 'Function',
  division: 'Division',
  edit: 'Edit',
  email: 'Email',
  embedMsFormIframeTitle: 'Embedded MS Form',
  embedPowerBiIframeTitle: 'Embedded Power BI report',
  error: 'Error',
  followNicoDelvauxOnLinkedIn: 'Follow Nico Delvaux on LinkedIn',
  formErrorMessageMissingFields: 'The following fields appear to be missing:',
  formLabelDescription: 'Description',
  formLabelImage: 'Image',
  formLabelImageAltText: 'Image alt text',
  formLabelLink: 'Link',
  formLabelPublishDate: 'Publish date',
  formLabelTitle: 'Title',
  formValidationInvalid: 'Value is invalid',
  formValidationRequired: '{{displayName}} is required',
  formValidationMaxLength:
    '{{displayName}} can be no more than {{maxLength}} characters',
  formValidationMinLength:
    '{{displayName}} can be no less than {{minLength}} characters',
  formValidationUrl: '{{displayName}} is not a valid URL',
  formValidationFallbackDisplayName: 'Value',
  global: 'Global',
  globalWebcastLinkBoxTitle:
    'Not here for the Global Webcast? Continue browsing Avenue resources',
  globalWebcastLinkTextJobs: 'Jobs',
  globalWebcastLinkTextNews: 'News',
  globalWebcastLinkTextTools: 'My tools',
  globalWebcastRefresh: 'Back to Avenue home',
  heroBanner: 'Hero Banner',
  hiringManager: 'Hiring manager:',
  imageGalleryBadgeTitle: 'Gallery',
  imageGalleryEditTitle: 'Image Gallery',
  imageGalleryListHeader: 'Images',
  imageGalleryInvalidMimeType: 'File format is not supported',
  imageGalleryInvalidFileSize: 'File size is too large',
  imageGalleryMaxCountTitle: 'Max 10 images! ',
  imageGalleryMaxCountText:
    'A maximum of 10 images will be uploaded to the gallery, any additional images will be ignored.',
  imageGalleryModalNextImage: 'Next image',
  imageGalleryModalPreviousImage: 'Previous image',
  imagePickerButtonText: 'Images',
  imagePickerInfo1: 'Supported files : jpg, jpeg; PNG',
  imagePickerInfo2: 'Max 10 images, 2Mb per file',
  imageUploadError: 'Failed to upload image, please try again.',
  information: 'Information',
  informationOwner: 'Information Owner',
  insightsDatabaseTitle: 'Industry Insights',
  insightsDatabaseSubtitle:
    'Unlock industry insights and global awareness with articles from the Insights database',
  insightsDatabaseNoItemsTextWithLink:
    'We have nothing to show here right now. Explore more in {{Insights Database}}.',
  insightsDatabaseNotAvailable: 'No articles available',
  insightsDatabaseReadMore: 'All Insights',
  jobOpeningsNoJobs:
    'If you want to explore your career potential in the ASSA ABLOY Group then we are here to support you.',
  jobOpeningsTitle: 'Job openings',
  lastEdit: 'Last edit:',
  like: 'Like',
  loadMoreComments: 'More comments',
  loadMoreReplies: 'More replies',
  market: 'Market',
  manageContentActionsDelete: 'Delete',
  manageContentActionsDeleteDialogText:
    'Deleting these pages will automatically delete all the sub-pages associated with them.',
  manageContentActionsDeleteDialogTitle:
    'Are you sure you want to delete these page(s)?',
  manageContentActionsDeleteError:
    'Something went wrong when trying to delete library page(s)',
  manageContentActionsDeleteSuccess: 'Deleted library page(s)',
  manageContentActionsReassign: 'Reassign',
  manageContentActionsReassignDialogText: 'You can select only 1 employee',
  manageContentActionsReassignDialogTitle: 'Reassign page(s)',
  manageContentActionsReassignError:
    'Something went wrong when trying to reassign library page(s)',
  manageContentActionsReassignSuccess: 'Reassigned library page(s)',
  manageContentActionsReassignUserInputError: 'Select an employee to reassign',
  manageContentActionsRenew: 'Renew',
  manageContentActionsRenewDialogText:
    'Update the dates in the selected page(s) to be valid for two more years.',
  manageContentActionsRenewDialogTitle: "Renew page's dates",
  manageContentActionsRenewError:
    'Something went wrong when trying to renew library page(s)',
  manageContentActionsRenewSuccess: 'Renewed library page(s)',
  manageContentActionsRepublish: 'Republish',
  manageContentActionsRepublishDialogText:
    'Republishing the page(s) will also republish parent page(s).',
  manageContentActionsRepublishDialogTitle:
    'Are you sure you want to republish these page(s)?',
  manageContentActionsRepublishError:
    'Something went wrong when trying to republish library page(s)',
  manageContentActionsRepublishSuccess: 'Republished library page(s)',
  manageContentActionsSelectedMany: '{{count}} items selected',
  manageContentActionsSelectedOne: '{{count}} item selected',
  manageContentActionsUnpublish: 'Unpublish',
  manageContentActionsUnpublishDialogText:
    'Unpublishing these pages will automatically unpublish all the sub-pages associated with them.',
  manageContentActionsUnpublishDialogTitle: 'Unpublish page(s) ',
  manageContentActionsUnpublishError:
    'Something went wrong when trying to unpublish library page(s)',
  manageContentActionsUnpublishSuccess: 'Unpublished library page(s)',
  manageContentAdmin: 'Admin',
  manageContentBrokenLinks: 'Broken links',
  manageContentComments: 'Comments',
  manageContentCreateHeroBanner: 'Create Hero Banner',
  manageContentCreateNewsletterBanner: 'Create Newsletter Banner',
  manageContentDeletionDate: 'Deletion date',
  manageContentDivisions: 'Divisions',
  manageContentDrafts: 'Drafts',
  manageContentEmpty: 'No content to display.',
  manageContentEmptyLibraryDrafts: "You don't own any draft pages.",
  manageContentEmptyLibraryPublished: "You don't own any published pages.",
  manageContentEmptyLibraryUnpublished: "You don't own any unpublished pages.",
  manageContentExpiryDate: 'Expiry date',
  manageContentFeedActionsUnpublishDialogText:
    'Unpublishing these articles removes them from Avenue and makes them unavailable to users.',
  manageContentFeedActionsUnpublishDialogTitle: 'Unpublish article(s)',
  manageContentFeedActionsUnpublishError:
    'Something went wrong when trying to unpublish feed articles',
  manageContentFeedActionsUnpublishSuccess: 'Unpublished articles',
  manageContentFetchArticlesError:
    'Failed to get content, please try again later',
  manageContentHasSubPages: 'Has sub-pages',
  manageContentInformationOwner: 'Owner',
  manageContentLibraryDeletionInfo:
    'The page will be deleted 30 months after it was last updated',
  manageContentLibraryExpiryInfo:
    'The page will be unpublished 24 months after it was last updated',
  manageContentLikes: 'Likes',
  manageContentModifiedDate: 'Modified',
  manageContentOwnerless: 'Ownerless',
  manageContentPublished: 'Published',
  manageContentPublishedDate: 'Published date',
  manageContentPublishTime: 'Publish time',
  manageContentPublishedDateExpiryInfo:
    'The article will be unpublished 24 months after it was published',
  manageContentScheduled: 'Scheduled',
  manageContentTitle: 'Title',
  manageContentType: 'Type',
  manageContentTypeHeroBanner: 'Hero',
  manageContentTypeNewsletterBanner: 'Newsletter',
  manageContentUnpublished: 'Unpublished',
  manageContentViews: 'Views',
  myMoment: '#MyMoment',
  myMomentFetchError:
    'Something went wrong when trying to get #MyMoment page, please try again later.',
  myMomentIframeTitle: 'My moment embedded video',
  myMomentInputTitle: 'Share your moment here',
  myMomentInputPlaceHolder: 'Share a moment',
  myMomentInputSubmit: 'Post Moment',
  myMomentInputSubmitModalTitle: 'Do you want to publish this moment?',
  myMomentInputSubmitModalText:
    'This moment will be visible to other employees',
  myMomentLatest: 'Latest #MyMoment',
  myMomentLoadMore: 'More moments',
  myMomentReadMore: 'All Moments',
  myMomentVideos: 'Moments shared by:',
  newsletterBanner: 'Newsletter Banner',
  newsletterBannerCreateError:
    'Something went wrong when creating the newsletter banner',
  newsletterBannerCreateSuccess: 'Successfully created newsletter banner',
  newsletterBannerDeleteError:
    'Something went wrong when trying to delete the newsletter banner',
  newsletterBannerDeleteSuccess: 'Successfully deleted newsletter banner',
  newsletterBannerDeleteText: 'It will not be possible to restore the content.',
  newsletterBannerDeleteTitle:
    'Are you sure you want to delete this newsletter banner?',
  newsletterBannerEditError:
    'Something went wrong when updating the newsletter banner',
  newsletterBannerEditSuccess: 'Successfully updated newsletter banner',
  newsletterBannerGetError:
    'Something went wrong when fetching newsletter banner data',
  notAvailable: 'N/A',
  notAvailableLong: 'Not available',
  notFound: 'Not found',
  pageTitleHome: 'Home',
  pageViewFeedToolTipText: 'Total views for this page. Last 24 h not included.',
  pageViewLibraryToolTipText:
    'Views for this page in the last 30 days. Last 24 h not included.',
  paginationRowsPerPage: 'Rows per page',
  phoneMobile: 'Mobile Phone',
  phoneOffice: 'Office Phone',
  productOfTheWeek: 'Product of the week',
  productOfTheWeekGallery: 'Gallery',
  productOfTheWeekGalleryTitle: 'Product of the week gallery',
  productOfTheWeekGalleryLinkLabel: 'Previous products',
  profile: 'Profile',
  profileAvatarAlt: 'Employee profile picture',
  profileDetails: 'Profile details',
  profileFetchError:
    'Something went wrong when trying to get your profile data, please try again later',
  profileFetchFormDataError:
    'An error occurred while populating parts of the profile form data',
  profileManageBanners: 'Banners',
  profileManageFeed: 'Feed articles',
  profileManageInsightsDatabase: 'Insights database',
  profileManageLibrary: 'Library pages',
  profileOverview: 'Profile overview',
  profilePreferences: 'Preferences',
  profilePreferencesCountryInfo:
    'Select a division in order to change the country.',
  profilePreferencesDivisionInfo:
    'You have not been assigned to a division, please contact support for assistance.',
  profilePreferencesInfo1:
    'We fetch your profile information from our HR systems.',
  profilePreferencesInfo2:
    'To make sure the content on Avenue matches your profile and personal interest, you are able to update your Avenue profile here.',
  profilePreferencesInfo3:
    'These changes only have effect on Avenue and will not reflect to any other system.',
  profilePreferencesInfo4:
    'Want to look your best? Add or change your profile picture {{here}}.',
  profileSettings: 'Profile settings',
  profileSubscriptions: 'Subscriptions',
  profileSubscriptionsInsightsDatabase:
    'I would like to receive the Latest Insights Database Articles in my Inbox.',
  profileUpdateSuccess: 'Your profile has been updated',
  profileUpdateError: 'Failed to update profile, please try again later',
  publish: 'Publish',
  published: 'Published',
  publishDateLabel: 'Select publish date',
  readMore: 'Read more',
  readMoreStories: 'All Stories',
  readingTime: 'Reading time less than {{readingTime}} min',
  released: 'Released',
  save: 'Save',
  search: 'Search',
  searchEmployees: 'Search employees',
  searchKeywordsInfoText:
    'Here you can enter important keywords that you think people might use to search for your content.',
  searchKeywordsTitle: 'Keywords',
  searchSelectorNoResults: 'No search results',
  searchSelectorFetchError:
    'Failed to get search suggestions, please try again later',
  select: 'Select',
  send: 'Send',
  segment: 'Segment',
  share: 'Share',
  shareEmailTemplateArticleSubject: 'Recommended read: {{title}}',
  shareEmailTemplateArticleBody:
    'Hi,\n\nI came across this article on Avenue and thought it might be of interest to you. Here is the link to the article: {{link}}\n\nBest regards,\n{{name}}',
  shareMenuCopyLink: 'Copy link',
  shareMenuCopyLinkConfirmation: 'Copied to clipboard',
  shareMenuSendEmail: 'E-mail',
  shareMenuSendEmailConfirmation: 'An email is created in your email client',
  shortcutsManage: 'Manage',
  shortcutsManageTools: 'Manage pinned tools',
  shortcutsPinnedTools: 'Pinned tools',
  shortcutsPinnedToolsEmpty: `You haven't pinned any tools yet. Click "Manage" to select which tools to pin here.`,
  shortcutsPinnedToolsInfo: 'You are able to pin a maximum of 10 tools.',
  shortcutsPinnedToolsLimit: 'You have reached a maximum of 10 pinned tools.',
  site: 'Site',
  siteFetchError: 'Failed to get sites, please try again later',
  storiesNotAvailable: 'No stories available',
  storiesTitle: 'Stories',
  successful: 'Successful',
  translate: 'Translate',
  updatedLibraryArticlesTitle: 'Updated Pages',
  updatedLibraryArticlesNotAvailable:
    'We have nothing to show here right now. Explore more in the {{Group Library}}.',
  upload: 'Upload',
  videos: 'Videos',
  warning: 'Warning',
  whitelistLinkDialogText1: 'You are about to leave Avenue for:',
  whitelistLinkDialogText2: 'Are you sure?',
  whitelistLinkDialogTitle: 'You are leaving Avenue',
  yes: 'Yes',
};

export { en };
