import Stack from '@mui/material/Stack';
import * as productOfTheWeekApi from 'api/cms/ProductOfTheWeek';
import { GetAllProductOfTheWeekResponse } from 'api/cms/ProductOfTheWeek/types';
import * as feedbackApi from 'api/feedback';
import {
  LikesAndCommentsResponse,
  UpdateLikeResponse,
} from 'api/feedback/transformers';
import * as feedbackTransformers from 'api/feedback/transformers';
import { useUser } from 'components/Context/User';
import { TablePagination } from 'componentsNew';
import { Page, PageContentCard } from 'layoutNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translations } from 'translations';
import { GAonClickProductOfTheWeekGalleryItem } from 'utils/analytics';

import * as helpers from './helpers';
import {
  ProductOfTheWeekGalleryGrid,
  ProductOfTheWeekGalleryItem,
} from './ProductOfTheWeekGalleryGrid';
import { ProductOfTheWeekGalleryHeader } from './ProductOfTheWeekGalleryHeader';
import { ProductOfTheWeekSheet } from './ProductOfTheWeekSheet';

const elementId = 'product-of-the-week-gallery';

const INITIAL_PAGINATION = {
  page: 1,
  itemsPerPage: 12,
};

const ProductOfTheWeekGallery = () => {
  const [pagination, setPagination] = useState<{
    page: number;
    itemsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [content, setContent] = useState<{
    items: ProductOfTheWeekGalleryItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [open, setOpen] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();
  const mountedRef = useRef<boolean>(true);

  const fetchContent = useCallback(
    async (
      pagination: { page: number; itemsPerPage: number },
      userId: string
    ) => {
      let items: ProductOfTheWeekGalleryItem[] = [];
      let total = 0;

      try {
        const queryParams = helpers.getRequestQueryParams(pagination);
        const response = (await productOfTheWeekApi.getAllProductOfTheWeek(
          queryParams
        )) as GetAllProductOfTheWeekResponse;
        total = response.data.data.totalRows;
        items = response.data.data.products;
      } catch {
        return { items, total };
      }

      try {
        const response = (await feedbackApi.getLikesAndComments(
          items.map((item) => item._doc)
        )) as LikesAndCommentsResponse;

        const likesAndCommentsMetaById =
          feedbackTransformers.likesAndCommentsResponseToLikesAndCommentsMetaByArticleId(
            response,
            userId
          );

        items.forEach((item) => {
          const likesAndCommentsMeta =
            likesAndCommentsMetaById[`cms-${item._doc}`];
          if (likesAndCommentsMeta) {
            item.likesMeta = {
              likesCount: likesAndCommentsMeta.likesCount,
              isLikedByMe: likesAndCommentsMeta.isLikedByMe,
            };
          }
        });
      } catch {}
      return { items, total };
    },
    []
  );

  const updateLike = useCallback(async (id, like: boolean) => {
    let result: { isLikedByMe: boolean; likesCount: number } | null = null;
    try {
      const response = like
        ? ((await feedbackApi.sendLike(
            id.replace('cms-', '')
          )) as UpdateLikeResponse)
        : ((await feedbackApi.deleteLike(
            id.replace('cms-', '')
          )) as UpdateLikeResponse);
      result = {
        isLikedByMe: response.data.meta.likedByMe,
        likesCount: response.data.meta.total,
      };
    } catch {}
    return result;
  }, []);

  const handleLikeChange = useCallback(
    async (item: ProductOfTheWeekGalleryItem) => {
      if (!item.likesMeta) {
        return;
      }
      const id = item._doc;
      const like = !item.likesMeta.isLikedByMe;

      const result = await updateLike(id, like);
      if (!result || !mountedRef.current) {
        return;
      }
      const newItems = content.items.map((item) => {
        if (item._doc !== id) {
          return item;
        }
        const newItem: ProductOfTheWeekGalleryItem = {
          ...item,
          likesMeta: {
            isLikedByMe: result.isLikedByMe,
            likesCount: result.likesCount,
          },
        };
        return newItem;
      });
      setContent({ total: content.total, items: newItems });
    },
    [content.items, content.total, updateLike]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; itemsPerPage: number }) => {
      setPagination(newPagination);
      if (
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.itemsPerPage < pagination.itemsPerPage
      ) {
        setContent({
          ...content,
          items: content.items.slice(0, newPagination.itemsPerPage),
        });
        return;
      }
      setIsLoading(true);
      const newContent = await fetchContent(newPagination, user.userId);
      if (!mountedRef.current) {
        return;
      }
      setContent(newContent);
      setIsLoading(false);
    },
    [
      pagination.page,
      pagination.itemsPerPage,
      fetchContent,
      user.userId,
      content,
    ]
  );

  useEffect(() => {
    async function fetchInitialContent() {
      if (!user.userId) {
        return;
      }
      setIsLoading(true);
      const newContent = await fetchContent(INITIAL_PAGINATION, user.userId);
      if (!mountedRef.current) {
        return;
      }
      setContent(newContent);
      setIsLoading(false);
    }
    fetchInitialContent();
  }, [fetchContent, user.userId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const openItem = useMemo(
    () => content.items.find((item) => item._doc === open),
    [content.items, open]
  );

  return (
    <Page
      title={[
        translations.productOfTheWeek,
        translations.productOfTheWeekGallery,
      ]}
    >
      <PageContentCard variant="elevated" sx={{ padding: 0 }}>
        <Stack>
          <ProductOfTheWeekGalleryHeader />
          <ProductOfTheWeekGalleryGrid
            isLoading={isLoading}
            items={content.items}
            onLike={handleLikeChange}
            onClick={(item) => {
              setOpen(item._doc);
              GAonClickProductOfTheWeekGalleryItem(item.title);
            }}
          />
        </Stack>
        {content.total > INITIAL_PAGINATION.itemsPerPage && (
          <TablePagination
            disabled={isLoading}
            page={pagination.page}
            rowsPerPage={pagination.itemsPerPage}
            rowsPerPageOptions={[12, 24, 48]}
            sx={(theme) => ({
              padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
              borderTop: `1px solid ${theme.colors.border.surfacePrimary}`,
            })}
            count={
              content.total % pagination.itemsPerPage > 0
                ? Math.trunc(content.total / pagination.itemsPerPage) + 1
                : content.total / pagination.itemsPerPage
            }
            onPageChange={(value: number) =>
              handlePaginationChange({
                itemsPerPage: pagination.itemsPerPage,
                page: value,
              })
            }
            onRowsPerPageChange={(value: number) =>
              handlePaginationChange({
                itemsPerPage: value,
                page: 1,
              })
            }
          />
        )}
      </PageContentCard>
      <ProductOfTheWeekSheet
        open={Boolean(open)}
        id={`${elementId}-sheet`}
        product={openItem}
        likesMeta={openItem?.likesMeta}
        onLike={() => {
          if (openItem) {
            handleLikeChange(openItem);
          }
        }}
        onClose={() => setOpen(null)}
      />
    </Page>
  );
};

export { ProductOfTheWeekGallery };
