import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as organizationApi from 'api/organization';
import { AlertBar } from 'componentsNew';
import { AlertBarType } from 'componentsNew/AlertBar';
import { ReactComponent as CogToothIcon } from 'componentsNew/Icon/CogTooth.svg';
import { ReactComponent as ExclaimationTriangleFilledIcon } from 'componentsNew/Icon/ExclaimationTriangleFilled.svg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RequestStatus } from 'types';

const elementId = 'profile-actions';

const ProfileActions = () => {
  const [deleteProfileStatus, setDeleteProfileStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const mountedRef = useRef<boolean>(true);
  const theme = useTheme();

  const deleteProfileSettings = useCallback(async () => {
    try {
      setDeleteProfileStatus(RequestStatus.Loading);
      await organizationApi.removeConsent();
      if (!mountedRef.current) return;
      setDeleteProfileStatus(RequestStatus.Success);
      setAnchorEl(null);
    } catch {
      if (!mountedRef.current) return;
      setDeleteProfileStatus(RequestStatus.Failure);
    }
  }, []);

  const actions: {
    title: string;
    text: string;
    icon: React.ReactNode;
    iconColor: string;
    disabled: boolean;
    onClick: () => void;
  }[] = useMemo(() => {
    return [
      {
        title: 'Remove profile settings',
        text: 'Click here to remove all profile settings from user. This is strictly for testing and only visible in non production environments.',
        icon: <ExclaimationTriangleFilledIcon />,
        iconColor: theme.colors.icon.critical,
        disabled: deleteProfileStatus === RequestStatus.Loading,
        onClick: deleteProfileSettings,
      },
    ];
  }, [deleteProfileSettings, deleteProfileStatus, theme.colors.icon.critical]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        top: theme.spacing('xs'),
        right: theme.spacing('xs'),
      })}
    >
      <Tooltip title="Profile actions">
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="small"
          aria-controls={`${elementId}-menu`}
          aria-haspopup="true"
          aria-expanded={open}
          sx={(theme) => ({
            'svg > path': { stroke: theme.colors.icon.brandBase },
          })}
        >
          <CogToothIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id={`${elementId}-menu`}
        open={open}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {actions.map((action, index) => (
          <MenuItem
            sx={(theme) => ({
              maxWidth: '20rem',
              whiteSpace: 'normal',
              padding: `${theme.spacing('xs')} ${theme.spacing('sm')}`,
              svg: {
                flexShrink: 0,
                alignSelf: 'baseline',
                marginTop: theme.spacing('xxxs'),
                marginRight: theme.spacing('xs'),
                path: { fill: action.iconColor },
              },
              p: {
                wordBreak: 'break-word',
                color: theme.colors.text.primary,
              },
            })}
            key={`${elementId}-${index}`}
            disabled={action.disabled}
            onClick={action.onClick}
          >
            {action.icon}
            <Stack>
              <Typography variant="body2" fontWeight="bold">
                {action.title}
              </Typography>
              <Typography variant="caption">{action.text}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      <AlertBar
        snackbar
        open={deleteProfileStatus === RequestStatus.Failure}
        type={AlertBarType.Critical}
        text="Failed to delete profile settings"
        onClose={() => setDeleteProfileStatus(RequestStatus.Idle)}
      />
      <AlertBar
        snackbar
        open={deleteProfileStatus === RequestStatus.Success}
        type={AlertBarType.Success}
        text="Profile settings were successfully deleted"
        onClose={() => setDeleteProfileStatus(RequestStatus.Idle)}
      />
    </Box>
  );
};

export { ProfileActions };
