import DataModel from './DataModel';

export const typeToLabel = {
  article: 'Library',
  blogpost: 'Blog',
  newsarticle: 'News',
  storyarticle: 'Story',
  pressrelease: 'Press',
  position: 'Jobs',
  glossary: 'Glossary',
  document: 'Document',
  person: 'People',
  tool: 'Tools',
  portalarticle: 'Portal',
  innovationportal: 'Innovation Portal',
  strategyportal: 'Strategy Portal',
  codeofconductportal: 'Code of Conduct Portal',
};

const sortOrder = [
  'person',
  'article',
  'newsarticle',
  'storyarticle',
  'glossary',
  'position',
  'document',
  'blogpost',
  'pressrelease',
  'codeofconductportal',
  'innovationportal',
  'strategyportal',
  'tool',
];

class SearchHit extends DataModel {
  constructor(id, attributes, includes, type) {
    super('SearchHit', id, attributes, includes);
    this.type = type.substr(0, type.indexOf('searchhits'));
  }

  getTypeLabel() {
    if (this.type === 'portalarticle') {
      return typeToLabel[`${this.attributes.portalType}portal`];
    }

    return typeToLabel[this.type];
  }

  getPersonName() {
    const { firstName, lastName } = this.attributes;

    return `${firstName} ${lastName}`;
  }

  isPersonHit() {
    return this.type === 'person';
  }

  isGlossaryHit() {
    return this.type === 'glossary';
  }

  isJobHit() {
    return this.type === 'position';
  }

  isDocumentHit() {
    return this.type === 'document';
  }

  isToolHit() {
    return this.type === 'tool';
  }

  getSearchSortOrder() {
    const { type, attributes } = this;
    if (attributes && attributes.portalType) {
      return sortOrder.indexOf(`${attributes.portalType}portal`);
    }
    return sortOrder.indexOf(type);
  }

  getCMSPointer() {
    return { oid: this.getOid(), name: this.getPersonName() };
  }

  getArticleLabels = () => {
    const { author, country, division } = this.includes;

    const label = this.getTypeLabel();

    const labels = [{ name: label }];
    if (author) {
      labels.push({ name: author.name, ghost: true });
    } else {
      if (country) {
        labels.push({ name: country.name, transparent: true });
      } else if (division) {
        labels.push({ name: division.name, transparent: true });
      } else {
        labels.push({ name: 'Global', transparent: true });
      }
    }

    return labels;
  };

  getPersonLabels = () => {
    const label = this.getTypeLabel();
    const country = this.attributes.country || 'n/a';

    return [{ name: label }, { name: country, transparent: true }];
  };

  getGlossaryLabels = () => {
    const label = this.getTypeLabel();
    return [{ name: label, color: 'gray' }];
  };

  getJobLabelsForSearchResult = () => {
    const { locations } = this.getAttributes();
    const labels = [];
    if (locations) {
      locations.forEach((location) =>
        labels.push({
          name: location,
          color: 'search-blue',
          transparent: 'light',
          topMargin: 'label-no-top-margin',
        })
      );
    }
    return labels;
  };

  getJobLabels = () => {
    const { locations } = this.getAttributes();
    const typeLabel = this.getTypeLabel();
    const labels = [{ name: typeLabel, color: 'dusty-gray' }];
    if (locations) {
      locations.forEach((location) =>
        labels.push({ name: location, color: 'transparent-gray' })
      );
    }
    return labels;
  };

  getToolsLabels = () => {
    const label = this.getTypeLabel();
    return [{ name: label, color: 'black' }];
  };

  getUrl() {
    const { previewUrl } = this.attributes;
    if (previewUrl) {
      return previewUrl;
    }
    return super.getUrl();
  }

  static getFilterLabel(type) {
    return typeToLabel[type];
  }

  static transformFilterToSearchType(filter) {
    return filter === 'innovationportal' ||
      filter === 'strategyportal' ||
      filter === 'codeofconductportal'
      ? 'portalarticle'
      : filter;
  }

  static transformFilterToPortalType(filter) {
    return filter === 'innovationportal' ||
      filter === 'strategyportal' ||
      filter === 'codeofconductportal'
      ? filter.replace('portal', '')
      : null;
  }
}

export default SearchHit;
