import './CommentAndLike.scss';

import classNames from 'classnames';
import Button from 'components/Button';
import { Tooltip } from 'componentsNew';
import { ReactComponent as CommentIcon } from 'componentsNew/Icon/Comment.svg';
import { ReactComponent as CommentFilledIcon } from 'componentsNew/Icon/CommentFilled.svg';
import PropTypes from 'prop-types';

const CommentButton = ({
  commentCount,
  commentedByMe,
  focusCommentsInput = () => {},
  commentsEnabled = true,
}) => {
  if (!commentsEnabled) return null;

  const commentsClassName = classNames(
    'comment-like-button',
    'comment-button',
    {
      'comment-like-button--commented-by-others':
        !commentedByMe && commentCount > 0,
    }
  );
  return (
    <Button
      onClick={focusCommentsInput}
      size="small"
      color="none"
      className={commentsClassName}
      width="narrow"
    >
      <Tooltip title="Comment">
        {commentedByMe ? <CommentFilledIcon /> : <CommentIcon />}
      </Tooltip>
      <span className="comment-like-button__text">{commentCount}</span>
    </Button>
  );
};

CommentButton.propTypes = {
  id: PropTypes.string,
  commentCount: PropTypes.number,
  commentedByMe: PropTypes.bool,
  focusCommentsInput: PropTypes.func,
};

export default CommentButton;
