import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { LikesAndCommentsMeta } from 'api/feedback/transformers';
import { useUser } from 'components/Context/User';
import {
  CommentButton,
  LikeButton,
  PageViews,
  ProfileMini,
  ShareButton,
} from 'componentsNew';
import * as shareButtonHelpers from 'componentsNew/ShareButton/helpers';
import { translations } from 'translations';
import { GAonArticleShared, GAonLikeSent } from 'utils/analytics';

import { ArticleCreatedBy } from './ArticleCreatedBy';
import { ArticleEditedBy } from './ArticleEditedBy';

const elementId = 'article-footer';

type ArticleFooterProps = {
  sx?: SxProps<Theme>;
  article: {
    title: string;
    informationOwner?: {
      name: string;
      id: string;
    };
    author?: {
      name: string;
      id: string;
    };
    editor?: {
      name: string;
      id: string;
    };
    createdDate?: string;
    editedDate?: string;
  };
  likesAndCommentsMeta: LikesAndCommentsMeta | null;
  pageViews: number | null;
  hidePageViews?: boolean;
  hideLikeButton?: boolean;
  hideCommentButton?: boolean;
  hideShareButton?: boolean;
  onLikeClick: () => void;
  onCommentClick: () => void;
};

const ArticleFooter = ({
  sx,
  article,
  likesAndCommentsMeta,
  pageViews,
  hidePageViews,
  hideLikeButton,
  hideCommentButton,
  hideShareButton,
  onCommentClick,
  onLikeClick,
}: ArticleFooterProps) => {
  const user = useUser();

  return (
    <Stack
      sx={[
        (theme) => ({ gap: theme.spacing('xxs') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        id={elementId}
        sx={(theme) => ({
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: theme.spacing('xs'),
          padding: theme.spacing('md'),
          backgroundColor: theme.colors.surface.secondary,
          borderRadius: theme.border.radius.lg,
        })}
      >
        {article.informationOwner && (
          <ProfileMini
            id={`${elementId}-information-owner`}
            profileId={article.informationOwner.id}
            profileName={article.informationOwner.name}
            hideTitle
          />
        )}
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing('xxs'),
            flexWrap: 'wrap',
            marginLeft: 'auto',
          })}
          divider={
            <Divider
              orientation="vertical"
              sx={(theme) => ({
                height: '1rem',
                borderColor: theme.colors.border.surfaceSecondary,
              })}
            />
          }
        >
          {!hidePageViews && pageViews && (
            <PageViews
              id={`${elementId}-page-views`}
              value={pageViews}
              tooltip={translations.pageViewFeedToolTipText}
            />
          )}
          {likesAndCommentsMeta && (
            <>
              {!hideLikeButton && (
                <LikeButton
                  id={`${elementId}-like`}
                  isLikedByMe={likesAndCommentsMeta.isLikedByMe}
                  likesCount={likesAndCommentsMeta.likesCount}
                  onClick={() => {
                    onLikeClick();
                    if (!likesAndCommentsMeta.isLikedByMe) {
                      GAonLikeSent(article.title);
                    }
                  }}
                />
              )}
              {!hideCommentButton && (
                <CommentButton
                  id={`${elementId}-comment`}
                  isCommentedByMe={likesAndCommentsMeta.isCommentedByMe}
                  commentsCount={likesAndCommentsMeta.commentsCount}
                  onClick={onCommentClick}
                />
              )}
            </>
          )}
          {!hideShareButton && (
            <ShareButton
              id={`${elementId}-share`}
              copyLink={window.location.href}
              emailLink={shareButtonHelpers.getShareArticleEmailLink({
                articleTitle: article.title,
                articleLink: window.location.href,
                senderName: user.displayName,
              })}
              onCopyLinkClick={() => GAonArticleShared(article.title)}
              onSendEmailClick={() => GAonArticleShared(article.title)}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xxs'),
        })}
        divider={
          <Divider
            orientation="vertical"
            sx={(theme) => ({
              height: '1rem',
              borderColor: theme.colors.border.surfaceSecondary,
            })}
          />
        }
      >
        {article.author && !article.informationOwner && (
          <ArticleCreatedBy
            id={`${elementId}-author`}
            authorId={article.author.id}
            authorName={article.author.name}
          />
        )}
        {article.editor && article.editedDate && (
          <ArticleEditedBy
            id={`${elementId}-editor`}
            date={article.editedDate}
            editorId={article.editor.id}
            editorName={article.editor.name}
          />
        )}
      </Stack>
    </Stack>
  );
};

export { ArticleFooter };
