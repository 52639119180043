import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import 'abortcontroller-polyfill';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { initializeGA } from 'utils/analytics';
import configureAuth, { configureAxios } from 'utils/auth';
import { msalGetActiveAccount } from 'utils/auth';

import PublicRoot from './pages/Public/Root';
import Root from './pages/Root';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) =>
    registration.unregister()
  );
}

const isPublicPage = /^\/public/.test(window.location.pathname);

//TODO: would be great to create forceredirect component to manage those
if (window.location.pathname === '/faq') {
  window.location.href = `${window.location.origin}/public/faq`;
} else if (window.location.pathname === '/employee-information') {
  window.location.href = `${window.location.origin}/public/employee-information`;
} else if (isPublicPage) {
  configureAxios();
  initializeGA();
  ReactDOM.render(
    <Router>
      <PublicRoot />
    </Router>,
    document.getElementById('root')
  );
} else {
  configureAuth().then(() => {
    const account = msalGetActiveAccount();
    if (!account) return;
    initializeGA();
    ReactDOM.render(<Root />, document.getElementById('root'));
  });
}
