import './Container.scss';

import classNames from 'classnames';
import Title from 'components/Title';
import React from 'react';

interface ContainerProps {
  children?: React.ReactElement | React.ReactElement[];
  title?: string;
  color?: 'teal' | 'gray' | 'darkgray';
  type?: string;
  className?: string;
  width?: 'thin' | 'default' | 'xxl' | 'xxxl' | 'xxxxl' | 'full';
  mobileRow?: boolean;
  padding?: boolean;
  verticalAlign?: boolean;
  forceColumns?: number;
  mobileColumnSpacing?: string;
}

export const Container = ({
  children,
  title,
  color,
  type = 'space-between',
  className = '',
  width = 'default',
  mobileRow = false,
  padding = true,
  verticalAlign = false,
  forceColumns,
  mobileColumnSpacing,
}: ContainerProps) => {
  let hasSpannedChildren = false;
  const count = React.Children.count(children);
  const childrenArray = React.Children.toArray(children);

  const clonedChildren = React.Children.map(childrenArray, (child) => {
    const childElement = child as React.ReactElement;
    if (childElement.props['span']) {
      hasSpannedChildren = true;
    }
    return React.cloneElement(childElement, {
      count: count,
    });
  });

  const containerClassName = classNames('container', {
    [type]: type,
    [`container--${color}`]: color,
    [className]: className,
    [`container--${width}`]: width,
    'container--has-spanned-children': hasSpannedChildren,
    'container--padding': padding,
    'container--mobile-column-spacing': mobileColumnSpacing,
    [`container--columns-${forceColumns}`]: forceColumns,
  });

  const containerInnerClassName = classNames('container__inner', {
    'container--mobile-row': mobileRow,
    'container__inner--vertical-align': verticalAlign,
  });

  return (
    <div className={containerClassName}>
      {title && <Title>{title}</Title>}
      <div className="container__outer">
        <div className={containerInnerClassName}>{clonedChildren}</div>
      </div>
    </div>
  );
};

Container.defaultProps = {
  type: 'space-between',
  className: '',
  width: 'default',
  mobileRow: false,
  padding: true,
  verticalAlign: false,
};
