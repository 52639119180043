import './TableHeader.scss';

import * as alertApi from 'api/cms/alert';
import Checkbox from 'components/Checkbox';
import { ReactComponent as DeleteIcon } from 'components/Icon/DeleteForever.svg';
import Modal from 'components/Modal';
import { Component } from 'react';

const HeaderItem = (props) => {
  return (
    <div>
      <span className="heading">{props.heading}</span>
    </div>
  );
};

class TableHeader extends Component {
  state = {
    deleteItem: true,
    inProgress: false,
    showDeleteModal: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deleteNotifications = async () => {
    if (this._isMounted) {
      const { selectedNotifications, setData, resetFn } = this.props;
      this.setState({ inProgress: true });
      for (let selectedNotification of selectedNotifications) {
        await alertApi.deleteAlert(selectedNotification);
      }
      setData(selectedNotifications);
      resetFn();
      this.setState({
        deleteItem: false,
        inProgress: false,
        showDeleteModal: false,
      });
    }
  };

  onCloseModal = () => {
    if (this._isMounted) {
      this.setState({ showDeleteModal: false });
    }
  };

  onShowModal = () => {
    if (this._isMounted) {
      this.setState({ showDeleteModal: true });
    }
  };

  render() {
    const currentAction = {
      label: 'Delete',
      title: 'Are you sure you want to delete this Alert?',
      text: "The action can't be undone",
      action: this.deleteNotifications,
    };
    const { isSelectAll, selectAllNotifications, isChecked } = this.props;
    const { inProgress, showDeleteModal } = this.state;
    return (
      <div className="table-header m-t-5">
        <div className="m-r-5">
          <Checkbox onChange={selectAllNotifications} checked={isSelectAll} />
        </div>
        <div className="table-header__item--black">
          <HeaderItem heading="Title"></HeaderItem>
          <span className="m-l-2">({this.props.count.length})</span>
        </div>
        <div className="table-header__item">
          <HeaderItem heading="Start Date"></HeaderItem>
        </div>
        <div className="table-header__item">
          <HeaderItem heading="End Date"></HeaderItem>
        </div>
        {showDeleteModal && (
          <Modal
            onClose={this.onCloseModal}
            title={currentAction.title}
            buttons={[
              {
                color: 'white',
                title: 'cancel',
                onClick: this.onCloseModal,
                disabled: inProgress,
              },
              {
                color: 'black',
                title: inProgress ? '...' : 'Ok',
                onClick: currentAction.action,
                disabled: inProgress,
              },
            ]}
            disableClickOutside={inProgress}
            focus={false}
          >
            {currentAction.text}
          </Modal>
        )}
        {isChecked && (
          <DeleteIcon
            className="table-header__item--white"
            onClick={this.onShowModal}
          />
        )}
      </div>
    );
  }
}

export default TableHeader;
