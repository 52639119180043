import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { WhitelistValidationLink } from 'componentsNew';
import { translations } from 'translations';
import { GAonClickHomeBanner } from 'utils/analytics';
import * as articleImageUtils from 'utils/cms/articleImage';

import bannerBackgroundImage from './banner_background.png';

type BannerLargeProps = {
  id: string;
  title?: string;
  description?: string;
  linkUrl?: string;
  linkText?: string;
  imageUrl?: string;
  imageAltText?: string;
};

const BannerLarge = ({
  id,
  title,
  description,
  linkUrl,
  linkText,
  imageUrl,
  imageAltText,
}: BannerLargeProps) => {
  const imageSrc =
    imageUrl &&
    articleImageUtils.getFormattedArticleImageSrc(imageUrl, {
      isPortrait: false,
      imageSize: 'md',
    });

  return (
    <Stack
      id={id}
      sx={(theme) => ({
        flexDirection: { xs: 'column-reverse', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        rowGap: theme.spacing('sm'),
        columnGap: theme.spacing('md'),
        padding: { xs: theme.spacing('sm'), md: theme.spacing('md') },
        backgroundColor: theme.colors.surface.informative,
        boxShadow: theme.elevation.sm,
        position: 'relative',
      })}
    >
      <Box
        sx={() => ({
          backgroundImage: `url(${bannerBackgroundImage})`,
          backgroundColor: 'lightgray',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          content: '" "',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0.1,
        })}
      />
      <Stack
        sx={(theme) => ({
          zIndex: 2,
          alignItems: 'baseline',
          rowGap: theme.spacing('sm'),
          ...((title || '').length <= 50 &&
            (description || '').length <= 200 && { maxWidth: '40rem' }),
        })}
      >
        {title && (
          <Typography
            id={`${id}-title`}
            variant="h2"
            sx={(theme) => ({
              color: theme.colors.text.brand,
              wordBreak: 'break-word',
              lineHeight: 1.2,
              fontWeight: 'bold',
              fontSize: { lg: theme.typography.display4.fontSize },
            })}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            id={`${id}-description`}
            variant="body2"
            sx={(theme) => ({
              color: theme.colors.text.secondary,
              wordBreak: 'break-word',
              fontSize: { lg: theme.typography.h4.fontSize },
            })}
          >
            {description}
          </Typography>
        )}
        {linkUrl && (
          <WhitelistValidationLink
            id={`${id}-link`}
            href={linkUrl}
            referrerPolicy="no-referrer"
            buttonVariant="outlined"
            sx={{ wordBreak: 'break-word' }}
            onClick={() => GAonClickHomeBanner(linkText, HeroBannerSize.Large)}
          >
            {linkText || translations.readMore}
          </WhitelistValidationLink>
        )}
      </Stack>
      <Box
        id={`${id}-image`}
        component="img"
        src={imageSrc}
        alt={imageAltText}
        title={imageAltText}
        sx={() => ({
          zIndex: 2,
          aspectRatio: 25 / 15.75,
          objectFit: 'cover',
          width: { xs: '100%', md: '28rem' },
        })}
      />
    </Stack>
  );
};

export { BannerLarge };
