import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;
const route = `${cms}/library`;

export const getHomeNode = ({ divisionId, countryId, regionId }) => {
  if (divisionId) {
    return createRequest({
      method: 'get',
      url: `${route}/homeNode/${divisionId}${regionId ? `/${regionId}` : ''}${
        countryId ? `/${countryId}` : ''
      }`,
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 404,
    });
  }
  return Promise.resolve({
    status: 404,
  });
};

export const getTree = (id) =>
  createRequest({
    method: 'get',
    url: `${route}/sub-tree${id}`,
    errorMessages: {
      '*': 'Failed to get navigation tree, please contact support for assistance.',
    },
  });

export const getArticle = (id) =>
  createRequest({
    method: 'get',
    url: `${route}/article/${id}`,
    errorMessages: {
      '*': 'Failed to get article, please contact support for assistance.',
    },
  });

export const getLastModifiedArticles = (query) =>
  createRequest({
    method: 'get',
    url: `${route}/article-last-modified${query}`,
    errorMessages: {
      '*': 'Failed to get article, please contact support for assistance.',
    },
  });

export const updateArticle = (id, data) =>
  createRequest({
    method: 'put',
    url: `${route}/article/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update article, please contact support for assistance.',
    },
  });

export const createArticle = (data) =>
  createRequest({
    method: 'post',
    url: `${route}/article`,
    data,
    errorMessages: {
      '*': 'Failed to create article, please contact support for assistance.',
    },
  });

export const deleteArticle = (id) =>
  createRequest({
    method: 'delete',
    url: `${route}/article/${id}`,
    errorMessages: {
      '*': 'Failed to delete article, please contact support for assistance.',
    },
  });

export const getChildArticleCount = (id) =>
  createRequest({
    method: 'get',
    url: `${route}/article-children-count/${id}`,
    errorMessages: {
      '*': 'Failed to get child article count, please contact support for assistance.',
    },
  });

export const getArticleUrl = (id) =>
  createRequest({
    method: 'get',
    url: `${cms}/links/${id}`,
    errorMessages: {
      '*': 'Failed to get child article count, please contact support for assistance.',
    },
  });

export const getMenuItems = () =>
  createRequest({
    method: 'get',
    url: `${cms}/menuitems`,
    errorMessages: {
      '*': 'Failed to get menu items, please contact support for assistance.',
    },
  });
