import { UserConsumer } from 'components/Context/User';
import { Container } from 'components/Grid';
import OnlyWithRole from 'components/OnlyWithRole';
import PageTitle from 'components/PageTitle';

import TableData from './components/TableData';

const Alert = () => (
  <OnlyWithRole
    isAllRolesRequired={false}
    viewableByRole={['AvenueNotificationEditor', 'AvenueSuperuser']}
  >
    <PageTitle titles={['Alert']} />
    <Container>
      <UserConsumer>
        {({ divisionId, countryId }) => {
          return (
            divisionId &&
            countryId && (
              <TableData divisionId={divisionId} countryId={countryId} />
            )
          );
        }}
      </UserConsumer>
    </Container>
  </OnlyWithRole>
);

export default Alert;
