import { Component } from 'react';

import { updateGAWithUserData } from '../../../utils/analytics';

class UserMetaGA extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.division !== this.props.division ||
      prevProps.department !== this.props.department ||
      prevProps.country !== this.props.country
    ) {
      const { division, department, userId, regionId, isManager, countryName } =
        this.props;

      if (division) {
        updateGAWithUserData(
          division,
          department,
          userId,
          regionId,
          isManager,
          countryName
        );
      }
    }
  }

  render() {
    return null;
  }
}

export default UserMetaGA;
