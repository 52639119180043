import './CommentLikeSummary.scss';

import { getLikesAndComments } from 'api/feedback';
import CommentButton from 'components/Button/CommentAndLike/CommentButton';
import LikeButton from 'components/Button/CommentAndLike/LikeButton';
import { HorizontalDividerList } from 'components/HorizontalDividerList/HorizontalDividerList';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GAonCommentFeedClick } from 'utils/analytics';
import { getUrlLink } from 'utils/misc/getUrlLink';

const ArticleTypes = [
  'NewsArticle',
  'BlogPost',
  'StoryArticle',
  'portalarticlesearchhits',
];

const LinkWrapper = ({ type, id, title, children }) => {
  return (
    <Link to={getUrlLink(type, id)} onClick={() => GAonCommentFeedClick(title)}>
      {children}
    </Link>
  );
};

const CommentLikeSummary = ({
  id,
  title,
  type,
  likesAndComments,
  commentsEnabled,
  loggedInUserId,
  article,
}) => {
  const [articleLikesAndComments, setArticleLikesAndComments] = useState();

  const formattedArticleId = article
    ? article.id.replace('cms-', '')
    : id.replace('cms-', '');

  const fetchLikesAndComments = useCallback(async () => {
    if (likesAndComments) {
      setArticleLikesAndComments(likesAndComments);
    }

    if (!article) {
      return;
    }

    const likesAndCommentsNew = await getLikesAndComments([formattedArticleId]);

    const response = extractLikesAndCommentsInfo(
      likesAndCommentsNew.data,
      loggedInUserId,
      article
    );

    setArticleLikesAndComments(response);
  }, [article, formattedArticleId, likesAndComments, loggedInUserId]);

  useEffect(() => {
    fetchLikesAndComments();
  }, [fetchLikesAndComments]);

  if (!articleLikesAndComments || !ArticleTypes.includes(type)) {
    return null;
  }

  return (
    <div className="comment-like-summary">
      <HorizontalDividerList>
        <LikeButton
          articleTitle={title}
          targetEntityId={formattedArticleId}
          likesCount={articleLikesAndComments.likesCount}
          likedByMe={articleLikesAndComments.isLikedByMe}
          clickInFeed={true}
        />
        <LinkWrapper type={type} id={formattedArticleId} title={title}>
          <CommentButton
            commentCount={articleLikesAndComments.commentsCount}
            commentsEnabled={commentsEnabled}
          />
        </LinkWrapper>
      </HorizontalDividerList>
    </div>
  );
};

const extractLikesAndCommentsInfo = (response, loggedInUserId, newsArticle) => {
  const { included } = response;
  if (!included)
    return {
      isLikedByMe: false,
      isCommentedByMe: false,
      likesCount: 0,
      commentsCount: 0,
    };

  // Get likes count based on articleId
  const likes = included.filter(
    (likesAndComments) =>
      `cms-${likesAndComments.attributes.targetEntityId}` === newsArticle.id &&
      likesAndComments.type === 'likes'
  );
  const likesCount = likes ? likes.length : 0;
  const isLikedByMe = !!likes?.find(
    (like) => like.attributes.personId === loggedInUserId
  );

  // Get comments count based on articleId
  const comments = included.filter(
    (likesAndComments) =>
      `cms-${likesAndComments.attributes.targetEntityId}` === newsArticle.id &&
      likesAndComments.type === 'comments'
  );

  let replies = [];
  if (comments.length > 0) {
    const commentIds = comments.map((comment) => comment.id);

    replies = included.filter(
      (likesAndComments) =>
        commentIds.includes(likesAndComments.attributes.targetEntityId) &&
        likesAndComments.type === 'comments'
    );
  }

  const publishedComments = comments.filter(
    (comment) => comment.attributes?.published
  );
  const publishedReplies = replies.filter(
    (reply) => reply.attributes?.published
  );

  const publishedCommentsAndRepliesCount =
    publishedComments.length + publishedReplies.length;

  const isCommentedByMe = !!publishedComments?.find(
    (comment) => comment.attributes.personId === loggedInUserId
  );

  return {
    isLikedByMe,
    isCommentedByMe,
    likesCount,
    commentsCount: publishedCommentsAndRepliesCount,
  };
};

export default CommentLikeSummary;
