import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as feedbackApi from 'api/feedback';
import { Tooltip } from 'componentsNew';
import { ReactComponent as EyeIcon } from 'componentsNew/Icon/Eye.svg';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Due to faulty GA page views during Q3 2023 on news, blogs and
// stories, we don't display page views for these articles
export const isArticlePublishDateBeforeFaultyGADataDate = (created: string) => {
  const faultyGADataDate = new Date('2023-08-01');
  const articlePublishedDate = new Date(created);
  return articlePublishedDate > faultyGADataDate;
};

type PageViewsProps = {
  id: string;
  value?: number;
  tooltip: string;
  sx?: SxProps<Theme>;
};

const PageViews = ({ id, value, tooltip, sx }: PageViewsProps) => {
  const [pageViews, setPageViews] = useState<string | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (Number.isInteger(value)) {
      return;
    }
    (async () => {
      try {
        const response = await feedbackApi.getPageViews(pathname);
        const result = response?.data?.data?.pageViews as string;
        if (result) {
          setPageViews(result);
        }
      } catch {}
    })();
  }, [value, pathname]);

  if (!value && !pageViews) {
    return null;
  }

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xxxs'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Tooltip title={tooltip}>
        <EyeIcon />
      </Tooltip>
      <Typography
        id={`${id}-count`}
        variant="caption"
        sx={(theme) => ({
          color: theme.colors.text.tertiary,
          cursor: 'default',
        })}
      >
        {value || pageViews}
      </Typography>
    </Stack>
  );
};

export { PageViews };
