import BlogAuthor from './BlogAuthor';
import { createObject } from './convertResponse';
import SearchHit from './SearchHit';
import Suggestion from './Suggestion';

const typeMap = {
  blogpostsuggestions: Suggestion,
  newsarticlesuggestions: Suggestion,
  positionsuggestions: Suggestion,
  storyarticlesuggestions: Suggestion,
  personsuggestions: Suggestion,
  articlesuggestions: Suggestion,
  glossarysuggestions: Suggestion,
  documentsuggestions: Suggestion,
  pressreleasesuggestions: Suggestion,
  portalarticlesuggestions: Suggestion,
  toolsuggestions: Suggestion,
  blogpostsearchhits: SearchHit,
  newsarticlesearchhits: SearchHit,
  storyarticlesearchhits: SearchHit,
  positionsearchhits: SearchHit,
  personsearchhits: SearchHit,
  articlesearchhits: SearchHit,
  glossarysearchhits: SearchHit,
  documentsearchhits: SearchHit,
  pressreleasesearchhits: SearchHit,
  portalarticlesearchhits: SearchHit,
  toolsearchhits: SearchHit,
  'aadw:blog-authors': BlogAuthor,
};

export const modelFactory = (response) => {
  const { data, included, meta, links } = response.data;

  const dataIsArray = Array.isArray(data);

  const dataObjects = dataIsArray
    ? data.map((item) => createObject(typeMap[item.type], item, included))
    : createObject(typeMap[data.type], data, included);

  return {
    data: dataObjects,
    meta,
    links,
  };
};
