import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as videoApi from 'api/video';
import PlayButton from 'components/PlayButton';
import { useCallback } from 'react';
import { GAonVideoClick } from 'utils/analytics';

import { ModalState } from './VideoList';

interface VideoItemProps {
  id: string;
  title?: string;
  thumbUrl: string;
  setModalState: (modalState: ModalState) => void;
}

const VideoItem = ({
  id,
  title = '',
  thumbUrl,
  setModalState,
}: VideoItemProps) => {
  const onFetchVideoUrl = useCallback(async () => {
    const response = await videoApi.getAuthenticatedUrlFromApi(id);
    const iframeUrl = response?.data?.data?.attributes?.url;
    if (iframeUrl) {
      setModalState({ isVisible: true, iframeUrl: iframeUrl });
      GAonVideoClick(title);
    }
  }, [id, setModalState, title]);

  return (
    <ListItemButton
      sx={(theme) => ({
        padding: theme.spacing('sm'),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        '&:hover': { background: theme.colors.surface.informative },
        '&:focus': {
          outline: `1px solid ${theme.colors.border.focus}`,
          boxShadow: `0px 0px 0px 3px ${theme.colors.border.surfaceInformative}`,
          backgroundColor: 'unset',
        },
      })}
      onClick={onFetchVideoUrl}
    >
      <Typography
        sx={() => ({
          overflowWrap: 'anywhere',
          wordBreak: 'break-word',
        })}
        variant="body1"
      >
        {title}
      </Typography>
      <Stack
        sx={() => ({
          position: 'relative',
          width: { xs: '7rem', sm: '9rem' },
          height: '5rem',
          alignItems: 'center',
          flexDirection: 'row',
          flexShrink: 0,
        })}
      >
        <Box
          sx={() => ({
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          })}
          component="img"
          src={thumbUrl}
          title={title}
          alt={title}
        />
        <Box
          sx={() => ({
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(63, 63, 63, 0.49)',
            transition: '0.2s',
            '&:hover': { background: 'transparent' },
          })}
        >
          <PlayButton />
        </Box>
      </Stack>
    </ListItemButton>
  );
};

export { VideoItem };
