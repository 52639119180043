import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';

import { Consumer } from '..';
import DeleteButton from './DeleteButton';
import PublishButton from './PublishButton';

const yesButton = {
  color: 'black',
  title: 'Yes',
};

const noButton = {
  color: 'white',
  title: 'No',
};

const SaveTextMediaBox = ({
  onCancel,
  subject,
  hideDelete,
  deleteModalChildren,
}) => (
  <Consumer>
    {({
      updatedArticle: { publishedInDivision, publishedInCountries },
      isCreating,
      onDeleteTextMedia,
      onSaveTextMedia,
      onAfterCancel,
    }) => (
      <>
        <div className="article-button-wrapper m-t-4">
          <PublishButton
            countries={publishedInCountries}
            division={publishedInDivision}
            subject={subject}
            onSaveArticle={onSaveTextMedia}
            isCreating={isCreating}
          />
          <ButtonModalWrapper
            title="Are you sure you want to leave this page before saving?"
            modalChildren={null}
            yesButton={yesButton}
            noButton={noButton}
          >
            <Button
              onClick={onAfterCancel}
              className="sm-m-b-2"
              color="transparent"
            >
              Cancel
            </Button>
          </ButtonModalWrapper>
          {hideDelete !== true && (
            <DeleteButton
              onDeleteArticle={onDeleteTextMedia}
              modalChildren={deleteModalChildren}
              onCancel={onCancel}
              isCreating={isCreating}
              subject="block"
            />
          )}
        </div>
      </>
    )}
  </Consumer>
);

export default SaveTextMediaBox;
