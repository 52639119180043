import './PublishSelector.scss';

import * as API from 'api/organization';
import Reset from 'components/Button';
import Error from 'components/Error';
import Select from 'components/Select';
import SiteSelector from 'components/SiteSelector';
import { ENTRANCE_SYSTEMS_DIVISION_ID } from 'constants/consents';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { cleanSegmentLabelName } from 'utils/misc/cleanSegmentLabelName';

import CompanySelector from './components/CompanySelector';

class PublishSelector extends Component {
  constructor(props) {
    super();

    this.state = {
      divisions: [],
      departments: [],
      segments: [],
      sites: [],
      companies: [],
      error: false,
      errorCode: null,
      selected: props.defaultValue,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getDivisions();
    this.getDepartments();
    this.getSegments();
  }

  componentDidUnMount() {
    this._isMounted = false;
  }

  getDivisions = async () => {
    try {
      const { data } = await API.getDivisions();
      if (this._isMounted) {
        const formattedData = this.formatDivisionData(data);
        this.setState({ divisions: formattedData });
      }
    } catch (e) {
      return this.setState({
        error: true,
        errorMessage: e.message,
      });
    }
  };

  getDepartments = async () => {
    if (this._isMounted) {
      try {
        const { data } = await API.getDepartments();
        const formattedData = this.formatDepartmentData(data);
        this.setState({ departments: formattedData });
      } catch (e) {
        return this.setState({
          error: true,
          errorMessage: e.message,
        });
      }
    }
  };

  getSegments = async () => {
    if (this._isMounted) {
      try {
        const { data } = await API.getSegments();
        const formattedData = this.formatSegmentData(data);
        this.setState({ segments: formattedData });
      } catch (e) {
        return this.setState({
          error: true,
          errorMessage: e.message,
        });
      }
    }
  };

  formatDivisionData = (res) => {
    const mapChildCountries = (node) => {
      const country = res.included.find((country) => country.id === node.id);
      return {
        value: country.id,
        name: country.attributes.name,
      };
    };

    const sortNodes = (a, b) =>
      a.name > b.name ? 1 : a.name < b.name ? -1 : 0;

    const root = res.data.find(
      (n) => n.relationships.parentDivision.data === null
    );

    const formattedData = res.data
      .filter((node) => {
        const parent = node.relationships.parentDivision.data;
        if (parent) {
          return parent.id === root.id;
        } else {
          return false;
        }
      })
      .map((node) => ({
        value: node.id,
        name: node.attributes.name,
        children: node.relationships.childCountries.data
          .map(mapChildCountries)
          .sort(sortNodes),
      }))
      .sort(sortNodes);

    const { defaultDivisionName } = this.props;

    return [
      { value: root.id, name: defaultDivisionName, children: [] },
      ...formattedData,
    ];
  };

  formatDepartmentData = (response) => {
    let result = [];
    if (response.data && response.data.length > 0) {
      result = response.data.map((node) => ({
        value: node.id,
        name: node.attributes.name,
        children: [],
      }));
    }
    return result;
  };

  formatSegmentData = (response) => {
    let result = [];
    if (response.data && response.data.length > 0) {
      result = response.data.map((node) => ({
        value: node.id,
        name: cleanSegmentLabelName(node.attributes.name),
        children: [],
      }));
    }
    return result;
  };

  getDivisionCountries = () => {
    const allDivisions = this.state.divisions;
    const { divisions } = this.state.selected;

    const findGlobal = divisions.find(
      (division) => division.value === this.props.defaultDivisionName
    );

    if (findGlobal) {
      return [];
    }

    if (allDivisions && allDivisions.length) {
      const countriesForSelectedDivisions = allDivisions
        .filter((divisionInList) =>
          divisions.some((division) => division.value === divisionInList.value)
        )
        .map((division) => division.children);

      if (allDivisions.length < 2) {
        return countriesForSelectedDivisions;
      }

      const listLength = countriesForSelectedDivisions.length;

      const flatListOfSelectedCountries = countriesForSelectedDivisions.flat();

      const countCountries = {};

      flatListOfSelectedCountries.forEach((country) => {
        countCountries[`${country.name};${country.value}`] =
          (countCountries[`${country.name};${country.value}`] || 0) + 1;
      });

      const countries = [];

      for (let key in countCountries) {
        if (countCountries[key] === listLength) {
          countries.push({
            name: key.split(';')[0],
            value: key.split(';')[1],
          });
        }
      }
      return countries;
    }

    return [];
  };

  onChangeDivision = (field) => (divisions) => {
    if (this._isMounted) {
      const { selected } = this.state;
      selected[field] = divisions;
      selected.countries = [];
      if (!this.canSelectSegments) selected.segments = [];

      this.setState({ selected });
      this.props.onChange(selected);
    }
  };

  createOnChangeHandler = (field) => (values) => {
    const { selected } = this.state;
    selected[field] = values;
    if (this._isMounted) {
      this.setState({ selected });
    }
    this.props.onChange(selected);
  };

  reset = () => {
    this.setState({
      selected: {
        divisions: [],
        segments: [],
        countries: [],
        departments: [],
        sites: [],
        companies: [],
      },
    });
    this.props.onChange({
      divisions: null,
      segments: null,
      countries: null,
      departments: null,
      sites: null,
      companies: null,
    });
  };

  get canSelectSegments() {
    const { divisions } = this.state.selected;
    if (divisions.length > 1) {
      return false;
    }
    return divisions?.some(
      (division) => division.value === ENTRANCE_SYSTEMS_DIVISION_ID
    );
  }

  renderSegmentsSelector() {
    const { showInitialError } = this.state;
    const { selected, segments } = this.state;
    const showError = showInitialError && selected.segments.length === 0;
    return this.canSelectSegments ? (
      <div className="division-country-segment-selector__select">
        <Select
          elementId="publish--segment--selector"
          data={segments}
          selected={selected.segments}
          multiple={true}
          deSelectEnabled={true}
          placeholder="Select segment"
          onChange={this.createOnChangeHandler('segments')}
          showError={showError}
        />
      </div>
    ) : (
      <></>
    );
  }

  render() {
    const {
      defaultDivisionName,
      showInitialError,
      publishWithCountry,
      publishWithDepartment,
      publishWithSegments,
      publishWithSite,
      publishWithCompany,
    } = this.props;
    const { divisions, departments, selected, error, errorMessage } =
      this.state;

    if (error) {
      return <Error message={errorMessage} />;
    }

    const countries = this.getDivisionCountries();

    const noDivisionSelected = selected.divisions.length === 0;
    const divisionSelected =
      selected.divisions.length > 0 &&
      !selected.divisions.some((x) => x.name === defaultDivisionName);
    const noSegmentSelected = selected.segments.length === 0;
    const noDepartmentSelected = selected.departments.length === 0;
    const noSiteSelected = selected.sites.length === 0;
    const noCompanySelected = selected.companies.length === 0;
    const globalSelected = selected.divisions.some(
      (x) => x.name === defaultDivisionName
    );

    return (
      <div className="ddivision-country-segment-selector">
        <div className="division-country-segment-selector__inner">
          <div className="division-country-segment-selector__selectors">
            <div className="division-country-segment-selector__select">
              <Select
                elementId="publish--division--selector"
                data={divisions}
                selected={selected.divisions}
                multiple={true}
                placeholder="Select Global or divisions"
                onChange={this.onChangeDivision('divisions')}
                showError={showInitialError && noDivisionSelected}
                disableGlobal={true}
                globalSelected={globalSelected}
                divisionSelected={divisionSelected}
                deSelectEnabled={true}
              />
            </div>
            {publishWithSegments && this.renderSegmentsSelector()}
            {publishWithCountry && (
              <div className="division-country-segment-selector__select">
                <Select
                  elementId="publish--country--selector"
                  data={countries}
                  selected={selected.countries}
                  multiple={true}
                  deSelectEnabled={true}
                  placeholder="Select country"
                  disabled={globalSelected || noDivisionSelected}
                  onChange={this.createOnChangeHandler('countries')}
                />
              </div>
            )}
            {/* As there is no second line, show the reset button in the first line itself */}
            {!(
              publishWithDepartment ||
              publishWithSite ||
              publishWithCompany
            ) && (
              <Reset
                onClick={this.reset}
                disabled={noDivisionSelected}
                size="small"
                width="narrow"
                color="white"
              >
                Reset
              </Reset>
            )}
          </div>
          {(publishWithDepartment || publishWithSite) && (
            <div className="division-country-segment-selector__selectors m-t-4">
              {publishWithDepartment && (
                <div className="division-country-segment-selector__select">
                  <Select
                    elementId="publish--departments--selector"
                    data={departments}
                    selected={selected.departments}
                    placeholder="Select Function (optional)"
                    onChange={this.createOnChangeHandler('departments')}
                    multiple={true}
                    deSelectEnabled={true}
                  />
                </div>
              )}
              {publishWithSite && (
                <div className="division-country-segment-selector__select">
                  <SiteSelector
                    identifier="publish--site--selector"
                    selectedSites={selected.sites}
                    onChange={this.createOnChangeHandler('sites')}
                    multiple={true}
                  />
                </div>
              )}
            </div>
          )}
          {publishWithCompany && (
            <div className="division-country-segment-selector__selectors m-t-4">
              <div className="division-country-segment-selector__select">
                <CompanySelector
                  identifier="publish--company--selector"
                  selectedCompanies={selected.companies}
                  onChange={this.createOnChangeHandler('companies')}
                  multiple={true}
                />
              </div>
              <div className="division-country-segment-selector__select">
                <Reset
                  onClick={this.reset}
                  disabled={
                    noDivisionSelected &&
                    noDepartmentSelected &&
                    noSiteSelected &&
                    ((this.canSelectSegments && noSegmentSelected) ||
                      !this.canSelectSegment) &&
                    noCompanySelected
                  }
                  size="small"
                  width="narrow"
                  color="white"
                >
                  Reset
                </Reset>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

PublishSelector.propTypes = {
  defaultDivisionName: PropTypes.string.isRequired,
  showInitialError: PropTypes.bool,
  publishWithCountry: PropTypes.bool,
  publishWithDepartment: PropTypes.bool,
  publishWithSite: PropTypes.bool,
  publishWithCompany: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({
    divisions: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    sites: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
  }),
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

PublishSelector.defaultProps = {
  publishWithCountry: true,
  publishWithDepartment: true,
  publishWithSite: true,
  publishWithCompany: true,
};

export default PublishSelector;
