import { CmsContentType } from 'api/cms/types';
import { differenceInCalendarDays } from 'date-fns';
import { translations } from 'translations';

import {
  ManageFeedTableField,
  ManageFeedTableItem,
  SortOrder,
} from './ManageFeedTable';

const MONTHS_BEFORE_UNPUBLISH_NEWS_BLOGS_STORIES = 24;

const articleContentTypeTranslation: Record<string, string> = {
  [CmsContentType.News]: translations.articleContentTypeNews,
  [CmsContentType.Blog]: translations.articleContentTypeBlog,
  [CmsContentType.Story]: translations.articleContentTypeStory,
};

const getArticleTypeTranslation = (type: CmsContentType) => {
  return articleContentTypeTranslation[type];
};

const getFormattedDate = (dateStr: string, includeTime?: boolean) => {
  const date = new Date(dateStr);
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    return translations.notAvailable;
  }

  return includeTime
    ? date.toLocaleString(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
      })
    : date.toLocaleDateString();
};

const isExpiryDateSoon = (publishedDateStr: string) => {
  const date = new Date(publishedDateStr);
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    return translations.notAvailable;
  }
  date.setMonth(date.getMonth() + MONTHS_BEFORE_UNPUBLISH_NEWS_BLOGS_STORIES);

  const now = new Date();
  const diff = differenceInCalendarDays(date, now);
  return diff < 30;
};

const sortTableItems = (
  items: ManageFeedTableItem[],
  sortField: ManageFeedTableField,
  sortOrder: SortOrder
) => {
  switch (sortField) {
    case ManageFeedTableField.Title:
      return items.sort((a, b) => {
        const _a = a.title || '';
        const _b = b.title || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageFeedTableField.PublishedDate:
      return items.sort((a, b) => {
        const _a = new Date(a.publishedDate || '');
        const _b = new Date(b.publishedDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageFeedTableField.ModifiedDate:
      return items.sort((a, b) => {
        const _a = new Date(a.modifiedDate || '');
        const _b = new Date(b.modifiedDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageFeedTableField.InformationOwner:
      return items.sort((a, b) => {
        const _a = a.informationOwner?.name || '';
        const _b = b.informationOwner?.name || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    default:
      return items;
  }
};

const getArticleRequestQueryParams = (
  sort: { field: ManageFeedTableField; order: SortOrder },
  pagination: { page: number; rowsPerPage: number }
) => {
  let sortParam = `sort=`;

  if (sort.order === SortOrder.Desc) {
    sortParam = `${sortParam}-`;
  }
  switch (sort.field) {
    case ManageFeedTableField.Title:
      sortParam = `${sortParam}title`;
      break;
    case ManageFeedTableField.PublishedDate:
      sortParam = `${sortParam}publishedDate`;
      break;
    case ManageFeedTableField.ModifiedDate:
      sortParam = `${sortParam}modifiedDate`;
      break;
    case ManageFeedTableField.InformationOwner:
      sortParam = `${sortParam}informationOwner`;
      break;
  }
  const limitParam = `page[limit]=${pagination.rowsPerPage}`;
  const offsetParam = `page[offset]=${pagination.page - 1}`;
  return `?${sortParam}&${limitParam}&${offsetParam}`;
};

export {
  getArticleRequestQueryParams,
  getArticleTypeTranslation,
  getFormattedDate,
  isExpiryDateSoon,
  sortTableItems,
};
