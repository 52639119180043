import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as cmsContentManagementApi from 'api/cms/contentManagement';
import { GetContentManagementBannersResponse } from 'api/cms/contentManagement/types';
import { CmsContentType } from 'api/cms/types';
import { useUser } from 'components/Context/User';
import { AlertBar, TablePagination } from 'componentsNew';
import { AlertBarType } from 'componentsNew/AlertBar';
import { AvenueRoute } from 'constants/routes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import { RequestStatus } from 'types';

import * as helpers from './helpers';
import {
  ManageBannersTable,
  ManageBannersTableField,
  ManageBannersTableItem,
  SortOrder,
} from './ManageBannersTable';

const elementId = 'profile-manage-banners';

const INITIAL_SORT = {
  field: ManageBannersTableField.PublishedDate,
  order: SortOrder.Desc,
};

const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 10,
};

const ManageBanners = () => {
  const [sort, setSort] = useState<{
    field: ManageBannersTableField;
    order: SortOrder;
  }>(INITIAL_SORT);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [tableContent, setTableContent] = useState<{
    items: ManageBannersTableItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [fetchStatus, setFetchStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );

  const user = useUser();
  const mountedRef = useRef<boolean>(true);

  const fetchItems = useCallback(
    async (
      sort: { field: ManageBannersTableField; order: SortOrder },
      pagination: { page: number; rowsPerPage: number }
    ) => {
      setFetchStatus(RequestStatus.Loading);
      let items: ManageBannersTableItem[] = [];
      let total = 0;

      try {
        const queryParams = helpers.getRequestQueryParams(sort, pagination);
        const response = (await cmsContentManagementApi.getBanners(
          queryParams
        )) as GetContentManagementBannersResponse;

        total = response.data.data.totalRows;
        items = response.data.data.banners.map((banner) => {
          const item: ManageBannersTableItem = {
            id: banner.id,
            title: banner.title,
            type: banner.type,
            publishedDate: banner.publishedDate,
            informationOwner: banner.informationOwner,
            divisions:
              (banner.publishedInDivisions || [])
                .map((division) => division.name)
                .join(', ') || translations.global,
            linkPath:
              banner.type === CmsContentType.NewsletterBanner
                ? `${AvenueRoute.NewsletterBannerEdit}/${banner.id}`
                : banner.type === CmsContentType.HeroBanner
                ? `${AvenueRoute.HeroBanner}/${banner.id}/edit`
                : '',
          };
          return item;
        });
      } catch {
        if (!mountedRef.current) {
          return { items, total };
        }
        setFetchStatus(RequestStatus.Failure);
        return { items, total };
      }

      setFetchStatus(RequestStatus.Idle);
      return { items, total };
    },
    []
  );

  const handleSortChange = useCallback(
    async (newSort: { field: ManageBannersTableField; order: SortOrder }) => {
      setSort(newSort);

      if (tableContent.items.length === tableContent.total) {
        const newTableContent = {
          ...tableContent,
          items: helpers.sortTableItems(
            tableContent.items,
            newSort.field,
            newSort.order
          ),
        };
        setTableContent(newTableContent);
        return;
      }
      const newPagination = { ...pagination, page: 1 };
      const newTableContent = await fetchItems(newSort, newPagination);
      setTableContent(newTableContent);
      setPagination(newPagination);
    },
    [tableContent, pagination, fetchItems]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      if (
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.rowsPerPage < pagination.rowsPerPage
      ) {
        setTableContent({
          ...tableContent,
          items: tableContent.items.slice(0, newPagination.rowsPerPage),
        });
        return;
      }
      const newTableContent = await fetchItems(sort, newPagination);
      setTableContent(newTableContent);
    },
    [fetchItems, tableContent, pagination, sort]
  );

  const isLoading = useMemo(
    () => user.firstLoad || fetchStatus === RequestStatus.Loading,
    [fetchStatus, user.firstLoad]
  );

  useEffect(() => {
    async function fetchInitialItems() {
      const newTableContent = await fetchItems(
        INITIAL_SORT,
        INITIAL_PAGINATION
      );
      setTableContent(newTableContent);
    }
    fetchInitialItems();
  }, [fetchItems]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
        })}
      >
        {user.roles && user.roles.includes('AvenueSuperuser') && (
          <Button
            variant="linkButton"
            component={ReactRouterLink}
            to={AvenueRoute.HeroBannerCreate}
          >
            {translations.manageContentCreateHeroBanner}
          </Button>
        )}
        {user.roles && user.roles.includes('AvenueAdmin') && (
          <Button
            variant="linkButton"
            component={ReactRouterLink}
            to={AvenueRoute.NewsletterBannerCreate}
          >
            {translations.manageContentCreateNewsletterBanner}
          </Button>
        )}
      </Stack>
      <ManageBannersTable
        isLoading={isLoading}
        items={tableContent.items}
        sort={sort}
        onSortChange={handleSortChange}
        sx={(theme) => ({
          borderTopLeftRadius: theme.border.radius.md,
          borderTopRightRadius: theme.border.radius.md,
        })}
      />
      {tableContent.total > INITIAL_PAGINATION.rowsPerPage && (
        <TablePagination
          disabled={isLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={
            tableContent.total % pagination.rowsPerPage > 0
              ? Math.trunc(tableContent.total / pagination.rowsPerPage) + 1
              : tableContent.total / pagination.rowsPerPage
          }
          onPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: pagination.rowsPerPage,
              page: value,
            })
          }
          onRowsPerPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: value,
              page: 1,
            })
          }
        />
      )}
      <AlertBar
        snackbar
        open={fetchStatus === RequestStatus.Failure}
        type={AlertBarType.Critical}
        text={translations.manageContentFetchArticlesError}
        onClose={() => {
          if (fetchStatus !== RequestStatus.Failure) return;
          setFetchStatus(RequestStatus.Idle);
        }}
      />
    </Stack>
  );
};

export { ManageBanners };
