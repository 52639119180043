import './Tree.scss';

import { getTree } from 'api/cms/library';
import TreeItem from 'components/Navigation/TreeItem';
import PropTypes from 'prop-types';
import { Component } from 'react';

const TreeItemLocal = ({
  url,
  treePath,
  loading,
  emptyResponse,
  title,
  container,
  ...others
}) => {
  const isActive = treePath === url && !loading;
  const inActivePath =
    isActive || (treePath && treePath.indexOf(`${url}/`) === 0 && !loading);

  let label = title;

  if (loading || emptyResponse) {
    label = emptyResponse ? 'No articles created yet...' : 'Loading...';
  }

  let globalItem = false;
  if (treePath !== undefined && treePath.startsWith('/group-library')) {
    globalItem = true;
  }

  return (
    <TreeItem
      isActive={isActive}
      disabled={loading}
      inActivePath={inActivePath}
      hasChildren={container}
      to={globalItem ? `${url}` : `/library${url}`}
      openKey={url}
      {...others}
    >
      {label}
    </TreeItem>
  );
};

// the tree keeps track of all nodes in a key value store
// { [url]: node } to make it easy to access any node.
// - all nodes get populated with their parent and child nodes
// - when rendering we start at the root node and recursively visit
//   all the child nodes and render them
// - the root node is a special case and has no title and is never rendered
// - the key value store (nodesByUrl) is always the same object, as are the
//   underlying nodes

// TODO: Move the nodesByUrl and handling of it to the context instead
const getDefaultState = (rootPath) => {
  const rootNode = {
    url: rootPath,
    isOpen: true,
    loading: true,
    children: [],
  };

  const nodesByUrl = { [rootPath]: rootNode };

  return {
    rootNode,
    nodesByUrl,
  };
};

export class Tree extends Component {
  constructor(props) {
    super(props);

    this.state = getDefaultState(props.rootPath);
  }

  componentDidMount() {
    this._isMounted = true;

    this.refreshNodes();
  }

  componentDidUpdate(prevProps) {
    const { rootPath, treePath } = this.props;

    if (prevProps.rootPath !== rootPath || prevProps.treePath !== treePath) {
      this.refreshNodes();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadNodes = async (path) => {
    const response = await getTree(path);
    const data = response.data.data;

    if (this._isMounted && data) {
      const { nodesByUrl } = this.state;

      if (data && data.length === 0) {
        const parent = nodesByUrl[path];
        // Guard required in case the node is a draft article.
        if (parent) {
          parent.loading = false;
        }
      }

      data.forEach((nodeData) => {
        // Ensure that each non-root node has a parent node.
        const parent = nodesByUrl[path];
        let node = nodesByUrl[nodeData.url];
        if (node) {
          Object.assign(node, {
            ...nodeData,
            isOpen: false,
            loading: false,
          });
        } else {
          node = {
            ...nodeData,
            children: [],
            isOpen: false,
            loading: false,
            parent,
          };
        }
        if (parent) {
          if (!parent.children.find((child) => node.url === child.url)) {
            parent.children.push(node);
          }
          parent.isOpen = true;
          parent.loading = false;
        }
        nodesByUrl[node.url] = node;
      });

      this.setState({ nodesByUrl });
    }
  };

  refreshNodes = () => {
    let { rootPath } = this.props;

    this.setState(getDefaultState(rootPath), this.loadAllPaths);
  };

  loadAllPaths = async () => {
    let { rootPath, treePath } = this.props;

    const pathsToLoad = [rootPath];

    if (rootPath === '/') {
      rootPath = '';
    }

    // remove the navigation path from the tree
    treePath = treePath.substr(rootPath.length + 1);

    // create series of paths we need to load to get to
    // current position in the tree
    if (treePath.length > 0) {
      let partialPath = '';
      treePath.split('/').forEach((subPath) => {
        partialPath += `/${subPath}`;

        pathsToLoad.push(rootPath + partialPath);
      });
    }
    try {
      for (const path of pathsToLoad) {
        await this.loadNodes(path);
      }

      this.updateBreadcrumb();
      this.updateSiblings();
    } catch (error) {
      if (this._isMounted) {
        return this.setState({
          error: true,
        });
      }
    }
  };

  updateBreadcrumb() {
    const { treePath, updateBreadcrumb } = this.props;
    const { nodesByUrl } = this.state;

    const currentNode = nodesByUrl[treePath];
    if (currentNode && currentNode.title) {
      let node = currentNode;
      const breadcrumbs = [];

      do {
        breadcrumbs.unshift({
          title: node.title,
          link: node.url,
        });

        node = node.parent;
      } while (node && node.title);

      updateBreadcrumb(treePath, breadcrumbs);
    }
  }

  updateSiblings() {
    const { treePath, updateSiblings } = this.props;
    const { nodesByUrl } = this.state;

    const currentNode = nodesByUrl[treePath];
    if (currentNode) {
      // siblings help us make sure we don't create multiple articles
      // at the same level with the same title.
      // - For editing an article its important that the articles on the same
      //   level have different names
      // - when creating new its important that the children have different names
      // - these values are used by the Edit component.
      let siblingTitles = [];

      // TODO: instead of lower case we should map the names the same way the back-end does
      // to ensure there are no conflicts
      if (currentNode.parent) {
        currentNode.parent.children.forEach((child) => {
          if (child !== currentNode) {
            siblingTitles.push(child.title.toLowerCase());
          }
        });
      }

      updateSiblings({
        childTitles: currentNode.children.map((child) =>
          child.title.toLowerCase()
        ),
        siblingTitles,
      });
    }
  }

  setOpen = async (url, isOpen) => {
    const { nodesByUrl } = this.state;

    const currentNode = nodesByUrl[url];

    currentNode.isOpen = isOpen;

    // Trigger loading-state for menu item.
    if (isOpen) {
      currentNode.loading = true;
    }

    this.setState({ nodesByUrl });

    // Load children for menu item.
    if (isOpen) {
      await this.loadNodes(url);
      this.updateSiblings();
    }
  };

  renderChildNodes(nodes, depth) {
    const { treePath } = this.props;
    const out = [];

    nodes.forEach((node) => {
      out.push(
        <TreeItemLocal
          {...node}
          loading={false}
          setOpen={this.setOpen}
          treePath={treePath}
          depth={depth}
          key={node.url}
        />
      );

      if (node.isOpen) {
        // if the node is loading this means its children are being loaded. Show
        // parent as loading=false, and insert a loading tree item below in depth + 1
        if (node.loading) {
          out.push(
            <ul
              className="tree-item tree-item--children"
              key={`${node.url}-child`}
            >
              <TreeItemLocal loading={true} depth={depth + 1} />
            </ul>
          );
        } else {
          out.push(
            <ul
              className="tree__list tree__list--children"
              key={`${node.url}-child`}
            >
              {this.renderChildNodes(node.children, depth + 1)}
            </ul>
          );
        }
      }
    });

    return out;
  }

  renderRootNode(rootNode) {
    if (rootNode.loading) {
      return <TreeItemLocal loading={true} depth={1} />;
    }

    if (rootNode.children.length === 0) {
      return <TreeItemLocal emptyResponse={true} depth={1} />;
    }

    return this.renderChildNodes(rootNode.children, 1);
  }

  render() {
    const { rootNode, error } = this.state;

    if (error) {
      return null;
    }

    return (
      <div className="tree">
        <ul className="tree__list">{this.renderRootNode(rootNode)}</ul>
      </div>
    );
  }
}

Tree.propTypes = {
  rootPath: PropTypes.string,
  treePath: PropTypes.string.isRequired,
  updateBreadcrumb: PropTypes.func.isRequired,
  updateSiblings: PropTypes.func.isRequired,
};

export default Tree;
