import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as InformationCircleFilledIcon } from 'componentsNew/Icon/InformationCircleFilled.svg';
import { translations } from 'translations';

const elementId = 'ai-chat-disclaimer';

const AIChatDisclaimer = () => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        '> svg': {
          flexShrink: 0,
          '> path': { fill: theme.colors.surface.disabled },
        },
      })}
    >
      <InformationCircleFilledIcon height={20} width={20} />
      <Typography
        variant="caption"
        sx={(theme) => ({
          color: theme.colors.text.secondary,
          marginLeft: theme.spacing('xxs'),
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {translations.aiChatDisclaimer}
      </Typography>
    </Stack>
  );
};

export { AIChatDisclaimer };
