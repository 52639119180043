import './ArticleListLink.scss';

import PlayButton from 'components/PlayButton';
import fallBackImageBlog from 'componentsNew/ArticleList/article-fallback-image-blog.jpg';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import TextClamp from 'react-string-clamp';
import { getImageSrc } from 'utils/cms';
import { isVideoImageUrl } from 'utils/cms/parseVideoUrls';

const blogPostTypeQId = 'aadw:blog-post';
const newsItemTypeQId = 'aadw:news-item';
const portalListingTypeQId = 'aadw:portal-listing';
const portalArticleTypeQId = 'aadw:portal-article';

class ArticleListLink extends Component {
  getHeroImage(imageWidth, isVideoThumbnail) {
    const { imageUrl } = this.props.to;
    if (imageUrl) {
      const useImageWidth = isVideoThumbnail ? undefined : imageWidth;
      return getImageSrc(imageUrl, useImageWidth);
    }
    return fallBackImageBlog;
  }

  getPath() {
    const { id, portalType, type } = this.props.to;
    switch (type) {
      case newsItemTypeQId:
        return `/feed/news/${id}`;
      case blogPostTypeQId:
        return `/feed/blog/${id}`;
      case portalListingTypeQId:
      case portalArticleTypeQId:
        return `/portals/${portalType}/${id}`;
      case 'NewsArticle':
        return `/feed/news/${id.substring(4)}`;
      case 'BlogPost':
        return `/feed/blog/${id.substring(4)}`;
      case 'StoryArticle':
        return `/feed/story/${id.substring(4)}`;
      default:
    }
  }

  render() {
    const {
      className,
      to: { title, preamble, imageUrl, imageAltText = '', description },
    } = this.props;

    const isVideoThumbnail = imageUrl && isVideoImageUrl(imageUrl);
    const imgSrc = this.getHeroImage(300, isVideoThumbnail);

    return (
      <li className={`article-list-link ${className || ''}`}>
        <Link to={this.getPath()}>
          <div className="article-list-link__image-container">
            <img src={imgSrc} alt={imageAltText} title={imageAltText} />
            {imageUrl && isVideoThumbnail && <PlayButton size="md" />}
          </div>
          <div className="article-list-link__text-container">
            <h4 className="article-list-link__heading">
              <TextClamp
                className="article-list-link__title"
                text={title}
                element="div"
              />
            </h4>
            <TextClamp
              className="article-list-link__preamble article-list-link__preamble--regular-line-clamp"
              text={preamble || description}
              lines={3}
              element="p"
            />
          </div>
        </Link>
      </li>
    );
  }
}

ArticleListLink.propTypes = {
  to: PropTypes.object.isRequired,
};

export default ArticleListLink;
