import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { CmsContentType } from 'api/cms/types';
import { Badge, TableCellWithSort } from 'componentsNew';
import notFoundImage from 'images/not-found.jpg';
import { useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

import * as helpers from './helpers';
import { ManageBannersTableSkeleton } from './ManageBannersTableSkeleton';

const elementId = 'profile-manage-banners-table';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ManageBannersTableField {
  Title = 'title',
  Type = 'Type',
  PublishedDate = 'publishedDate',
  InformationOwner = 'informationOwner',
  Divisions = 'divisions',
}

export type ManageBannersTableItem = {
  id: string;
  title: string;
  type: CmsContentType;
  publishedDate?: string;
  divisions: string;
  informationOwner?: {
    name: string;
    oid: string;
  };
  linkPath: string;
};

type ManageBannersTableProps = {
  sx?: SxProps<Theme>;
  isLoading: boolean;
  items: ManageBannersTableItem[];
  sort: {
    field: ManageBannersTableField;
    order: SortOrder;
  };
  onSortChange: (sort: {
    field: ManageBannersTableField;
    order: SortOrder;
  }) => void;
};

const ManageBannersTable = ({
  sx,
  isLoading,
  items,
  sort,
  onSortChange,
}: ManageBannersTableProps) => {
  const handleSortClick = useCallback(
    (field: ManageBannersTableField) => {
      onSortChange({
        field: field,
        order:
          sort?.field === field && sort.order === SortOrder.Asc
            ? SortOrder.Desc
            : SortOrder.Asc,
      });
    },
    [onSortChange, sort]
  );

  if (isLoading) {
    return <ManageBannersTableSkeleton />;
  }

  if (!items.length) {
    return (
      <Stack alignItems="center" padding="1rem">
        <Box component="img" src={notFoundImage} maxHeight="300px" />
        <Typography marginTop={1}>{translations.manageContentEmpty}</Typography>
      </Stack>
    );
  }

  return (
    <TableContainer sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Table id={elementId} aria-label={translations.profileManageBanners}>
        <TableHead>
          <TableRow>
            <TableCellWithSort
              width="30%"
              sorted={sort?.field === ManageBannersTableField.Title}
              onSort={() => handleSortClick(ManageBannersTableField.Title)}
            >
              {translations.manageContentTitle}
            </TableCellWithSort>
            <TableCellWithSort
              sorted={sort?.field === ManageBannersTableField.Type}
              onSort={() => handleSortClick(ManageBannersTableField.Type)}
            >
              {translations.manageContentType}
            </TableCellWithSort>
            <TableCellWithSort
              sorted={sort?.field === ManageBannersTableField.PublishedDate}
              onSort={() =>
                handleSortClick(ManageBannersTableField.PublishedDate)
              }
            >
              {translations.manageContentPublishedDate}
            </TableCellWithSort>
            <TableCell>{translations.manageContentDivisions}</TableCell>
            <TableCellWithSort
              sorted={sort?.field === ManageBannersTableField.InformationOwner}
              onSort={() =>
                handleSortClick(ManageBannersTableField.InformationOwner)
              }
            >
              {translations.manageContentInformationOwner}
            </TableCellWithSort>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`${item.id}-${index}`}
              id={`${elementId}-row-${index}`}
            >
              <TableCell>
                <Link
                  variant="body2"
                  fontWeight="bold"
                  to={item.linkPath}
                  component={ReactRouterLink}
                  sx={{ textDecoration: 'none' }}
                >
                  {item.title}
                </Link>
              </TableCell>
              <TableCell>{helpers.getTypeTranslation(item.type)}</TableCell>
              <TableCell>
                {item.publishedDate ? (
                  <Badge
                    size="small"
                    color="default"
                    variant="outlined"
                    label={helpers.getFormattedDate(item.publishedDate)}
                  />
                ) : (
                  translations.notAvailable
                )}
              </TableCell>
              <TableCell sx={{ maxWidth: '10rem' }}>{item.divisions}</TableCell>
              <TableCell>
                {item.informationOwner ? (
                  <Link
                    to={`/people/${item.informationOwner.oid}`}
                    component={ReactRouterLink}
                    sx={{ textDecoration: 'none' }}
                  >
                    {item.informationOwner.name}
                  </Link>
                ) : (
                  translations.notAvailable
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ManageBannersTable };
