import Box from 'components/Box';
import { CMSConsumer } from 'components/Context/Library';
import withConsumer from 'components/Context/withConsumer';
import Flex from 'components/Flex';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GAonLibraryCreatePage } from 'utils/analytics';
import appendToUrl from 'utils/misc/appendToUrl';

import Tree from './Tree';

const getCurrentFolderType = (navigationPath, userContext) => {
  const { departments } = userContext;
  const currentItem = departments.find((dept) =>
    navigationPath === '/'
      ? dept.path === '/group-library'
      : dept.path === navigationPath
  );
  return currentItem ? currentItem.folderType : null;
};

const NavigationContainer = ({
  isEditOrCreate,
  url,

  // from consumer
  depth,
  treeNavigationKey,
  loading,
  libraryPath,
  navigationPath,
  updateBreadcrumb,
  updateSiblings,
  userContext,
  division,
  country,
}) => {
  if (!navigationPath) return null;

  const folderType = getCurrentFolderType(navigationPath, userContext);

  /*
  The Add Page link appears only
  1. After the data loading is complete
  2. If not in Create / Edit Mode
  3. If the depth is less than 5
  4. For the AvenueSuperUser Role from Depth 0 onwards for Global, Division, Region and Country
  5. For the Global Editor Role 
     from Depth 0 onwards for Region and Country
     and Depth 1 onwards in Global and Division
  */
  const showAddPage =
    !isEditOrCreate &&
    loading === false &&
    (folderType === 'global' ? depth < 6 : depth < 5) &&
    (((folderType === 'region' || folderType === 'country') &&
      (userContext.isSuperUser || userContext.isGlobalEditor)) ||
      (folderType === 'division' &&
        (userContext.isSuperUser ||
          (userContext.isGlobalEditor && depth > 0))) ||
      (folderType === 'global' &&
        (userContext.isSuperUser ||
          (userContext.isGlobalEditor &&
            depth > 0 &&
            url !== '/group-library'))));

  return (
    <>
      <Box className="m-b-4 sm-m-b-0" padding={false}>
        <Tree
          rootPath={navigationPath === '/' ? '/group-library' : navigationPath}
          treePath={libraryPath}
          updateBreadcrumb={updateBreadcrumb}
          updateSiblings={updateSiblings}
          key={`libraryNavigation${treeNavigationKey}`}
        />
      </Box>
      {showAddPage && (
        <Flex type="flex-end" className="m-b-6 sm-bg-blue">
          <Link
            to={appendToUrl(url, 'create')}
            className="color-blue sm-color-white sm-p--2"
            onClick={GAonLibraryCreatePage(division, country)}
          >
            Add page
          </Link>
        </Flex>
      )}
    </>
  );
};

NavigationContainer.propTypes = {
  isEditOrCreate: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  depth: PropTypes.number,
  navigationPath: PropTypes.string,
  libraryPath: PropTypes.string,
  treeNavigationKey: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  updateBreadcrumb: PropTypes.func.isRequired,
  updateSiblings: PropTypes.func.isRequired,
  userContext: PropTypes.object.isRequired,
  division: PropTypes.object,
  country: PropTypes.object,
};

export default withConsumer(CMSConsumer, NavigationContainer);
