import Box from '@mui/material/Box';
import * as API from 'api/cms/ProductOfTheWeek';
import CreateOrEdit from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import Category from 'components/CreateOrEditArticle/components/Category';
import { ContactSearchSelector } from 'components/CreateOrEditArticle/components/ContactSearchSelector';
import DateRange from 'components/CreateOrEditArticle/components/DateRange';
import DivisionName from 'components/CreateOrEditArticle/components/DivisionName';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import Market from 'components/CreateOrEditArticle/components/Market';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import ReleaseYear from 'components/CreateOrEditArticle/components/ReleaseYear';
import Title from 'components/CreateOrEditArticle/components/Title';
import { maxLengthValidator } from 'components/CreateOrEditArticle/validators';
import { Page } from 'layoutNew';
import { RouteComponentProps } from 'react-router-dom';
import { translations } from 'translations';

import { CreateOrEditProductOfTheWeekSkeleton } from './CreateOrEditProductOfTheWeekSkeleton';

interface MatchParams {
  id: string;
  type: string;
}

type CreateOrEditProductOfTheWeekProps = RouteComponentProps<MatchParams>;

const CreateOrEditProductOfTheWeek = ({
  match,
  history,
  location,
}: CreateOrEditProductOfTheWeekProps) => {
  const isCreating = match.params.type === 'create';
  const productOfTheWeekArticleId = match.params.id;

  const fields = [
    {
      key: 'title',
      required: true,
      displayName: 'Title',
      maxLength: 90,
    },
    {
      key: 'heroImage',
      type: 'productoftheweek',
    },
    {
      key: 'preamble',
      fieldValidator: maxLengthValidator(300),
      maxLength: 300,
    },
    { key: 'body' },
    { key: 'market' },
    { key: 'division' },
    { key: 'releaseYear' },
    { key: 'category' },
    { key: 'startDate', displayName: 'Start date', required: true },
    { key: 'endDate', displayName: 'End date', required: true },
    { key: 'contactPersons' },
  ];

  const placeholders = {
    title: translations.articleTitlePlaceholder,
    preamble: translations.articlePreamblePlaceholder,
    body: translations.articleBodyPlaceholder,
    market: translations.market,
    divisonName: translations.articleDivisionPlaceholder,
    releaseYear: translations.articleReleaseYearPlaceholder,
    category: translations.articleCategoryPlaceholder,
    contact: translations.articleContactPlaceholder,
    startDate: translations.articleStartDatePlaceholder,
    endDate: translations.articleEndDatePlaceholder,
  };

  return (
    <Page title={translations.productOfTheWeek}>
      <CreateOrEdit
        isCreating={isCreating}
        viewableByRole="AvenueAdmin"
        pageTitle={translations.productOfTheWeek}
        location={location}
        fields={fields}
        ContentLoader={CreateOrEditProductOfTheWeekSkeleton}
        onFetch={() =>
          productOfTheWeekArticleId &&
          !isCreating &&
          API.getProductOfTheWeekWithId(productOfTheWeekArticleId)
        }
        onCreate={API.createProductOfTheWeek}
        onEdit={API.editProductOfTheWeek}
        onDelete={API.deleteProductOfTheWeek}
        onAfterCreate={() => {
          history.push(`/`);
        }}
        onAfterDelete={() => {
          history.push('/');
        }}
        onAfterCancel={() => {
          history.push('/');
        }}
      >
        <Box>
          <Box
            sx={(theme) => ({
              padding: theme.spacing('sm'),
              boxShadow: '0 2px 8px rgba(9, 88, 112, 0.08)',
              height: '100%',
              background: theme.colors.surface.primary,
            })}
          >
            <Title placeholder={placeholders.title} />
            <Hero
              useVideo={false}
              useAltText={true}
              aspectRatio={1.1}
              minWidth={480}
              displayType="feed"
            />
            <Preamble placeholder={placeholders.preamble} />
            <Body placeholder={placeholders.body} showSideButtons={false} />
            <DateRange
              startDateLabel={placeholders.startDate}
              endDateLabel={placeholders.endDate}
              rangeLength={7}
            />
            <Market placeholder={placeholders.market} />
            <DivisionName placeholder={placeholders.divisonName} />
            <ReleaseYear placeholder={placeholders.releaseYear} />
            <Category placeholder={placeholders.category} />
            <ContactSearchSelector />
          </Box>
          <PublishOptions
            onCancel="/"
            subject="productoftheweek"
            hideDelete={false}
          />
        </Box>
      </CreateOrEdit>
    </Page>
  );
};

export { CreateOrEditProductOfTheWeek };
