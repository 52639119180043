import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { aiSearch } = settings.api;

export const sendPrompt = (data: {
  objectId: string;
  prompt: string;
  dateTime: string;
  countryId?: string;
  divisionId?: string;
  departmentId?: string;
  siteId?: string;
  companyId?: string;
  regionId?: string;
}) => {
  return createRequest({
    method: 'post',
    url: `${aiSearch}`,
    data,
    errorMessages: {
      '*': 'Failed to send prompt, please contact support for assistance.',
    },
  });
};
