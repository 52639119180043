import './Search.scss';

import { UserContext } from 'components/Context/User';
import SearchField from 'components/SearchField';
import { Component, createRef } from 'react';
import { GAonOverlayFilterClick } from 'utils/analytics';

import SuggestionFilter from './components/Filter';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      filter: null,
    };
    this.input = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fromSearchToSearcFieldHandler = () => this.props.onSubmit();

  setFilter(value) {
    GAonOverlayFilterClick(value);
    this.setState({ filter: value === 'all' ? null : value });
  }

  render() {
    const { showSuggestionFilters, resetQueryOnSubmit, toggleSearch } =
      this.props;
    return (
      <div className="search">
        {this.state.suggestions.length === 0 &&
        showSuggestionFilters === true ? (
          <SuggestionFilter
            onClick={(value) => {
              this.setFilter(value);
            }}
            selected={this.state.filter}
          />
        ) : (
          ''
        )}
        <SearchField
          history={this.props.history}
          filters={this.state.filter ? [this.state.filter] : []}
          onSubmitClicked={this.fromSearchToSearcFieldHandler}
          resetQueryOnSubmit={resetQueryOnSubmit}
          toggleSearch={toggleSearch}
        />
      </div>
    );
  }
}

Search.contextType = UserContext;

export default Search;
