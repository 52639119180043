import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AlertDialog, AlertDialogType } from 'componentsNew/AlertDialog';
import { useEffect, useState } from 'react';
import { translations } from 'translations';

import * as helpers from './helpers';
import { ImageList } from './ImageList';
import { ImagePicker } from './ImagePicker';

const MAX_FILE_SIZE_IN_BYTES = 2 * 1024 * 1024;
const VALID_MIME_TYPES = ['image/png', 'image/jpeg'];
const MAX_NBR_OF_IMAGES = 10;

const elementId = 'image-gallery';

export type UnsavedImage = {
  file: File;
  error?: string;
};

type SavedImage = {
  path: string;
  sortIndex: number;
  name: string;
};

export type ImageGalleryItem =
  | { isFile: true; image: UnsavedImage }
  | { isFile: false; image: SavedImage };

export type ImageGalleryProps = {
  onChange: (imageGalleryItems: ImageGalleryItem[]) => void;
  initialImages?: SavedImage[];
};

export const ImageGalleryEdit = ({
  initialImages,
  onChange,
}: ImageGalleryProps) => {
  const [imageGalleryItems, setImageGalleryItems] = useState<
    ImageGalleryItem[]
  >([]);

  const [maxCountConfirmationDialog, setMaxCountConfirmationDialog] = useState<{
    open: boolean;
    files: FileList | null;
  }>({ open: false, files: null });

  useEffect(() => {
    if (!initialImages) return;
    const initialImageGalleryItems: ImageGalleryItem[] = initialImages.map(
      (image) => {
        return { isFile: false, image };
      }
    );
    setImageGalleryItems(initialImageGalleryItems);
  }, [initialImages]);

  const onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || !files.length) {
      return;
    }
    if (imageGalleryItems.length + files.length > MAX_NBR_OF_IMAGES) {
      setMaxCountConfirmationDialog({ open: true, files });
      return;
    }
    addToGallery(files);
  };

  const addToGallery = (files: FileList) => {
    let newImageGalleryItems = helpers.validateFiles(
      files,
      MAX_FILE_SIZE_IN_BYTES,
      VALID_MIME_TYPES
    );
    if (
      imageGalleryItems.length + newImageGalleryItems.length >
      MAX_NBR_OF_IMAGES
    ) {
      const nbrOfImagesLeft = MAX_NBR_OF_IMAGES - imageGalleryItems.length;
      newImageGalleryItems = newImageGalleryItems.slice(0, nbrOfImagesLeft);
    }
    setImageGalleryItems([...imageGalleryItems, ...newImageGalleryItems]);
    if (onChange) onChange([...imageGalleryItems, ...newImageGalleryItems]);
  };

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        marginBottom: theme.spacing('sm'),
      })}
    >
      <Typography variant="body1" fontWeight="600">
        {translations.imageGalleryEditTitle}
      </Typography>
      <ImagePicker
        disabled={imageGalleryItems.length >= MAX_NBR_OF_IMAGES}
        onChange={(e) => onSelectFiles(e)}
      />
      {imageGalleryItems.length > 0 && (
        <ImageList
          imageGalleryItems={imageGalleryItems}
          onChange={(newImageGalleryItems) => {
            setImageGalleryItems(newImageGalleryItems);
            onChange(newImageGalleryItems);
          }}
        />
      )}
      <AlertDialog
        open={maxCountConfirmationDialog.open}
        type={AlertDialogType.Warning}
        title={translations.imageGalleryMaxCountTitle}
        paragraphs={[translations.imageGalleryMaxCountText]}
        primaryButton={{
          text: translations.upload,
          onClick: () => {
            maxCountConfirmationDialog.files &&
              addToGallery(maxCountConfirmationDialog.files);
            setMaxCountConfirmationDialog({ open: false, files: null });
          },
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: () => {
            setMaxCountConfirmationDialog({ open: false, files: null });
          },
        }}
        onClose={() => {
          setMaxCountConfirmationDialog({ open: false, files: null });
        }}
      />
    </Stack>
  );
};
