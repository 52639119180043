import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import * as powerbiApi from 'api/cms/powerbi';
import { PowerBIEmbedDetails } from 'api/cms/powerbi/types';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useRef, useState } from 'react';
import { translations } from 'translations';
import { RequestStatus } from 'types';

import * as embedHelpers from './helpers';

type EmbedPowerBIProps = {
  src: string;
};

const EmbedPowerBI = ({ src }: EmbedPowerBIProps) => {
  const [fetchStatus, setFetchStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );
  const [embedDetails, setEmbedDetails] = useState<PowerBIEmbedDetails | null>(
    null
  );

  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    async function fetchEmbedDetails() {
      try {
        setFetchStatus(RequestStatus.Loading);
        const reportId = embedHelpers.getReportIdFromPowerBIEmbedUrl(src);
        if (!reportId) {
          setFetchStatus(RequestStatus.Failure);
          return;
        }
        const response = await powerbiApi.getEmbedDetails({ reportId });
        const data = response?.data?.data;
        if (!mountedRef.current) {
          return;
        }
        if (!data) {
          setFetchStatus(RequestStatus.Failure);
          return;
        }
        setEmbedDetails(data);
        setFetchStatus(RequestStatus.Success);
      } catch {
        setFetchStatus(RequestStatus.Failure);
      }
    }
    fetchEmbedDetails();
  }, [src]);

  if (fetchStatus === RequestStatus.Loading) {
    return (
      <EmbedWrapper>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ height: '100%', width: '100%' }}
        />
      </EmbedWrapper>
    );
  }

  if (fetchStatus === RequestStatus.Failure) {
    // Failed to fetch embed details (access token etc) so auto login will not work,
    // user will have to click "Sign in" in the iframe in order to see the report
    return (
      <EmbedWrapper>
        <iframe
          title={translations.embedPowerBiIframeTitle}
          src={src}
          allowFullScreen
        />
      </EmbedWrapper>
    );
  }

  return (
    <EmbedWrapper>
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: embedDetails?.reportId,
          embedUrl: embedDetails?.embedUrl,
          accessToken: embedDetails?.embedToken,
          tokenType: models.TokenType.Embed,
          pageName: embedDetails?.reportName,
        }}
        eventHandlers={
          new Map([['error', () => setFetchStatus(RequestStatus.Failure)]])
        }
      />
    </EmbedWrapper>
  );
};

const EmbedWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        width: '100%',
        aspectRatio: 3 / 2,
        marginBottom: '1rem',
        overflow: 'hidden',
        position: 'relative',
        '> div': {
          width: '100%',
          height: '100%',
        },
        iframe: {
          width: '100%',
          height: '100%',
          border: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

export { EmbedPowerBI };
