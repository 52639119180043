import './Find.scss';

import { addRecentSearch, getSearchResults } from 'api/find';
import SearchHit from 'api/models/SearchHit';
import Box from 'components/Box';
import Button from 'components/Button';
import Center from 'components/Center';
import { UserConsumer } from 'components/Context/User';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import { ReactComponent as Arrow } from 'components/Icon/Arrow.svg';
import NewSortingControl from 'components/NewSortingControl';
import PageTitle from 'components/PageTitle';
import Resize from 'components/Resize';
import ScrollRestoration from 'components/ScrollRestoration';
import SearchField from 'components/SearchField';
import Title from 'components/Title';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GAonSearchFilterClick, GAonShowMoreClick } from 'utils/analytics';
import { loadData } from 'utils/api/handleAPIData';
import animateScroll from 'utils/misc/animateScroll';
import makeQueryString from 'utils/misc/makeQueryString';

import ArticleItem from './components/ArticleItem';
import DocumentItem from './components/DocumentItem';
import GlossaryItem from './components/GlossaryItem';
import JobItem from './components/JobItem';
import NoResult from './components/NoResults';
import PersonItem from './components/PersonItem';
import SearchCount from './components/SearchCount';
import SearchFilters from './components/SearchFilters';
import ToolsItem from './components/ToolsItem';
import ContentLoader from './ContentLoader';

const configureLoader = (limit) => {
  if (limit === 1) {
    return (
      <Box>
        <Resize>{({ width }) => <ContentLoader width={width} />}</Resize>
      </Box>
    );
  } else {
    return (
      <Box>
        <Resize>
          {({ width }) => [
            Array(limit)
              .fill()
              .map((_, i) => <ContentLoader key={i} width={width} />),
          ]}
        </Resize>
      </Box>
    );
  }
};

const onLoading = (limit) => (
  <>
    <Container className="find">
      <Column span={2}>
        <Title color="white">Searching...</Title>
      </Column>
      <Column></Column>
    </Container>
    <Container className="find">
      <Column span={2} className="m-b-6">
        <div className="find__loader-search-field">{configureLoader(1)}</div>
        <div className="m-t-5">{configureLoader(limit)}</div>
      </Column>
    </Container>
  </>
);

const sortOptions = [
  {
    label: 'Relevance',
    sortType: '_score',
    sortOrder: 'desc',
  },
  {
    label: 'A-Z',
    sortType: 'sortingName',
    sortOrder: 'asc',
  },
  {
    label: 'Z-A',
    sortType: 'sortingName',
    sortOrder: 'desc',
  },
  {
    label: 'Newest',
    sortType: 'published',
    sortOrder: 'desc',
  },
  {
    label: 'Oldest',
    sortType: 'published',
    sortOrder: 'asc',
  },
];

class Find extends Component {
  state = {
    selectedSortOption: sortOptions[0],
    selectedFilters: [],
    searchString: '',
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.location.search) {
      this.readURLSearchParams();
    }
  }

  componentDidUpdate(prevProps) {
    this._isMounted = true;
    if (this.props.location.search !== prevProps.location.search) {
      this.readURLSearchParams();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  readURLSearchParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const searchQuery = searchParams.get('query') || '';
    const searchFilters = JSON.parse(searchParams.get('filters')) || [];

    this.sendRecentSearch(searchQuery);
    this.setState({
      ...this.state,
      searchString: searchQuery,
      selectedFilters: searchFilters,
    });
  };

  async sendRecentSearch(query) {
    if (!query) {
      return;
    }
    const apiCall = addRecentSearch;
    const queryString = makeQueryString({ queryPhrase: query });

    await loadData({
      apiCall,
      param: queryString,
      useModelFactory: true,
    }).promise;
  }

  handleSortChange = (selectedSortOption) => {
    if (this._isMounted) {
      this.setState({ selectedSortOption });
    }
  };

  onFilterClick = (toggleOptions) => {
    const { searchString, selectedFilters } = this.state;

    const clickedFilter = toggleOptions.value;
    GAonSearchFilterClick(clickedFilter);

    let newSelectedFilters = [];

    if (clickedFilter !== 'all') {
      newSelectedFilters = selectedFilters.includes(clickedFilter)
        ? selectedFilters.filter((type) => type !== clickedFilter)
        : [...selectedFilters, clickedFilter];
    }

    const searchParams = new URLSearchParams();

    if (searchString) {
      searchParams.set('query', searchString);
    }
    if (newSelectedFilters.length) {
      searchParams.set('filters', JSON.stringify(newSelectedFilters));
    }
    this.props.history.push(`/find?${searchParams.toString()}`);
  };

  toggleFilter = () => {
    if (this._isMounted) {
      this.setState({ isFilterOpened: !this.state.isFilterOpened });
    }
  };

  render() {
    const { history, useLoadMore = true, limit = 10 } = this.props;

    const {
      selectedSortOption,
      isFilterOpened,
      selectedFilters,
      searchString,
    } = this.state;

    let sort;
    if (selectedSortOption && selectedSortOption.sortType) {
      sort = selectedSortOption.sortOrder === 'desc' ? '-' : '';
      sort += selectedSortOption.sortType;
    }

    if (!searchString) {
      return (
        <>
          <Container className="find">
            <Column span={2}>
              <Title color="white">Search</Title>
            </Column>
            <Column></Column>
          </Container>
          <Container className="find">
            <Column span={2} className="m-b-6">
              <SearchField history={history} />
            </Column>
          </Container>
        </>
      );
    }

    return (
      <>
        <PageTitle titles={['Find']} />

        <UserConsumer>
          {({
            divisionId,
            countryId,
            regionId,
            departmentId,
            companyId,
            siteId,
            isLoading,
            findPagination,
            setFindPagination,
          }) =>
            isLoading ? (
              onLoading(limit)
            ) : (
              <Fetcher
                source={getSearchResults}
                useModelFactory={true}
                param={makeQueryString({
                  filter: {
                    query: searchString,
                    division: divisionId,
                    country: countryId,
                    region: regionId,
                    department: departmentId,
                    company: companyId,
                    site: siteId,
                    filterTypes: selectedFilters
                      .map((filter) =>
                        SearchHit.transformFilterToSearchType(filter)
                      )
                      .filter(Boolean),
                    portalTypes: selectedFilters
                      .map((filter) =>
                        SearchHit.transformFilterToPortalType(filter)
                      )
                      .filter(Boolean),
                  },
                  sort: sort,
                  page: {
                    limit,
                  },
                })}
                useLoadMore={true}
                pagination={findPagination}
                setPagination={setFindPagination}
                onLoading={() => onLoading(limit)}
              >
                {({
                  isLoading,
                  isLoadingMore,
                  loadMoreCount,
                  response,
                  meta,
                  links,
                  loadMore,
                }) =>
                  response.length ? (
                    <>
                      <Container className="find">
                        <Column span={2}>
                          <Title color="white">Search</Title>
                        </Column>
                        <Column></Column>
                      </Container>
                      <Container className="find">
                        <Column span={2} className="m-b-6">
                          <SearchField
                            history={history}
                            initialValue={searchString}
                          />

                          {meta && (
                            <div className="find__mobile-filters m-b-4">
                              <Box>
                                <div
                                  className="find__filter-toggle"
                                  onClick={this.toggleFilter}
                                >
                                  <h3>Filter</h3>
                                  <Arrow />
                                </div>
                                {isFilterOpened && (
                                  <div className="find__filter-body">
                                    <SearchFilters
                                      searchResponseMeta={meta}
                                      activeFilters={selectedFilters}
                                      onFilterClick={this.onFilterClick}
                                    />
                                  </div>
                                )}
                              </Box>
                            </div>
                          )}
                          {meta && (
                            <div className="find__filters">
                              <Box
                                className="box--no-padding"
                                padding={false}
                                color="transparent"
                              >
                                <SearchFilters
                                  searchResponseMeta={meta}
                                  activeFilters={selectedFilters}
                                  onFilterClick={this.onFilterClick}
                                />
                              </Box>
                            </div>
                          )}
                          <div className="find__box">
                            <div className="find__search-control">
                              <SearchCount
                                total={meta.total}
                                searchString={searchString}
                              />
                              <NewSortingControl
                                sortOptions={sortOptions}
                                selectedSortOption={selectedSortOption}
                                handleSortChange={this.handleSortChange}
                              />
                            </div>

                            {/* Glossary Item */}
                            <div className="find__glossary-item">
                              {response
                                .filter((searchHit) =>
                                  searchHit.isGlossaryHit()
                                )
                                .map((searchHit) => (
                                  <div
                                    className="find__glossary"
                                    key={searchHit.id}
                                  >
                                    <Box className="box--not-shadow">
                                      <GlossaryItem searchHit={searchHit} />
                                    </Box>
                                  </div>
                                ))}
                            </div>
                            <div className="find__article">
                              {response
                                .filter(
                                  (searchHit) => !searchHit.isGlossaryHit()
                                )
                                .map((searchHit) => (
                                  <Box
                                    className="box--not-shadow"
                                    key={searchHit.id}
                                  >
                                    {/* Jobs  */}
                                    {searchHit.isJobHit() && (
                                      <JobItem searchHit={searchHit} />
                                    )}
                                    {/* Documents  */}
                                    {searchHit.isDocumentHit() && (
                                      <DocumentItem searchHit={searchHit} />
                                    )}
                                    {/* Tools  */}
                                    {searchHit.isToolHit() && (
                                      <ToolsItem searchHit={searchHit} />
                                    )}
                                    {/* Persons  */}
                                    {searchHit.isPersonHit() && (
                                      <PersonItem searchHit={searchHit} />
                                    )}
                                    {/* News, Blogs, Libary Articles, Portal Articles  */}
                                    {!searchHit.isJobHit() &&
                                      !searchHit.isDocumentHit() &&
                                      !searchHit.isToolHit() &&
                                      !searchHit.isPersonHit() && (
                                        <ArticleItem searchHit={searchHit} />
                                      )}
                                  </Box>
                                ))}
                              {links && !links.next ? (
                                <div className="find__feedback">
                                  <Box>
                                    <h2 className="find__feedback-title">
                                      Can't find what you're searching for?!
                                    </h2>
                                    <p className="find__feedback-text">
                                      Let us know so we can improve your search
                                      experience.
                                    </p>
                                    <div className="find__feedback-button-wrapper">
                                      <Button
                                        outbound={true}
                                        to="https://forms.office.com/Pages/ResponsePage.aspx?id=ycG98EhRhk-sQO3ZduGBTPyMUsgW0WBMjdyPw1IVrlBUNUFHTUM4NU9QUEJWUUdSTUlJTUJFQkxLVi4u"
                                        size="medium"
                                        color="white"
                                        width="narrow"
                                        className="button--search"
                                      >
                                        Feedback
                                      </Button>
                                    </div>
                                  </Box>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {isLoadingMore ? (
                              <Resize>
                                {({ width }) =>
                                  [...Array(loadMoreCount)].map((_, i) => (
                                    <Box key={`find-box-${i}`}>
                                      <ContentLoader width={width} />
                                    </Box>
                                  ))
                                }
                              </Resize>
                            ) : (
                              <ScrollRestoration storageKey="find" />
                            )}
                          </div>
                          {useLoadMore && links && links.next && (
                            <Center className="find__bottom m-t-4 m-b-4">
                              <Button
                                className="find__button"
                                onClick={() => {
                                  animateScroll(220, 50);
                                  GAonShowMoreClick('Find');
                                  loadMore();
                                }}
                                disabled={isLoading}
                                color="black"
                              >
                                Load more
                              </Button>
                            </Center>
                          )}
                        </Column>
                      </Container>
                    </>
                  ) : (
                    <>
                      <Container className="find">
                        <Column span={2} className="m-b-6">
                          <Title color="white">
                            {`No search results for ${searchString}`}
                          </Title>
                        </Column>
                        <Column></Column>
                      </Container>
                      <Container className="find">
                        <Column span={2} className="m-b-6">
                          <SearchField
                            history={history}
                            initialValue={searchString}
                          />
                          <Box>
                            <NoResult />
                          </Box>
                        </Column>
                      </Container>
                    </>
                  )
                }
              </Fetcher>
            )
          }
        </UserConsumer>
      </>
    );
  }
}

export default withRouter(Find);
