import SearchHit from 'api/models/SearchHit';
import Toggle from 'components/Toggle';

const SearchFilters = ({
  searchResponseMeta,
  activeFilters,
  onFilterClick,
}) => {
  const all = {
    value: 'all',
    total: searchResponseMeta.total,
    label: 'All',
    active: activeFilters.length === 0,
  };

  const filterOptions = searchResponseMeta.totalInSections
    .map((totalInSection) => {
      const value =
        totalInSection.section === 'innovation' ||
        totalInSection.section === 'strategy' ||
        totalInSection.section === 'codeofconduct'
          ? `${totalInSection.section}portal`
          : totalInSection.section;

      const label = SearchHit.getFilterLabel(value);

      if (!label) {
        return null;
      }

      const option = {
        value: value,
        total: totalInSection.total,
        label: label,
        active: activeFilters.includes(value),
      };
      return option;
    })
    .filter(Boolean);

  return (
    <>
      {[all, ...filterOptions].map((filterOption, i) => (
        <Toggle
          color="blue"
          selected={filterOption.active}
          onClick={onFilterClick}
          value={filterOption.value}
          key={i}
        >
          {`${filterOption.label} (${filterOption.total})`}
        </Toggle>
      ))}
    </>
  );
};
export default SearchFilters;
