import { Snackbar, SnackbarProps } from 'componentsNew/Snackbar';
import React, { useCallback, useContext, useMemo, useState } from 'react';

type ShowSnackbarProps = Pick<
  SnackbarProps,
  'text' | 'type' | 'action' | 'autoHideDuration'
>;

const SnackbarContext = React.createContext<{
  showSnackbar: (props: ShowSnackbarProps) => void;
} | null>(null);

type SnackbarProviderProps = {
  children: React.ReactNode;
};

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    key: 0,
    open: false,
    text: '',
  });

  const showSnackbar = useCallback((props: ShowSnackbarProps) => {
    setSnackbar({ ...props, open: true, key: props.text });
  }, []);

  const contextValue = useMemo(
    () => ({
      showSnackbar,
    }),
    [showSnackbar]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      <Snackbar
        id={`${snackbar.type || 'default'}-snackbar`}
        autoHideDuration={4500}
        onClose={(_e, reason) => {
          if (reason === 'clickaway') return;
          setSnackbar({ ...snackbar, open: false });
        }}
        {...snackbar}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('SnackbarContext is not defined');
  }
  return context;
};

const SnackbarConsumer = SnackbarContext.Consumer;

export { SnackbarConsumer, SnackbarProvider, useSnackbar };
