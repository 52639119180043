import { FeedConsumer } from 'components/Context/Feed';
import List from 'components/Feed/List';
import PropTypes from 'prop-types';

const ListWrapper = ({
  limit,
  offset,
  useLoadMore,
  showNoResult,
  forNextArticle,
  previousFilter,
  isGlobalFeed,
  isGlobalForMyFeed,
  hideLastDivider,
}) => {
  return (
    <FeedConsumer>
      {({ filter, isLoading, pagination, setPagination }) => (
        <List
          limit={limit}
          offset={offset}
          useLoadMore={useLoadMore}
          showNoResult={showNoResult}
          forNextArticle={forNextArticle}
          isLoading={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          currentFilter={filter}
          isGlobalFeed={isGlobalFeed}
          isGlobalForMyFeed={isGlobalForMyFeed}
          hideLastDivider={hideLastDivider}
          {...(forNextArticle ? previousFilter : filter)}
        />
      )}
    </FeedConsumer>
  );
};

export default ListWrapper;

ListWrapper.propTypes = {
  limit: PropTypes.number,
  offset: PropTypes.number,
  useLoadMore: PropTypes.bool,
  showNoResult: PropTypes.bool,
  forNextArticle: PropTypes.bool,
  previousFilter: PropTypes.object,
  isGlobalFeed: PropTypes.bool,
  isGlobalForMyFeed: PropTypes.bool,
  hideLastDivider: PropTypes.bool,
};

ListWrapper.defaultProps = {
  limit: 20,
  offset: 0,
  useLoadMore: true,
  showNoResult: false,
  forNextArticle: false,
  hideLastDivider: false,
};
