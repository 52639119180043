import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

export const getShareArticleEmailLink = (args: {
  articleTitle: string;
  articleLink: string;
  senderName: string;
}) => {
  const subject = textUtils.replaceTranslationAliases(
    translations.shareEmailTemplateArticleSubject,
    { title: args.articleTitle }
  );

  const body = textUtils.replaceTranslationAliases(
    translations.shareEmailTemplateArticleBody,
    { link: args.articleLink, name: args.senderName }
  );

  return (
    `mailto:?subject=${encodeURIComponent(subject)}` +
    `&body=${encodeURIComponent(body)}`
  );
};
