import 'to-blob';
import './ImageCrop.scss';

import OutlinedInput from '@mui/material/OutlinedInput';
import Modal from 'components/Modal';
import { FormFieldWrapper } from 'componentsNew';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactCrop from 'react-image-crop';

export const dataURItoBlob = (dataURI) => {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

const getCroppedImg = (image, pixelCrop, maxSize) => {
  const canvas = document.createElement('canvas');
  let outW = pixelCrop.width;
  let outH = pixelCrop.height;

  // resize canvas if maximum size if selected area is larger
  // to not upload giant images to back-end
  if (outW > maxSize || outH > maxSize) {
    if (outW > outH) {
      outH = Math.round((maxSize * outH) / outW);
      outW = maxSize;
    } else {
      outW = Math.round((maxSize * outW) / outH);
      outH = maxSize;
    }
  }

  canvas.width = outW;
  canvas.height = outH;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    outW,
    outH
  );

  return new Promise((resolve) => {
    const img = canvas.toDataURL();
    const blob = dataURItoBlob(img);
    canvas.toBlob((file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve({
          file: reader.result,
          blob,
          width: outW,
          height: outH,
        });
      };
      reader.readAsDataURL(file);
    }, 'image/jpeg');
  });
};

class ImageCrop extends Component {
  state = {
    crop: {
      x: 0,
      y: 0,
      aspect: this.props.aspectRatio,
    },
    pixelCrop: {},
    loaded: false,
    cropping: false,
    heroAltText: null,
    heroAltTextError: false,
  };

  onImageLoaded = (image) => {
    const { minWidth, minHeight, aspectRatio } = this.props;

    const pctMinWidth = (minWidth / image.naturalWidth) * 100;
    const pctMinHeight = (minHeight / image.naturalHeight) * 100;

    let crop, pixelCrop;

    // if we have an aspect ratio set (hero image) we select the minimum
    // size area with the correct aspect ratio in the middle of the image
    if (aspectRatio) {
      const x = ((1 - minWidth / image.naturalWidth) * 100) / 2;
      const y = ((1 - minHeight / image.naturalHeight) * 100) / 2;

      crop = {
        x,
        y,
        width: pctMinWidth,
        aspect: aspectRatio,
      };

      // gives the actual pixel values which we have selected
      pixelCrop = {
        x: Math.round(image.naturalWidth * (crop.x / 100)),
        y: Math.round(image.naturalHeight * (crop.y / 100)),
        width: minWidth,
        height: minHeight,
      };
      // if no aspect ratio we select the entire image as default
    } else {
      crop = {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      };

      pixelCrop = {
        x: 0,
        y: 0,
        width: image.naturalWidth,
        height: image.naturalHeight,
      };
    }

    this.setState({
      crop,
      pixelCrop,
      minWidth: pctMinWidth,
      minHeight: pctMinHeight,
      image,
      loaded: true,
    });
  };

  onCropChange = (crop, pixelCrop) => {
    this.setState({ crop, pixelCrop });
  };

  onAltTextChanged = (value) => {
    this.setState({
      heroAltText: value,
      heroAltTextError: this.state.heroAltTextError
        ? !this.isAltTextValid(value)
        : this.state.heroAltTextError,
    });
  };

  isAltTextValid = (value) => {
    return !this.props.useAltText || (value || '').trim() !== '';
  };

  onSubmit = () => {
    if (!this.isAltTextValid(this.state.heroAltText)) {
      this.setState({ heroAltTextError: true });
      return;
    }
    // somehow createOutput is so heavy it blocks rendering
    // just doing it once set state is completed does not help, the timeout
    // thus ensures rendering is complete (button updated from crop to ...)
    // before the work begins.
    this.setState({ cropping: true }, () => setTimeout(this.createOutput, 100));
  };

  createOutput = async () => {
    const { onCrop, handleCroppedData, maxSize } = this.props;
    const { image, pixelCrop, heroAltText } = this.state;
    let output = await getCroppedImg(image, pixelCrop, maxSize);

    if (handleCroppedData) {
      output = await handleCroppedData(output);
    }

    if (heroAltText) {
      output.altText = heroAltText;
    }

    onCrop(output);
  };

  render() {
    const { src, onCancel, children, useAltText } = this.props;

    const {
      loaded,
      cropping,
      crop,
      minWidth,
      minHeight,
      heroAltText,
      heroAltTextError,
    } = this.state;

    if (!src) return null;

    let buttons = [];

    if (loaded) {
      buttons = [
        {
          title: 'Cancel',
          color: 'white',
          onClick: onCancel,
          disabled: cropping,
        },
        {
          title: cropping ? '...' : 'Save',
          color: 'black',
          disabled: cropping,
          onClick: this.onSubmit,
        },
      ];
    }

    return (
      <Modal
        onClose={onCancel}
        buttons={buttons}
        disableClickOutside={true}
        centerButtons={true}
        size="full"
      >
        <div className="crop__container">
          <ReactCrop
            crop={crop}
            minWidth={minWidth}
            minHeight={minHeight}
            src={src}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropChange}
            onChange={this.onCropChange}
          />
          {children}
        </div>
        {useAltText && (
          <FormFieldWrapper
            id="image-alt-text"
            label="Please describe the image for people who use screen readers to see images (max 125 characters)"
            error={
              this.state.heroAltTextError
                ? ['Please add a descriptive text to your image']
                : []
            }
            sx={() => ({
              maxWidth: '37.5rem',
              width: '100%',
              margin: '0.5rem auto 0',
            })}
          >
            <OutlinedInput
              fullWidth
              multiline
              size="small"
              inputProps={{ maxLength: 125 }}
              placeholder="Please describe the image for people who use screen readers to see images (max 125 characters)"
              value={heroAltText || ''}
              error={heroAltTextError}
              onChange={(e) => this.onAltTextChanged(e.currentTarget.value)}
            />
          </FormFieldWrapper>
        )}
      </Modal>
    );
  }
}

ImageCrop.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  src: PropTypes.string,
  minWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  maxSize: PropTypes.number,
  aspectRatio: PropTypes.number,
  handleCroppedData: PropTypes.func,
  children: PropTypes.node,
  useAltText: PropTypes.bool,
};

ImageCrop.defaultProps = {
  maxSize: 2100,
};

export default ImageCrop;
