import './Toggle.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

const Toggle = ({
  children,
  type,
  selected,
  className,
  disabled,
  value,
  onClick,
  color,
}) => {
  const toggleClassNames = classNames('toggle-button', {
    'toggle-button--selected': selected,
    'toggle-button--disabled': disabled,
    [`toggle-button__${color}`]: color,
    [className]: className,
  });

  return (
    <button
      className={toggleClassNames}
      disabled={disabled}
      value={value}
      onClick={() => {
        onClick({
          type,
          selected,
          value,
        });
      }}
    >
      {children}
    </button>
  );
};

Toggle.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['black', 'white', 'blue']),
};

Toggle.defaultProps = {
  selected: false,
  disabled: false,
  color: 'black',
};

export { Toggle };
export default memo(Toggle);
