import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { WhitelistValidationLink } from 'componentsNew';
import { ReactComponent as ChevronDownIcon } from 'componentsNew/Icon/ChevronDown.svg';
import { ReactComponent as LinkIcon } from 'componentsNew/Icon/Link.svg';
import { translations } from 'translations';
import { GAonAISourceClick, GAonAISourceListClick } from 'utils/analytics';

type AIChatSourceListProps = {
  id: string;
  sources: { title: string; linkUrl: string }[];
};

const AIChatSourceList = ({ id, sources }: AIChatSourceListProps) => {
  if (!sources.length) {
    return null;
  }
  return (
    <Accordion
      id={id}
      sx={(theme) => ({
        boxShadow: 'unset',
        backgroundColor: theme.colors.surface.informative,
        '&.Mui-expanded': { margin: 0 },
      })}
    >
      <AccordionSummary
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        expandIcon={<ChevronDownIcon />}
        onClick={GAonAISourceListClick}
        sx={(theme) => ({
          backgroundColor: theme.colors.surface.secondary,
          borderRadius: theme.border.radius.sm,
          padding: `${theme.spacing('xxxs')} ${theme.spacing('xxs')}`,
          typography: theme.typography.caption,
          fontWeight: 'bold',
          color: theme.colors.text.actionDefault,
          minHeight: 'unset',
          '&.Mui-expanded': {
            minHeight: 'unset',
            margin: 0,
            marginBottom: theme.spacing('xxxxs'),
          },
          '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded':
            {
              margin: 0,
            },
        })}
      >
        {translations.aiChatSourceList}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {sources.map((source, index) => (
          <WhitelistValidationLink
            key={`${index}-${source}`}
            href={source.linkUrl}
            target="_blank"
            onClick={() => GAonAISourceClick(source.title)}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              typography: theme.typography.caption,
              backgroundColor: theme.colors.surface.primary,
              marginBottom: theme.spacing('xxxxs'),
              padding: `${theme.spacing('xxxxs')} ${theme.spacing('xxxs')}`,
              '> svg': {
                flexShrink: 0,
                marginRight: theme.spacing('xxs'),
              },
              ':hover > svg > path': {
                stroke: theme.colors.text.actionHover,
              },
            })}
          >
            <LinkIcon />
            {source.title}
          </WhitelistValidationLink>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export { AIChatSourceList };
