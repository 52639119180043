import CMSProvider from 'components/Context/Library';
import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import Hamburger from 'components/Hamburger';
import Resize from 'components/Resize';
import { Mobile } from 'components/Responsive';
import Title from 'components/Title';
import { Component } from 'react';

import Edit from './Edit';
import TreeNavigation from './Navigation';
import TreePicker from './Navigation/TreePicker';
import View from './View';

class Library extends Component {
  state = {
    open: false,
  };

  componentDidUpdate(nextProps) {
    if (nextProps.match.url !== this.props.match.url) {
      this.setState({ open: false });
    }
  }

  toggle = () => this.setState(({ open }) => ({ open: !open }));

  render() {
    const {
      location: { pathname, search },
      match: { params, url },
    } = this.props;
    const { open } = this.state;

    const isEditOrCreate = /\/(edit|create)\/?$/.test(pathname);
    const id = params.route || url;

    const columnClassName = open ? 'column--no-gutter' : 'mobile-hidden';
    return (
      <div className="p-b-7">
        <Resize allowActualWidth={true}>
          {({ width }) => (
            <CMSProvider url={id} search={search} screenWidth={width}>
              <Mobile>
                <Container>
                  <Column>
                    <Flex type="space-between">
                      <Title
                        color="white"
                        className="m-t-2 m-l-0 m-b-3"
                        tag="h2"
                      >
                        Library
                      </Title>
                      <Hamburger
                        color="white"
                        onClick={this.toggle}
                        label="Toggle Navigation"
                        isOpen={open}
                      />
                    </Flex>
                  </Column>
                </Container>
              </Mobile>
              <Container
                {...(width > 1499 ? { width: 'xxxl' } : {})}
                className="m-t-5"
              >
                <Column className={`${columnClassName}`}>
                  <TreePicker url={url} />
                </Column>
              </Container>
              <Container {...(width > 1499 ? { width: 'xxxl' } : {})}>
                <Column className={`${columnClassName}`}>
                  <TreeNavigation url={url} isEditOrCreate={isEditOrCreate} />
                </Column>
                <Column span={2}>
                  {isEditOrCreate ? (
                    <Edit {...this.props} key={url} />
                  ) : (
                    <View id={id} url={url} key={url} />
                  )}
                </Column>
                {width > 1499 && (
                  <Column>
                    <div id="library-related-content-placeholder"></div>
                  </Column>
                )}
              </Container>
            </CMSProvider>
          )}
        </Resize>
      </div>
    );
  }
}

export default Library;
