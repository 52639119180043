import './MosaicLink.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextClamp from 'react-string-clamp';

const MosaicLink = ({ toPage, useCardStyle }) => {
  const responsiveImageSize = useCardStyle ? 300 : 350;
  const { title, preamble, heroAltText } = toPage.getAttributes();

  const liClassNames = classNames('mosaic-link', {
    'mosaic-link--mosaic-style': !useCardStyle,
    'mosaic-link--card-style': useCardStyle,
  });

  return (
    <li className={liClassNames}>
      <Link to={toPage.getPath()}>
        <img
          className="mosaic-link__image-container"
          src={toPage.getHeroImage(responsiveImageSize)}
          alt={heroAltText}
          title={heroAltText}
        />
        <div className="mosaic-link__text-container">
          <h4 className="mosaic-link__heading">
            <TextClamp className="mosaic-link__title" text={title} lines={1} />
          </h4>

          <TextClamp
            className="mosaic-link__preamble mosaic-link__preamble--reduced-line-clamp"
            text={preamble}
            lines={3}
            element="p"
          />
        </div>
      </Link>
    </li>
  );
};

MosaicLink.propTypes = {
  toPage: PropTypes.instanceOf(PortalNode).isRequired,
  useCardStyle: PropTypes.bool,
};

export default MosaicLink;
