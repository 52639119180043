import './Jobs.scss';

import { getPositions, headers } from 'api/jobs';
import { JobsConsumer } from 'components/Context/Jobs';
import withConsumer from 'components/Context/withConsumer';
import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';
import Title from 'components/Title';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  getDefaultState,
  loadData,
  loadMoreData,
} from 'utils/api/handleAPIData';
import makeQueryString from 'utils/misc/makeQueryString';

import JobsFilter from './components/JobsFilter';
import JobsList from './components/JobsList';
import RecommendedJobs from './components/RecommendedJobs';

const selectReducer = (meta, previousFilterOptions, filterKey) => {
  if (!meta[filterKey]) {
    // failed to get options due to server error, use previous values
    return previousFilterOptions[filterKey];
  }

  return meta[filterKey]
    .map(({ id, name, positionCount, childLocations, countryId }) => {
      let depth = 0;
      const usaIndex = name.indexOf(', USA');
      let niceName = name;

      // if its a us state, tab it in one step and remove , USA from the text
      if (usaIndex !== -1) {
        depth = 1;
        niceName = name.substr(0, usaIndex);
      }

      // <Select /> uses strings as values for matching checked
      return {
        name: niceName,
        positionCount,
        value: String(id),
        depth,
        childLocations,
        countryId: String(countryId),
      };
    })
    .sort(({ name: nameA, depth: depthA }, { name: nameB, depth: depthB }) => {
      // sort options alphabetically, the ones with depth === 1 are US states, so we
      // sort with USA in front
      const a = depthA === 0 ? nameA : `USA ${nameA}`;
      const b = depthB === 0 ? nameB : `USA ${nameB}`;

      return a.localeCompare(b);
    });
};

const selectOptionFetcher = async (
  { meta, queryObject, previousFilterOptions, updatedFilterKey },
  filterKey
) => {
  if (!updatedFilterKey) {
    return selectReducer(meta, previousFilterOptions, filterKey);
  }
  // the meta data returned by the query is filtered, however for the
  // select dropdown we do not wish to get the filtered results given the own
  // selection, therefore we must run the query again but without the own filter
  const queryObjectCopy = JSON.parse(JSON.stringify(queryObject));
  queryObjectCopy.filter[filterKey] = null;
  queryObjectCopy.page.limit = 1;

  const query = makeQueryString(queryObjectCopy);

  const { meta: unfilteredMeta } = await loadData({
    apiCall: getPositions,
    headers,
    param: query,
  }).promise;

  return selectReducer(unfilteredMeta, previousFilterOptions, filterKey);
};

const seniorityReducer = ({ meta, previousFilterOptions }) => {
  if (!meta.seniorityLevels) {
    // failed to get options due to server error, use previous values
    return previousFilterOptions.seniority;
  }

  return meta.seniorityLevels.map(({ name, id }) => {
    return { name, value: id };
  });
};

// Create filter will update the list of options but
// will retain the old options for the currently used filter to
// ensure that multiple options can be selected
const createFilterOptions = async (options) => {
  const [regions, locations, categories, states] = await Promise.all([
    selectOptionFetcher(options, 'regions'),
    selectOptionFetcher(options, 'locations'),
    selectOptionFetcher(options, 'categories'),
    selectOptionFetcher(options, 'states'),
  ]);

  return {
    regions,
    locations,
    categories,
    states,
    seniority: seniorityReducer(options),
  };
};

class Jobs extends Component {
  state = getDefaultState();

  async componentDidMount() {
    this._isMounted = true;
    const { pagination } = this.props;

    const isLoadingMore = pagination > 0;

    await this.loadData(isLoadingMore);

    if (isLoadingMore) {
      let newState = this.state;

      for (let i = 1; i <= pagination; ++i) {
        newState = await loadMoreData(headers, newState).promise;
      }

      this.asyncSetState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  loadData = async (isLoadingMore) => {
    const { query, queryObject, updatedFilterKey } = this.props;

    const { newState, promise } = loadData({
      apiCall: getPositions,
      headers,
      dataState: this.state,
      param: query,
    });

    this.setState(newState);

    if (!promise) {
      return;
    }

    const newDataState = await promise;

    this.asyncSetState({ ...newDataState, isLoadingMore });

    if (!newDataState.error) {
      const previousFilterOptions = this.state.filterOptions || {};

      const filterOptions = await createFilterOptions({
        meta: newDataState.meta,
        queryObject,
        previousFilterOptions,
        updatedFilterKey,
      });

      this.asyncSetState({ filterOptions });
    }
  };

  loadMoreData = () => {
    const { incrementPagination } = this.props;
    incrementPagination();

    const { newState, promise } = loadMoreData({
      headers,
      dataState: this.state,
    });
    this.setState(newState);

    if (promise) {
      promise.then((newDataState) => {
        this.asyncSetState(newDataState);
      });
    }
  };

  render() {
    const {
      response,
      isLoadingMore,
      loadMoreCount,
      error,
      errorStatus,
      errorMessage,
      isLoading,
      filterOptions,
      links,
    } = this.state;

    const {
      filterValues,
      disableReset,
      limit,
      updateSelectFilters,
      updateSeniority,
      resetFilters,
    } = this.props;

    return (
      <>
        <PageTitle titles={['Jobs']} />
        <Container className="m-b-4">
          <Column>
            <Title color="white">Jobs</Title>
            <RecommendedJobs limit={2} />
          </Column>
        </Container>
        <Container>
          <Column>
            <JobsFilter
              filterOptions={filterOptions}
              error={error}
              filterValues={filterValues}
              disableReset={disableReset}
              onResetFilters={resetFilters}
              onUpdateFilters={updateSelectFilters}
              onUpdateSeniority={updateSeniority}
            />
            <JobsList
              isLoading={isLoading}
              error={error}
              errorStatus={errorStatus}
              errorMessage={errorMessage}
              limit={limit}
              availableJobs={response}
              isLoadingMore={isLoadingMore}
              loadMoreCount={loadMoreCount}
              showLoadMore={Boolean(links.next)}
              onLoadMore={this.loadMoreData}
            />
          </Column>
        </Container>
      </>
    );
  }
}

Jobs.propTypes = {
  filterValues: PropTypes.object.isRequired,
  disableReset: PropTypes.bool,
  limit: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  updateSelectFilters: PropTypes.func.isRequired,
  updateSeniority: PropTypes.func.isRequired,
  incrementPagination: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  updatedFilterKey: PropTypes.string,
};

export default withConsumer(JobsConsumer, Jobs);
