import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { FormFieldHelper } from 'componentsNew';
import { DatePickerProps } from 'componentsNew/DatePickers/DatePicker';
import { DateTimePickerProps } from 'componentsNew/DatePickers/DateTimePicker';
import { FormFieldHelperType } from 'componentsNew/FormFieldHelper';
import { SearchSelectProps } from 'componentsNew/SearchSelect';
import { SelectProps } from 'componentsNew/Select/Select';
import { SiteSearchSelectProps } from 'componentsNew/SiteSearchSelect';
import React, { useMemo } from 'react';

/**
 * FormFieldWrapper can be used as a wrapper for input components to help handle
 * error states, accessibility and more as it develops (information texts, icons etc).
 * We do this by applying some extra props to the wrapped input component. MUI has a
 * similar FormControl component but the styling differs too much from what we aim for.
 *
 * If you're about to use this wrapper with an input component that is not listed below,
 * you need to add its "Props" type to "FieldElementProps" and potentially do some
 * tweaking when cloning the element so that the extra props are applied correctly.
 */

type FieldElementProps =
  | DatePickerProps
  | DateTimePickerProps
  | OutlinedInputProps
  | SelectProps
  | SearchSelectProps
  | SiteSearchSelectProps
  | SwitchProps;

export type FormFieldWrapperProps = {
  id: string;
  label: string;
  labelSize?: 'small' | 'large' | 'none';
  error?: string | string[];
  info?: string | string[];
  sx?: SxProps<Theme>;
  children: React.ReactElement<FieldElementProps>;
};

const FormFieldWrapper = ({
  id,
  label,
  labelSize = 'none',
  error = [],
  info = [],
  sx,
  children,
}: FormFieldWrapperProps) => {
  const fieldId = useMemo(() => `${id}-input`, [id]);

  const errorMessages = useMemo(
    () => [...(Array.isArray(error) ? error : [error])],
    [error]
  );

  const infoMessages = useMemo(
    () => [...(Array.isArray(info) ? info : [info])],
    [info]
  );

  const fieldElement = useMemo(() => {
    if (!React.isValidElement(children)) {
      return null;
    }
    const ariaDescribedBy = [...infoMessages, ...errorMessages]
      .map((_, index) => `${fieldId}-helper-${index}`)
      .join(' ');

    if (children.type === Switch) {
      return React.cloneElement(children, {
        id: fieldId,
        inputProps: {
          ...children.props.inputProps,
          'aria-describedby': ariaDescribedBy,
        },
      });
    }

    return React.cloneElement(children, {
      id: fieldId,
      error: errorMessages.length > 0,
      inputProps: {
        ...children.props.inputProps,
        'aria-describedby': ariaDescribedBy,
      },
    });
  }, [children, errorMessages, fieldId, infoMessages]);

  const labelElement = useMemo(() => {
    switch (labelSize) {
      case 'small':
        return (
          <Typography
            variant="body2"
            component="label"
            htmlFor={fieldId}
            sx={() => ({ fontWeight: 'bold' })}
          >
            {label}
          </Typography>
        );
      case 'large':
        return (
          <Typography
            variant="h4"
            component="label"
            htmlFor={fieldId}
            sx={(theme) => ({
              fontWeight: 400,
              color: theme.colors.text.tertiary,
              marginBottom: theme.spacing('xs'),
            })}
          >
            {label}
          </Typography>
        );
      default:
        return (
          <Typography variant="srOnly" component="label" htmlFor={fieldId}>
            {label}
          </Typography>
        );
    }
  }, [fieldId, label, labelSize]);

  return (
    <Stack
      sx={[
        (theme) => ({
          position: 'relative',
          rowGap: theme.spacing('xxxs'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {labelElement}
      {fieldElement}
      {infoMessages.map((infoMessage, index) => (
        <FormFieldHelper
          open={true}
          key={`${fieldId}-helper-${index}`}
          type={FormFieldHelperType.Information}
        >
          {infoMessage}
        </FormFieldHelper>
      ))}
      {errorMessages.map((errorMessage, index) => (
        <FormFieldHelper
          open={true}
          key={`${fieldId}-helper-${index}`}
          type={FormFieldHelperType.Critical}
        >
          {errorMessage}
        </FormFieldHelper>
      ))}
    </Stack>
  );
};

export { FormFieldWrapper };
