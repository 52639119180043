/* eslint-disable no-restricted-imports */
import { Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import createTypography from '@mui/material/styles/createTypography';

/**
 * The Typography part of the theme is used for customizing
 * typography related properties such as "font-family", "font-weight",
 * "font-size", "line-height", "letter-spacing" and "text-transform".
 */

export type AvenueTypographySize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl';

const createMuiTypography = (muiPalette: Palette) => {
  const typographyOptions: TypographyOptions = {
    fontFamily: 'Noto Sans, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    button: {
      fontWeight: 600,
    },
    button1: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    button2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    button3: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: 1.077,
    },
    display1: {
      // "text-6xl" (~64px)
      fontSize: 'clamp(4rem, calc(4rem + 0vw), 4rem)',
      fontWeight: 400,
      lineHeight: 1.125,
    },
    display2: {
      // "text-5xl" (~56px)
      fontSize: 'clamp(3.5rem, calc(3.5rem + 0vw), 3.5rem)',
      fontWeight: 400,
      lineHeight: 1.143,
    },
    display3: {
      // "text-4xl" (~48px)
      fontSize: 'clamp(3rem, calc(3rem + 0vw), 3rem)',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    display4: {
      // "text-3xl" (~40px)
      fontSize: 'clamp(2.5rem, calc(2.5rem + 0vw), 2.5rem)',
      fontWeight: 400,
      lineHeight: 1.25,
    },
    h1: {
      // "text-2xl" (~32px)
      fontSize: 'clamp(2rem, calc(2rem + 0vw), 2rem)',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h2: {
      // "text-xl" (~24px)
      fontSize: 'clamp(1.5rem, calc(1.5rem + 0vw), 1.5rem)',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      // "text-lg" (~20px)
      fontSize: 'clamp(1.25rem, calc(1.25rem + 0vw), 1.25rem)',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      // "text-md" (~18px)
      fontSize: 'clamp(1.125rem, calc(1.125rem + 0vw), 1.125rem)',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h5: undefined,
    h6: undefined,
    body1: {
      // "text-base" (~16px)
      fontSize: 'clamp(1rem, calc(1rem + 0vw), 1rem)',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body2: {
      // "text-sm" (~14px)
      fontSize: 'clamp(0.8125rem, calc(0.71875rem + 0.1953125vw), 0.875rem)',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    caption: {
      // "text-xs" (~13px)
      fontSize: 'clamp(.6875rem, calc(.5rem + .390625vw), .8125rem)',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    srOnly: {},
  };

  return createTypography(muiPalette, typographyOptions);
};

export { createMuiTypography };
