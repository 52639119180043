import './SimilarJobs.scss';

import { getSimilar, headers } from 'api/jobs';
import Box from 'components/Box';
import Fetcher from 'components/Fetcher';
import ContentRows from 'components/JobsList/ContentRows';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GAonSimilarJobsListItemClick } from 'utils/analytics';
import makeQueryString from 'utils/misc/makeQueryString';

const createQuery = (seniorityLevel, category, locations, limit, id) => {
  return makeQueryString({
    filter: {
      locations: locations.map((location) => location.id),
      categories: category.id,
      senioritylevelname: seniorityLevel,
      currentposition: id,
    },
    page: {
      limit,
    },
  });
};

const SimilarJobs = ({ seniorityLevel, category, locations, limit, id }) => {
  return (
    <Fetcher
      source={getSimilar}
      headers={headers}
      param={createQuery(seniorityLevel, category, locations, limit, id)}
    >
      {({ response }) =>
        response.length === 0 ? null : (
          <Box className="similar-jobs">
            <h2 className="similar-jobs__title">Similar jobs</h2>
            <ul className="similar-jobs__items">
              {response.map((item) => (
                <li key={item.id} className="similar-jobs__item">
                  <Link
                    to={`/jobs/${item.id}`}
                    onClick={() => {
                      GAonSimilarJobsListItemClick(item.id, item.name);
                    }}
                    className="similar-jobs__link"
                  >
                    <ContentRows
                      name={item.name}
                      category={item.category}
                      locations={item.locations}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
        )
      }
    </Fetcher>
  );
};

SimilarJobs.propTypes = {
  seniorityLevel: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
};

SimilarJobs.defaultProps = {
  limit: 3,
};

export default SimilarJobs;
