import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as productOfTheWeekApi from 'api/cms/ProductOfTheWeek';
import { ProductOfTheWeekType } from 'api/cms/ProductOfTheWeek/types';
import * as feedbackApi from 'api/feedback';
import { UpdateLikeResponse } from 'api/feedback/transformers';
import { useUser } from 'components/Context/User';
import { AvenueRoute } from 'constants/routes';
import { ProductOfTheWeekCard } from 'pagesNew/ProductOfTheWeekGallery/ProductOfTheWeekCard';
import { ProductOfTheWeekCardSkeleton } from 'pagesNew/ProductOfTheWeekGallery/ProductOftheWeekCardSkeleton';
import { ProductOfTheWeekSheet } from 'pagesNew/ProductOfTheWeekGallery/ProductOfTheWeekSheet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import { GAonClickHomeProductOfTheWeekReadMore } from 'utils/analytics';

const elementId = 'home-product-of-the-week';

const ProductOfTheWeek = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [productOfTheWeekArticle, setProductOfTheWeekArticle] =
    useState<ProductOfTheWeekType | null>(null);

  const [likesMeta, setLikesMeta] = useState<{
    likesCount: number;
    isLikedByMe: boolean;
  } | null>(null);

  const user = useUser();
  const mountedRef = useRef<boolean>(true);

  const toggleLike = useCallback(async () => {
    if (!productOfTheWeekArticle || !likesMeta) {
      return;
    }
    try {
      const response = !likesMeta.isLikedByMe
        ? ((await feedbackApi.sendLike(
            productOfTheWeekArticle._doc
          )) as UpdateLikeResponse)
        : ((await feedbackApi.deleteLike(
            productOfTheWeekArticle._doc
          )) as UpdateLikeResponse);

      if (!mountedRef.current) return;
      setLikesMeta({
        likesCount: response.data.meta.total,
        isLikedByMe: response.data.meta.likedByMe,
      });
    } catch {}
  }, [likesMeta, productOfTheWeekArticle]);

  useEffect(() => {
    async function fetchProductOfTheWeek() {
      setIsLoading(true);
      let productOfTheWeek: ProductOfTheWeekType | null = null;
      let likesMeta: { likesCount: number; isLikedByMe: boolean } | null = null;

      try {
        const productOfTheWeekResponse =
          await productOfTheWeekApi.getProductOfTheWeek();
        if (productOfTheWeekResponse.data.data) {
          productOfTheWeek = productOfTheWeekResponse.data.data;
        }
      } catch {}

      if (!productOfTheWeek) {
        if (!mountedRef.current) return;
        setIsLoading(false);
        return;
      }

      try {
        const likesResponse = await feedbackApi.getLikes(productOfTheWeek._doc);
        if (likesResponse.data.meta) {
          likesMeta = {
            likesCount: likesResponse.data.meta.total,
            isLikedByMe: likesResponse.data.meta.likedByMe,
          };
        }
      } catch {
      } finally {
        if (!mountedRef.current) return;
        setProductOfTheWeekArticle(productOfTheWeek);
        setLikesMeta(likesMeta);
        setIsLoading(false);
      }
    }
    fetchProductOfTheWeek();
  }, [user.userId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (isLoading) {
    return <ProductOfTheWeekCardSkeleton />;
  }
  if (
    !productOfTheWeekArticle ||
    !Object.keys(productOfTheWeekArticle).length
  ) {
    return null;
  }

  return (
    <Stack id={elementId} sx={(theme) => ({ gap: theme.spacing('xs') })}>
      <Typography
        component="h2"
        variant="h4"
        sx={(theme) => ({
          color: theme.colors.text.secondary,
          textAlign: 'center',
        })}
      >
        {translations.productOfTheWeek}
      </Typography>
      <ProductOfTheWeekCard
        id={`${elementId}-card`}
        product={productOfTheWeekArticle}
        likesMeta={likesMeta || undefined}
        onLike={toggleLike}
        onClick={() => {
          setOpen(true);
          GAonClickHomeProductOfTheWeekReadMore(productOfTheWeekArticle.title);
        }}
      />
      <Button
        id={`${elementId}-gallery-link`}
        to={AvenueRoute.ProductOfTheWeekGallery}
        variant="linkButton"
        component={ReactRouterLink}
        sx={{ alignSelf: 'end' }}
      >
        {translations.productOfTheWeekGalleryLinkLabel}
      </Button>
      <ProductOfTheWeekSheet
        open={open}
        id={`${elementId}-sheet`}
        product={productOfTheWeekArticle}
        likesMeta={likesMeta}
        onLike={toggleLike}
        onClose={() => setOpen(false)}
      />
    </Stack>
  );
};

export { ProductOfTheWeek };
