import Img from 'components/Image';
import WhitelistUrlModal from 'components/WhitelistUrlModal';
import { EmbedMSForm, EmbedPowerBI } from 'componentsNew';
import * as embedHelpers from 'componentsNew/Embed/helpers';
import HTMLParser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { GAonParsedContentLinkClick } from 'utils/analytics';

import withContentMeasurer from './components/ContentMeasurer';

const removeFirstLetterFromBody = (children) => {
  if (children[0].type === 'text') {
    children[0].data = children[0].data.slice(1);
  }
  return children;
};
const getFirstLetterInBody = (body) => {
  if (!body) return body;

  const firstLetter = body.split('').splice(0, 1).join('');
  return firstLetter.toUpperCase();
};

const parserOptions = (meta) => ({
  replace: ({ name, attribs, children }) => {
    if (name === 'a') {
      const { class: className, href } = attribs;

      if (!href) {
        return domToReact(children, parserOptions);
      }

      return (
        <WhitelistUrlModal
          linkUrl={href}
          className={className}
          onClick={GAonParsedContentLinkClick(meta, href)}
          openInNewWindow="true"
        >
          {domToReact(children, parserOptions)}
        </WhitelistUrlModal>
      );
    }

    if (meta.type === 'Alert' && name === 'p') {
      return <div>{domToReact(children, parserOptions)}</div>;
    }

    if (meta.type === 'Story' && typeof attribs === 'object') {
      if (attribs.id === 'story-first-tag') {
        return (
          <div className="story-first-tag__container">
            <div className="story-first-tag__first-letter">
              {getFirstLetterInBody(children[0].data)}
            </div>
            <p className="story-first-tag__body">
              {domToReact(removeFirstLetterFromBody(children), parserOptions)}
            </p>
          </div>
        );
      }
    }

    if (meta.type === 'Jobs' && name === 'img') {
      return <></>;
    }

    if (name === 'img') {
      const { class: className, ...props } = attribs;
      const width = Number(props['data-original-width']);
      const height = Number(props['data-original-height']);
      const hasDimensions = width && height;
      const expandable = props['expandable'] !== undefined;
      const orientation = width > height ? 'landscape' : 'portrait';

      return (
        <Img
          className={className}
          {...props}
          maxWidth={width}
          maxHeight={height}
          measureContentWidth={meta.measureContentWidth}
          type={
            hasDimensions
              ? orientation === 'landscape'
                ? 'contentLandscape'
                : 'contentPortrait'
              : null
          }
          expandable={expandable}
        >
          {domToReact(children, parserOptions)}
        </Img>
      );
    }

    if (
      attribs &&
      attribs?.class === 'embed-container' &&
      children[0]?.attribs?.class === 'accordion-container'
    ) {
      attribs.class = 'accordion-wrapper';
      return;
    }

    if (
      attribs &&
      attribs?.class === 'embed-container' &&
      children[0]?.name === 'iframe' &&
      Boolean(children[0]?.attribs?.src)
    ) {
      const src = children[0].attribs?.src;

      if (embedHelpers.isMSFormsEmbedUrl(src)) {
        return <EmbedMSForm src={src} />;
      }

      if (embedHelpers.isPowerBIEmbedUrl(src)) {
        return <EmbedPowerBI src={src} />;
      }

      if (embedHelpers.isPlayerenceEmbedUrl(src)) {
        attribs.class = 'embed-container embed-container-playerence';
        return;
      }
    }
  },
});

const Parser = ({ html, ...props }) => HTMLParser(html, parserOptions(props));

Parser.propTypes = {
  html: PropTypes.string.isRequired,
  type: PropTypes.string,
  division: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Parser.defaultProps = {
  html: '',
};

export default memo(withContentMeasurer(Parser));
