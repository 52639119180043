import Stack from '@mui/material/Stack';
import { useUser } from 'components/Context/User';
import { translations } from 'translations';

import { FieldName } from './FieldName';
import { FieldValue } from './FieldValue';
import { FieldWrapper } from './FieldWrapper';
import { ProfileInformationSkeleton } from './ProfileInformationSkeleton';

const elementId = 'profile-information';

const ProfileInformation = () => {
  const user = useUser();

  if (user.firstLoad) {
    return <ProfileInformationSkeleton />;
  }
  return (
    <Stack
      id={elementId}
      component="section"
      aria-label={translations.profileDetails}
      sx={(theme) => ({
        rowGap: theme.spacing('sm'),
        padding: theme.spacing('md'),
      })}
    >
      <FieldWrapper>
        <FieldName>{translations.email}</FieldName>
        <FieldValue id={`${elementId}-email`}>
          {user.email || translations.notAvailable}
        </FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldName>{translations.phoneMobile}</FieldName>
        <FieldValue id={`${elementId}-mobile`}>
          {user.mobilePhoneNo || translations.notAvailable}
        </FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldName>{translations.phoneOffice}</FieldName>
        <FieldValue id={`${elementId}-phone`}>
          {user.phoneNo || translations.notAvailable}
        </FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldName>{translations.company}</FieldName>
        <FieldValue id={`${elementId}-company`}>
          {user.companyName || translations.notAvailable}
        </FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldName>{translations.division}</FieldName>
        <FieldValue>
          {user.divisionName || translations.notAvailable}
        </FieldValue>
      </FieldWrapper>
    </Stack>
  );
};

export { ProfileInformation };
