import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { CmsContentType } from 'api/cms/types';
import { Badge, TableCellWithSort } from 'componentsNew';
import notFoundImage from 'images/not-found.jpg';
import { useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

import * as helpers from './helpers';
import { ArticleStatus } from './ManageInsightsDatabaseFilter';
import { ManageInsightsDatabaseTableSkeleton } from './ManageInsightsDatabaseTableSkeleton';

const elementId = 'profile-manage-insights-database-table';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ManageInsightsDatabaseTableField {
  Title = 'title',
  PublishedDate = 'publishedDate',
  ModifiedDate = 'modifiedDate',
  LikesCount = 'likesCount',
  CommentsCount = 'commentsCount',
  InformationOwner = 'informationOwner',
}

export type ManageInsightsDatabaseTableItem = {
  id: string;
  title: string;
  type: CmsContentType;
  publishedDate?: string;
  modifiedDate?: string;
  likesCount?: number;
  commentsCount?: number;
  newCommentsCount?: number;
  informationOwner?: {
    name: string;
    oid: string;
  };
  linkPath: string;
};

type ManageInsightsDatabaseTableProps = {
  sx?: SxProps<Theme>;
  isLoading: boolean;
  items: ManageInsightsDatabaseTableItem[];
  filter: {
    articleStatus: ArticleStatus;
  };
  sort: {
    field: ManageInsightsDatabaseTableField;
    order: SortOrder;
  };
  onSortChange: (sort: {
    field: ManageInsightsDatabaseTableField;
    order: SortOrder;
  }) => void;
};

const ManageInsightsDatabaseTable = ({
  sx,
  isLoading,
  items,
  filter,
  sort,
  onSortChange,
}: ManageInsightsDatabaseTableProps) => {
  const handleSortClick = useCallback(
    (field: ManageInsightsDatabaseTableField) => {
      onSortChange({
        field: field,
        order:
          sort?.field === field && sort.order === SortOrder.Asc
            ? SortOrder.Desc
            : SortOrder.Asc,
      });
    },
    [onSortChange, sort]
  );

  if (isLoading) {
    return <ManageInsightsDatabaseTableSkeleton />;
  }

  if (!items.length) {
    return (
      <Stack alignItems="center" padding="1rem">
        <Box component="img" src={notFoundImage} maxHeight="300px" />
        <Typography marginTop={1}>{translations.manageContentEmpty}</Typography>
      </Stack>
    );
  }

  return (
    <TableContainer sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Table
        id={elementId}
        aria-label={translations.profileManageInsightsDatabase}
      >
        <TableHead>
          <TableRow>
            <TableCellWithSort
              width="30%"
              sorted={sort?.field === ManageInsightsDatabaseTableField.Title}
              onSort={() =>
                handleSortClick(ManageInsightsDatabaseTableField.Title)
              }
            >
              {translations.manageContentTitle}
            </TableCellWithSort>
            {filter.articleStatus === ArticleStatus.Published && (
              <>
                <TableCellWithSort
                  sorted={
                    sort?.field ===
                    ManageInsightsDatabaseTableField.PublishedDate
                  }
                  onSort={() =>
                    handleSortClick(
                      ManageInsightsDatabaseTableField.PublishedDate
                    )
                  }
                >
                  {translations.manageContentPublishedDate}
                </TableCellWithSort>
                <TableCellWithSort
                  sorted={
                    sort?.field ===
                    ManageInsightsDatabaseTableField.InformationOwner
                  }
                  onSort={() =>
                    handleSortClick(
                      ManageInsightsDatabaseTableField.InformationOwner
                    )
                  }
                >
                  {translations.manageContentInformationOwner}
                </TableCellWithSort>
                <TableCell>{translations.manageContentLikes}</TableCell>
                <TableCell>{translations.manageContentComments}</TableCell>
              </>
            )}
            {filter.articleStatus === ArticleStatus.Drafts && (
              <TableCellWithSort
                sorted={
                  sort?.field === ManageInsightsDatabaseTableField.ModifiedDate
                }
                onSort={() =>
                  handleSortClick(ManageInsightsDatabaseTableField.ModifiedDate)
                }
              >
                {translations.manageContentModifiedDate}
              </TableCellWithSort>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`${item.id}-${index}`}
              id={`${elementId}-row-${index}`}
            >
              <TableCell>
                <Link
                  variant="body2"
                  fontWeight="bold"
                  to={item.linkPath}
                  component={ReactRouterLink}
                  sx={{ textDecoration: 'none' }}
                >
                  {item.title}
                </Link>
              </TableCell>
              {filter.articleStatus === ArticleStatus.Published && (
                <>
                  <TableCell>
                    {item.publishedDate ? (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={helpers.getFormattedDate(item.publishedDate)}
                      />
                    ) : (
                      translations.notAvailable
                    )}
                  </TableCell>
                  <TableCell>
                    {item.informationOwner ? (
                      <Link
                        to={`/people/${item.informationOwner.oid}`}
                        component={ReactRouterLink}
                        sx={{ textDecoration: 'none' }}
                      >
                        {item.informationOwner.name}
                      </Link>
                    ) : (
                      translations.notAvailable
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.likesCount === undefined ? (
                      translations.notAvailable
                    ) : item.likesCount === 0 ? null : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={item.likesCount}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.commentsCount === undefined ? (
                      translations.notAvailable
                    ) : item.commentsCount ===
                      0 ? null : (item.newCommentsCount || 0) > 0 ? (
                      <Badge
                        size="small"
                        color="success"
                        variant="outlined"
                        label={item.commentsCount}
                        type="status"
                      />
                    ) : (
                      <Badge
                        size="small"
                        color="default"
                        variant="outlined"
                        label={item.commentsCount}
                      />
                    )}
                  </TableCell>
                </>
              )}
              {filter.articleStatus === ArticleStatus.Drafts && (
                <TableCell>
                  {!item.modifiedDate ? (
                    translations.notAvailable
                  ) : (
                    <Badge
                      size="small"
                      color="default"
                      variant="outlined"
                      label={helpers.getFormattedDate(item.modifiedDate)}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ManageInsightsDatabaseTable };
