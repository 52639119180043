import './Editable.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Editable = ({
  children,
  size,
  color,
  className,
  hashtag,
  error,
  roundedCorners,
  showAllEvents,
}) => {
  const editableClassName = classNames('editable', {
    [`editable--${size}`]: size,
    [`editable--${color}`]: color,
    'editable--hashtag': hashtag,
    'editable--error': error,
    'editable--rounded-corners': roundedCorners,
    'editable--disabled': showAllEvents,
    [className]: className,
  });
  return <div className={editableClassName}>{children}</div>;
};

Editable.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

Editable.defaultProps = {
  size: 'medium',
  color: 'black',
};

export default Editable;
