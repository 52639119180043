import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { useHomeBanner } from 'components/Context/HomeBanner';

import { BannerLarge } from './BannerLarge';
import { BannerMedium } from './BannerMedium';

const elementId = 'home-banner';

const Banner = () => {
  const { bannerData } = useHomeBanner();

  if (!bannerData) {
    return null;
  }

  const {
    size,
    title,
    description,
    linkUrl,
    linkButtonText,
    heroImage,
    heroAltText,
  } = bannerData;

  if (size === HeroBannerSize.Medium || !heroImage) {
    return (
      <BannerMedium
        id={elementId}
        title={title}
        description={description}
        linkUrl={linkUrl}
        linkText={linkButtonText}
      />
    );
  }

  return (
    <BannerLarge
      id={elementId}
      title={title}
      description={description}
      linkUrl={linkUrl}
      linkText={linkButtonText}
      imageUrl={heroImage}
      imageAltText={heroAltText}
    />
  );
};

export { Banner };
