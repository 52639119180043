import './TreeItem.scss';

import classNames from 'classnames';
import { ReactComponent as Arrow } from 'components/Icon/Arrow.svg';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const TreeItem = ({
  portal,
  hasChildren,
  disabled,
  to,
  isOpen,
  setOpen,
  openKey,
  depth,
  isActive,
  inActivePath,
  children,
  isOutbound,
}) => {
  const listItemClassNames = portal
    ? classNames('tree-item', {
        [`tree-item--depth-${depth}__portal`]: depth,
        'tree-item--open__portal': isOpen,
        'tree-item--inActivePath__portal': inActivePath,
        'tree-item--active__portal': isActive,
      })
    : classNames('tree-item', {
        [`tree-item--depth-${depth}`]: depth,
        'tree-item--open': isOpen,
        'tree-item--inActivePath': inActivePath,
        'tree-item--active': isActive,
      });

  if (isOutbound) {
    return (
      <li className={listItemClassNames}>
        <span className="tree-item__container">
          <a href={to} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        </span>
      </li>
    );
  }

  return (
    <li className={listItemClassNames}>
      <span className="tree-item__container">
        <NavLink
          onClick={() => {
            if (!isOpen && hasChildren) {
              setOpen(openKey, true);
            }
          }}
          to={to}
          activeClassName="active"
          isActive={() => isActive}
          disabled={disabled}
          exact
        >
          {children}
        </NavLink>
        {hasChildren && (
          <span
            className="tree-item__arrow"
            onClick={() => setOpen(openKey, !isOpen)}
          >
            <Arrow />
          </span>
        )}
      </span>
    </li>
  );
};

TreeItem.propTypes = {
  hasChildren: PropTypes.bool,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  openKey: PropTypes.any,
  depth: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  inActivePath: PropTypes.bool,
  isOutbound: PropTypes.bool,
  children: PropTypes.node,
};

export default TreeItem;
