import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { ReactComponent as BellBadgeLargeIcon } from 'componentsNew/Icon/BellBadgeLarge.svg';
import { ReactComponent as BellBadgeSmallIcon } from 'componentsNew/Icon/BellBadgeSmall.svg';
import { ReactComponent as CheckCircleBadgeLargeIcon } from 'componentsNew/Icon/CheckCircleBadgeLarge.svg';
import { ReactComponent as CheckCircleBadgeSmallIcon } from 'componentsNew/Icon/CheckCircleBadgeSmall.svg';
import { ReactComponent as CrossIcon } from 'componentsNew/Icon/Cross.svg';
import { ReactComponent as ExclaimationTriangleBadgeLargeIcon } from 'componentsNew/Icon/ExclaimationTriangleBadgeLarge.svg';
import { ReactComponent as ExclaimationTriangleBadgeSmallIcon } from 'componentsNew/Icon/ExclaimationTriangleBadgeSmall.svg';
import { ReactComponent as InformationCircleBadgeLargeIcon } from 'componentsNew/Icon/InformationCircleBadgeLarge.svg';
import { ReactComponent as InformationCircleBadgeSmallIcon } from 'componentsNew/Icon/InformationCircleBadgeSmall.svg';
import { useMemo } from 'react';
import { translations } from 'translations';

export enum AlertDialogType {
  Information = 'information',
  Success = 'success',
  Warning = 'warning',
  Critical = 'critical',
}

export enum AlertDialogSize {
  Small = 'small',
  Large = 'large',
}

type AlertDialogProps = {
  id?: string;
  open: boolean;
  type?: AlertDialogType;
  size?: AlertDialogSize;
  icon?: React.ReactNode;
  title?: string;
  paragraphs?: string[];
  primaryButton?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  tertiaryButton?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  sx?: SxProps<Theme>;
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
};

const AlertDialog = ({
  id,
  open,
  type = AlertDialogType.Information,
  size = AlertDialogSize.Large,
  icon,
  title,
  paragraphs = [],
  primaryButton,
  secondaryButton,
  tertiaryButton,
  sx,
  children,
  onClose,
}: AlertDialogProps) => {
  const theme = useTheme();

  const iconSettings: {
    element: React.ReactNode;
  } = useMemo(() => {
    const large = size === AlertDialogSize.Large;
    switch (type) {
      case AlertDialogType.Success:
        return {
          element: icon ? (
            icon
          ) : large ? (
            <CheckCircleBadgeLargeIcon />
          ) : (
            <CheckCircleBadgeSmallIcon />
          ),
        };
      case AlertDialogType.Warning:
        return {
          element: icon ? (
            icon
          ) : large ? (
            <BellBadgeLargeIcon />
          ) : (
            <BellBadgeSmallIcon />
          ),
        };
      case AlertDialogType.Critical:
        return {
          element: icon ? (
            icon
          ) : large ? (
            <ExclaimationTriangleBadgeLargeIcon />
          ) : (
            <ExclaimationTriangleBadgeSmallIcon />
          ),
        };
      default:
        return {
          element: icon ? (
            icon
          ) : large ? (
            <InformationCircleBadgeLargeIcon />
          ) : (
            <InformationCircleBadgeSmallIcon />
          ),
        };
    }
  }, [size, type, icon]);

  const textSettings: {
    titleFallback: string;
    titleColor: string;
    titleTypography: CSSProperties;
    paragraphTypography: CSSProperties;
  } = useMemo(() => {
    const titleTypography =
      size === AlertDialogSize.Large
        ? theme.typography.h3
        : theme.typography.h4;

    const paragraphTypography =
      size === AlertDialogSize.Large
        ? theme.typography.body1
        : theme.typography.body2;

    switch (type) {
      case AlertDialogType.Success:
        return {
          titleFallback: translations.successful,
          titleColor: theme.colors.text.success,
          titleTypography,
          paragraphTypography,
        };
      case AlertDialogType.Warning:
        return {
          titleFallback: translations.warning,
          titleColor: theme.colors.text.primary,
          titleTypography,
          paragraphTypography,
        };
      case AlertDialogType.Critical:
        return {
          titleFallback: translations.error,
          titleColor: theme.colors.text.critical,
          titleTypography,
          paragraphTypography,
        };
      default:
        return {
          titleFallback: translations.information,
          titleColor: theme.colors.text.brand,
          titleTypography,
          paragraphTypography,
        };
    }
  }, [size, theme, type]);

  const paperSettings: {
    width: string;
    padding: string;
    gap: string;
  } = useMemo(() => {
    switch (size) {
      case AlertDialogSize.Small:
        return {
          width: '22.5rem',
          padding: theme.spacing('sm'),
          gap: theme.spacing('xs'),
        };
      default:
        return {
          width: '34.5rem',
          padding: theme.spacing('md'),
          gap: theme.spacing('sm'),
        };
    }
  }, [size, theme]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      sx={sx}
      PaperProps={{
        sx: (theme) => ({
          overflowY: 'visible',
          width: paperSettings.width,
          padding: paperSettings.padding,
          gap: paperSettings.gap,
          borderRadius: theme.border.radius.md,
        }),
      }}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('sm'),
        })}
      >
        <Box sx={() => ({ flexShrink: 0, '> svg': { display: 'block' } })}>
          {iconSettings.element}
        </Box>
        <Typography
          component="h2"
          sx={() => ({
            typography: textSettings.titleTypography,
            color: textSettings.titleColor,
          })}
        >
          {title || textSettings.titleFallback}
        </Typography>
        {onClose && (
          <IconButton
            size="large"
            aria-label={translations.close}
            sx={(theme) => ({
              marginLeft: 'auto',
              padding: theme.spacing('xxxs'),
            })}
            onClick={onClose}
          >
            <CrossIcon />
          </IconButton>
        )}
      </Stack>
      {paragraphs &&
        paragraphs.map((paragraph, index) => (
          <Typography
            key={`alert-dialog-paragraph-${index}`}
            sx={(theme) => ({
              color: theme.colors.text.tertiary,
              typography: textSettings.paragraphTypography,
            })}
          >
            {paragraph}
          </Typography>
        ))}
      {children}
      {(primaryButton || secondaryButton || tertiaryButton) && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            justifyContent: 'end',
            gap: theme.spacing('sm'),
            flexWrap: 'wrap',
          })}
        >
          {tertiaryButton && (
            <Button
              size="medium"
              variant="text"
              disabled={tertiaryButton.disabled}
              onClick={tertiaryButton.onClick}
            >
              {tertiaryButton.loading ? (
                <CircularProgress size={20} />
              ) : (
                tertiaryButton.text
              )}
            </Button>
          )}
          {secondaryButton && (
            <Button
              size="medium"
              variant="outlined"
              disabled={secondaryButton.disabled}
              onClick={secondaryButton.onClick}
            >
              {secondaryButton.loading ? (
                <CircularProgress size={20} />
              ) : (
                secondaryButton.text
              )}
            </Button>
          )}
          {primaryButton && (
            <Button
              size="medium"
              variant="contained"
              disabled={primaryButton.disabled}
              onClick={primaryButton.onClick}
            >
              {primaryButton.loading ? (
                <CircularProgress size={20} />
              ) : (
                primaryButton.text
              )}
            </Button>
          )}
        </Stack>
      )}
    </Dialog>
  );
};
export { AlertDialog };
