import './Box.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Box = ({ children, color, className, padding, noShadow, wide }) => {
  const boxClassName = classNames('box', {
    [`box--${color}`]: typeof color !== 'undefined',
    [className]: className,
    'box--no-padding': !padding,
    'box--no-shadow': noShadow,
    'box--wide': wide,
  });

  return <div className={boxClassName}>{children}</div>;
};

Box.propTypes = {
  id: PropTypes.string,
  color: PropTypes.oneOf(['white', 'green', 'blue', 'transparent']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  padding: PropTypes.bool,
  noShadow: PropTypes.bool,
  wide: PropTypes.bool,
};

Box.defaultProps = {
  color: 'white',
  padding: true,
};

export default Box;
