import Resize from 'components/Resize';
import { Desktop, Mobile } from 'components/Responsive';
import ContentLoader from 'react-content-loader';

const DesktopContent = ({ width = 1050 }) => (
  <ContentLoader
    className=""
    width={width}
    height={420}
    preserveAspectRatio="none"
  >
    <rect x={40} y={40} width={351} height={18} />
    <rect x={431} y={40} width={281} height={18} />
    <rect x={751} y={40} width={281} height={18} />

    <rect x={40} y={100} width={351} height={18} />
    <rect x={431} y={100} width={281} height={18} />
    <rect x={751} y={100} width={281} height={18} />

    <rect x={40} y={160} width={351} height={18} />
    <rect x={431} y={160} width={281} height={18} />
    <rect x={751} y={160} width={281} height={18} />

    <rect x={40} y={220} width={351} height={18} />
    <rect x={431} y={220} width={281} height={18} />
    <rect x={751} y={220} width={281} height={18} />

    <rect x={40} y={280} width={351} height={18} />
    <rect x={431} y={280} width={281} height={18} />
    <rect x={751} y={280} width={281} height={18} />

    <rect x={40} y={340} width={351} height={18} />
    <rect x={431} y={340} width={281} height={18} />
    <rect x={751} y={340} width={281} height={18} />

    <rect x={40} y={400} width={351} height={18} />
    <rect x={431} y={400} width={281} height={18} />
    <rect x={751} y={400} width={281} height={18} />
  </ContentLoader>
);
const MobileContent = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={140}
    preserveAspectRatio="none"
  >
    <rect x={0} y={0} width={200} height={10} />
    <rect x={0} y={15} width={100} height={10} />
    <rect x={0} y={30} width={280} height={10} />

    <rect x={0} y={50} width={200} height={10} />
    <rect x={0} y={65} width={100} height={10} />
    <rect x={0} y={80} width={280} height={10} />

    <rect x={0} y={110} width={200} height={10} />
    <rect x={0} y={125} width={100} height={10} />
    <rect x={0} y={140} width={280} height={10} />
  </ContentLoader>
);
const Loader = () => (
  <Resize>
    {({ width }) => (
      <>
        <Desktop className="editor-row__loader">
          <DesktopContent width={width} />
        </Desktop>
        <Mobile>
          <MobileContent width={width} />
        </Mobile>
      </>
    )}
  </Resize>
);

export default Loader;
