import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PromptResult } from 'api/aiSearch/types';
import { ReactComponent as RobotIcon } from 'componentsNew/Icon/Robot.svg';
import { useMemo } from 'react';

import { AIChatDisclaimerWithFeedbackLink } from './AIChatDisclaimerWithFeedbackLink';
import { AIChatLoadingDots } from './AIChatLoadingDots';
import { AIChatSourceList } from './AIChatSourceList';

type AIChatBubbleResultProps = {
  id: string;
  result?: PromptResult;
  loading?: boolean;
};

const AIChatBubbleResult = ({
  id,
  result,
  loading,
}: AIChatBubbleResultProps) => {
  const content = useMemo(() => {
    if (loading) {
      return <AIChatLoadingDots />;
    }
    if (!result) {
      return null;
    }
    return (
      <>
        <Typography dangerouslySetInnerHTML={{ __html: result.text }} />
        {result.sources && (
          <AIChatSourceList id={`${id}-source-list`} sources={result.sources} />
        )}
        <AIChatDisclaimerWithFeedbackLink />
      </>
    );
  }, [id, loading, result]);

  return (
    <Stack
      id={id}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xs'),
        '> svg': { flexShrink: 0 },
      })}
    >
      <RobotIcon height={32} width={32} />
      <Stack
        sx={(theme) => ({
          padding: loading ? theme.spacing('xxs') : theme.spacing('sm'),
          boxShadow: theme.elevation.sm,
          borderRadius: theme.border.radius.lg,
          border: `1px solid ${theme.colors.border.surfaceInformative}`,
          backgroundColor: theme.colors.surface.informative,
          typography: theme.typography.body1,
          wordBreak: 'break-word',
          gap: theme.spacing('xs'),
        })}
      >
        {content}
      </Stack>
    </Stack>
  );
};

export { AIChatBubbleResult };
