import * as feedbackApi from 'api/feedback';
import * as feedbackTransformers from 'api/feedback/transformers';
import { getNewsFlashes } from 'api/news';
import Button from 'components/Button';
import Center from 'components/Center';
import { NextArticleConsumer } from 'components/Context/NextArticle';
import { useNextArticle } from 'components/Context/NextArticle';
import { useUser } from 'components/Context/User';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import ScrollRestoration from 'components/ScrollRestoration';
import { saveScrollPosition } from 'components/ScrollRestoration';
import { ArticleList, ArticleListSkeleton } from 'componentsNew';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  GAfailedToLoad,
  GAonLikeSent,
  GAonShowMoreClick,
} from 'utils/analytics';
import { GAonCommentFeedClick, GAonFeedReadMoreClick } from 'utils/analytics';
import animateScroll from 'utils/misc/animateScroll';
import makeQueryString from 'utils/misc/makeQueryString';

const FeedListItems = ({
  response,
  nextArticleIndex,
  forNextArticle,
  currentFilter,
  hideLastDivider,
}) => {
  const [likesAndCommentsMetaByArticleId, setLikesAndCommentsMetaByArticleId] =
    useState(null);

  const nextInYourFeed = useNextArticle();
  const user = useUser();

  const handleLikeChange = useCallback(
    async (articleId, like) => {
      try {
        const response = like
          ? await feedbackApi.sendLike(articleId.replace('cms-', ''))
          : await feedbackApi.deleteLike(articleId.replace('cms-', ''));

        const oldLikesAndCommentsMeta =
          likesAndCommentsMetaByArticleId[articleId] || {};

        const newLikesAndCommensMeta = {
          ...oldLikesAndCommentsMeta,
          isLikedByMe: response.data.meta.likedByMe,
          likesCount: response.data.meta.total,
        };
        const newLikesAndCommentsMetaByArticleId = {
          ...likesAndCommentsMetaByArticleId,
          [articleId]: newLikesAndCommensMeta,
        };
        setLikesAndCommentsMetaByArticleId(newLikesAndCommentsMetaByArticleId);
      } catch {}
    },
    [likesAndCommentsMetaByArticleId]
  );

  useEffect(() => {
    if (!response?.length) {
      return;
    }
    async function fetchLikesAndComments() {
      const articleIds = response.map((item) => item.id.replace('cms-', ''));

      const likesAndCommentsResponse = await feedbackApi.getLikesAndComments(
        articleIds
      );
      const likesAndCommentsMetaByArticleId =
        feedbackTransformers.likesAndCommentsResponseToLikesAndCommentsMetaByArticleId(
          likesAndCommentsResponse,
          user.userId
        );
      setLikesAndCommentsMetaByArticleId(likesAndCommentsMetaByArticleId);
    }
    fetchLikesAndComments();
  }, [response, user]);

  const articles = useMemo(
    () =>
      response.map((item, index) => ({
        ...item,
        tags: item.tags || [],
        likesAndCommentsMeta: likesAndCommentsMetaByArticleId
          ? likesAndCommentsMetaByArticleId[item.id]
          : undefined,
        index,
      })),
    [likesAndCommentsMetaByArticleId, response]
  );

  if (!response?.length) {
    return null;
  }

  return (
    <ArticleList
      articles={articles}
      hideLastDivider={hideLastDivider}
      onLikeClick={(article) => {
        const like = !article.likesAndCommentsMeta?.isLikedByMe;
        handleLikeChange(article.id, like);
        if (like) GAonLikeSent(article.title, true);
      }}
      onCommentClick={(article) => GAonCommentFeedClick(article.title)}
      onArticleClick={(article) => {
        GAonFeedReadMoreClick(article.title);
        saveScrollPosition('news');
        if (currentFilter) {
          nextInYourFeed.updateCurrentFilter(currentFilter);
          nextInYourFeed.updateArticleIndex(
            forNextArticle ? nextArticleIndex : article.index
          );
        }
      }}
    />
  );
};

const FeedList = ({
  wrapper: Wrapper = Fragment,
  limit = 5,
  offset = 0,
  useLoadMore = true,
  news,
  blog,
  story,
  press,
  topNewsOnly,
  onlyGlobalItems,
  onlyMatchingDivision,
  onlyMatchingSegment,
  onlyMatchingCountry,
  onlyMatchingDepartment,
  onlyMatchingSite,
  onlyMatchingCompany,
  divisionId,
  segmentId,
  countryId,
  departmentId,
  siteId,
  companyId,
  isLoading,
  locale,
  channel,
  pagination,
  setPagination,
  showNoResult,
  publishedStartDate,
  publishedEndDate,
  forNextArticle,
  currentFilter,
  isGlobalFeed,
  isGlobalForMyFeed,
  onNoResult,
  hideLastDivider,
}) => {
  if (isLoading) {
    return (
      <Wrapper>
        <ArticleListSkeleton count={limit} />
      </Wrapper>
    );
  }
  return (
    <Fetcher
      source={getNewsFlashes}
      param={makeQueryString({
        filter: {
          division: divisionId,
          country: countryId,
          department: departmentId,
          segment: segmentId,
          site: siteId,
          company: companyId,
          type: {
            pressrelease: press,
            newsarticle: news,
            blogpost: blog,
            storyarticle: story,
          },
          localization: {
            global: onlyGlobalItems,
            division: onlyMatchingDivision,
            segment: onlyMatchingSegment,
            country: onlyMatchingCountry,
            department: onlyMatchingDepartment,
            site: onlyMatchingSite,
            company: onlyMatchingCompany,
          },
          tag: channel,
          PublishedStartDate: publishedStartDate,
          PublishedEndDate: publishedEndDate,
          topNewsOnly: topNewsOnly ? topNewsOnly : undefined,
          isGlobalFeed,
          isGlobalForMyFeed,
          locale,
        },
        page: {
          limit,
          offset,
        },
      })}
      useLoadMore={useLoadMore}
      pagination={pagination}
      setPagination={setPagination}
      onError={(status, message) => {
        GAfailedToLoad(status, message, 'news');
        return <Error message={message} />;
      }}
      onLoading={() => (
        <Wrapper>
          <ArticleListSkeleton count={limit} />
        </Wrapper>
      )}
    >
      {({
        isLoading,
        response,
        loadMore,
        loadMoreCount,
        isLoadingMore,
        links,
      }) => {
        if (response.length === 0 && onNoResult) {
          onNoResult();
        }

        return (
          <>
            {response.length === 0 && forNextArticle && (
              <NextArticleConsumer>
                {({ setIsNextArticleVisible }) =>
                  setIsNextArticleVisible(false)
                }
              </NextArticleConsumer>
            )}
            {response.length === 0 && showNoResult === false ? null : (
              <Wrapper>
                {response.length === 0 && (
                  <p>
                    There are no available news items with this combination of
                    filters.
                  </p>
                )}

                <FeedListItems
                  response={response}
                  nextArticleIndex={offset}
                  currentFilter={forNextArticle ? null : currentFilter}
                  forNextArticle={forNextArticle}
                  hideLastDivider={hideLastDivider}
                />

                {isLoadingMore ? (
                  <ArticleListSkeleton count={loadMoreCount} />
                ) : (
                  <ScrollRestoration storageKey="news" />
                )}
              </Wrapper>
            )}
            {useLoadMore && links && links.next && (
              <Center className="m-t-6">
                <Button
                  onClick={() => {
                    loadMore();
                    GAonShowMoreClick(channel ? `Channel: ${channel}` : 'Feed');
                    animateScroll(220, 50);
                  }}
                  disabled={isLoading}
                >
                  Load more
                </Button>
              </Center>
            )}
          </>
        );
      }}
    </Fetcher>
  );
};

FeedList.propTypes = {
  press: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  global: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  news: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  useLoadMore: PropTypes.bool,
  showNoResult: PropTypes.bool,
  locale: PropTypes.string,
  forNextArticle: PropTypes.bool,
  currentFilter: PropTypes.object,
  isGlobalFeed: PropTypes.bool,
  hideLastDivider: PropTypes.bool,
};

FeedList.defaultProps = {
  useLoadMore: true,
  showNoResult: true,
  locale: 'en',
  forNextArticle: false,
  hideLastDivider: false,
};

export default FeedList;
