import './TableRow.scss';

import Checkbox from 'components/Checkbox';
import { getFormattedDate } from 'utils/misc/time';

const TableLink = (props) => {
  return <div className="table-link">{props.value}</div>;
};

const TableRow = ({ tableData, isChecked, selectNotification }) => {
  const startDate = tableData.startDate;
  const endDate = tableData.endDate;

  return (
    <div className="table-row">
      <div className="table-row__item--flex">
        <div className="m-r-5">
          <Checkbox checked={isChecked} onChange={selectNotification} />
        </div>
        <TableLink
          value={tableData.title ? tableData.title : 'N/A'}
        ></TableLink>
      </div>
      <div className="table-row__item">
        <TableLink
          value={
            getFormattedDate(startDate) ? getFormattedDate(startDate) : 'N/A'
          }
        ></TableLink>
      </div>
      <div className="table-row__item">
        <TableLink
          value={getFormattedDate(endDate) ? getFormattedDate(endDate) : 'N/A'}
        ></TableLink>
      </div>
    </div>
  );
};

export default TableRow;
