import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Content = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={650}
    preserveAspectRatio="none"
    style={{ height: '650px' }}
  >
    <rect
      x={0}
      y={5}
      width={getRandomNumberBetween(350, Math.floor(0.95 * width))}
      height={40}
    />
    <rect x={0} y={65} width={getRandomNumberBetween(550, 670)} height={80} />
    <rect
      x={0}
      y={170}
      width={getRandomNumberBetween(500, Math.floor(0.7 * width))}
      height={40}
    />
    <rect x={0} y={235} width={getRandomNumberBetween(400, 670)} height={160} />
    <rect
      x={0}
      y={420}
      width={getRandomNumberBetween(350, Math.floor(0.9 * width))}
      height={80}
    />
    <rect x={0} y={525} width={getRandomNumberBetween(350, 500)} height={40} />
    <rect
      x={0}
      y={590}
      width={getRandomNumberBetween(350, Math.floor(0.95 * width))}
      height={50}
    />
  </ContentLoader>
);

const Loader = () => (
  <Resize>{({ width }) => <Content width={width} />}</Resize>
);

export default Loader;
