import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactComponent as BellIcon } from 'componentsNew/Icon/Bell.svg';
import { ReactComponent as CheckCircleIcon } from 'componentsNew/Icon/CheckCircle.svg';
import { ReactComponent as ExclaimationTriangleIcon } from 'componentsNew/Icon/ExclaimationTriangle.svg';
import { ReactComponent as InformationCircleIcon } from 'componentsNew/Icon/InformationCircle.svg';
import { useMemo } from 'react';

export enum InputHelperTextType {
  Information = 'information',
  Success = 'success',
  Warning = 'warning',
  Critical = 'critical',
}

type InputHelperTextProps = {
  type?: InputHelperTextType;
  children: React.ReactNode | React.ReactNode[];
};

const InputHelperText = ({ type, children }: InputHelperTextProps) => {
  const theme = useTheme();

  const iconSettings: {
    color: string;
    icon: React.ReactElement;
  } | null = useMemo(() => {
    switch (type) {
      case InputHelperTextType.Success:
        return {
          color: theme.colors.text.success,
          icon: <CheckCircleIcon />,
        };
      case InputHelperTextType.Warning:
        return {
          color: theme.colors.text.warning,
          icon: <BellIcon />,
        };
      case InputHelperTextType.Critical:
        return {
          color: theme.colors.text.critical,
          icon: <ExclaimationTriangleIcon />,
        };
      case InputHelperTextType.Information:
        return {
          color: theme.colors.text.informative,
          icon: <InformationCircleIcon />,
        };
      default:
        return null;
    }
  }, [theme, type]);

  if (!iconSettings) {
    return (
      <Typography variant="caption" marginTop={theme.spacing('xxs')}>
        {children}
      </Typography>
    );
  }
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      marginTop={theme.spacing('xxs')}
    >
      <Box
        flexShrink={0}
        lineHeight={1}
        marginRight={theme.spacing('xxs')}
        sx={{ 'svg > path': { stroke: iconSettings.color } }}
      >
        {iconSettings.icon}
      </Box>
      <Typography variant="caption">{children}</Typography>
    </Stack>
  );
};

export { InputHelperText };
