import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { ShortcutTypeName } from 'api/organization/types';
import { useGlobalWebcast } from 'components/Context/GlobalWebcast';
import { Page } from 'layoutNew';
import { PAGE_GRID_GAP } from 'layoutNew/Page';
import { useState } from 'react';
import { translations } from 'translations';

import { Banner } from './Banner/Banner';
import { GlobalWebcast } from './GlobalWebcast/GlobalWebcast';
import { HomeSkeleton } from './HomeSkeleton';
import { InsightsDatabase } from './InsightsDatabase/InsightsDatabase';
import { JobOpenings } from './JobOpenings/JobOpenings';
import { LatestArticles } from './LatestArticles/LatestArticles';
import { MyMoment } from './MyMoment/MyMoment';
import { ProductOfTheWeek } from './ProductOfTheWeek/ProductOfTheWeek';
import { Shortcuts } from './Shortcuts/Shortcuts';
import { Some } from './Some';
import { Stories } from './Stories/Stories';
import { UpdatedLibraryPages } from './UpdatedLibraryPages/UpdatedLibraryPages';

const Home = () => {
  const [locale, setLocale] = useState<string>('en');

  const {
    isGlobalWebcastActivated,
    isGlobalWebcastBlockingHomeFeed,
    isGlobalWebcastIframeMessageLoading,
  } = useGlobalWebcast();

  return (
    <Page title={translations.pageTitleHome} gridContainer>
      {isGlobalWebcastActivated && <GlobalWebcast />}
      {isGlobalWebcastBlockingHomeFeed &&
        isGlobalWebcastIframeMessageLoading && <HomeSkeleton />}
      {!isGlobalWebcastBlockingHomeFeed && (
        <>
          <Grid xs={12}>
            <Banner />
          </Grid>
          <Grid xs={12} md={8}>
            <Typography variant="srOnly" component="h2">
              {translations.articleFeed}
            </Typography>
            <LatestArticles locale={locale} setLocale={setLocale} />
          </Grid>
          <Grid xs={12} md={4}>
            <Stack spacing={PAGE_GRID_GAP}>
              <Shortcuts shortcutType={ShortcutTypeName.Tools} />
              <MyMoment />
              <ProductOfTheWeek />
              <Some />
              <UpdatedLibraryPages />
            </Stack>
          </Grid>
          <Grid xs={12}>
            <Stories locale={locale} />
          </Grid>
          <Grid xs={12} lg={8}>
            <JobOpenings />
          </Grid>
          <Grid xs={12}>
            <InsightsDatabase />
          </Grid>
        </>
      )}
    </Page>
  );
};

export { Home };
