import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { WhitelistValidationLink } from 'componentsNew';
import { ReactComponent as ArrowRightIcon } from 'componentsNew/Icon/ArrowRight.svg';
import { ReactComponent as InformationCircleIcon } from 'componentsNew/Icon/InformationCircle.svg';
import { GAonClickHomeBanner } from 'utils/analytics';

type BannerMediumProps = {
  id: string;
  title?: string;
  description?: string;
  linkUrl?: string;
  linkText?: string;
};

const BannerMedium = ({
  id,
  title,
  description,
  linkUrl,
  linkText,
}: BannerMediumProps) => {
  return (
    <Stack
      id={id}
      sx={(theme) => ({
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        backgroundColor: theme.colors.surface.informative,
        boxShadow: theme.elevation.sm,
      })}
    >
      <Stack
        sx={(theme) => ({
          rowGap: theme.spacing('xxxs'),
          padding: {
            xs: theme.spacing('xs'),
            md: `${theme.spacing('sm')} ${theme.spacing('md')}`,
          },
          ...((title || '').length <= 50 &&
            (description || '').length <= 200 && { maxWidth: '40rem' }),
        })}
      >
        {title && (
          <Typography
            id={`${id}-title`}
            component="h2"
            sx={(theme) => ({
              color: theme.colors.text.brand,
              wordBreak: 'break-word',
              fontWeight: 'bold',
              fontSize: {
                xs: theme.typography.body1.fontSize,
                lg: theme.typography.h3.fontSize,
              },
            })}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            id={`${id}-description`}
            variant="body2"
            sx={(theme) => ({
              color: theme.colors.text.secondary,
              wordBreak: 'break-word',
            })}
          >
            {description}
          </Typography>
        )}
      </Stack>
      {linkUrl ? (
        <WhitelistValidationLink
          id={`${id}-link`}
          href={linkUrl}
          referrerPolicy="no-referrer"
          linkVariant="body2"
          sx={(theme) => ({
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: theme.spacing('sm'),
            rowGap: theme.spacing('xs'),
            padding: { xs: theme.spacing('xs'), md: theme.spacing('md') },
            backgroundColor: theme.colors.surface.primary,
            color: theme.colors.text.actionDefault,
            wordBreak: 'break-word',
            textDecoration: 'none',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '40%',
            flexShrink: 0,
            ':hover': {
              '> *': {
                opacity: 0.9,
              },
            },
          })}
          onClick={() => GAonClickHomeBanner(linkText, HeroBannerSize.Medium)}
        >
          {linkText}
          <Stack
            sx={(theme) => ({
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              height: '3rem',
              width: '3rem',
              borderRadius: '50%',
              backgroundColor: theme.colors.icon.brandBase,
              'svg > path': {
                stroke: theme.colors.text.inversePrimary,
              },
            })}
          >
            <ArrowRightIcon />
          </Stack>
        </WhitelistValidationLink>
      ) : (
        <Stack
          sx={(theme) => ({
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing('md'),
            backgroundColor: theme.colors.surface.primary,
          })}
        >
          <Stack
            sx={(theme) => ({
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              height: '3rem',
              width: '3rem',
              borderRadius: '50%',
              backgroundColor: theme.colors.icon.brandBase,
              'svg > path': {
                stroke: theme.colors.text.inversePrimary,
              },
            })}
          >
            <InformationCircleIcon />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export { BannerMedium };
