import './RecommendedNoJobs.scss';

import { AvenueRoute } from 'constants/routes';
import { Link } from 'react-router-dom';

const RecommendedNojobs = () => {
  return (
    <li className="recommended-no-jobs-list-item">
      <div className="recommended-no-jobs-list-item__content">
        <h3 className="recommended-no-jobs-list-item__content__title">
          Oh no, you are missing out!
        </h3>
        <p className="recommended-no-jobs-list-item__content__category-department">
          We are unable to match roles for your profile since it’s missing some
          information. Please go to Profile settings to complete it.
        </p>
        <div className="recommended-no-jobs-list-item__item-link-wrapper">
          <Link
            to={AvenueRoute.ProfilePreferences}
            className="recommended-no-jobs-list-item__item-link"
          >
            Profile settings
          </Link>
        </div>
      </div>
    </li>
  );
};

export default RecommendedNojobs;
