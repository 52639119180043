export type MenuItemResponse = {
  data: {
    data: Array<{
      _doc: string;
      title: string;
    }>;
  };
};

export type MenuItem = {
  _doc: string;
  title: string;
};

const menuItemsResponseToMenuItems = (response: MenuItemResponse) => {
  const menuItems = response.data.data
    .map((item) => {
      if (!item.title) return null;

      const menuItem: MenuItem = {
        _doc: item._doc,
        title: item.title,
      };
      return menuItem;
    })
    .filter(Boolean) as MenuItem[];
  return menuItems;
};

export { menuItemsResponseToMenuItems };
