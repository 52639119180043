import OnlyWithRole from 'components/OnlyWithRole';
import { AIChatSheet } from 'componentsNew/AIChat/AIChatSheet/AIChatSheet';
import React, { useContext, useMemo, useState } from 'react';

const AIChatContext = React.createContext<{
  setChatOpen: (open: boolean) => void;
} | null>(null);

const { Consumer: AIChatConsumer } = AIChatContext;

type AIChatProviderProps = {
  children: React.ReactNode;
};

export function AIChatProvider({ children }: AIChatProviderProps) {
  const [chatOpen, setChatOpen] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setChatOpen,
    }),
    [setChatOpen]
  );

  return (
    <AIChatContext.Provider value={contextValue}>
      {children}
      <OnlyWithRole viewableByRole="AvenueAIBeta">
        <AIChatSheet open={chatOpen} setOpen={setChatOpen} />
      </OnlyWithRole>
    </AIChatContext.Provider>
  );
}

export const useAIChat = () => {
  const context = useContext(AIChatContext);
  if (!context) {
    throw new Error('AIChatContext is not defined');
  }
  return context;
};

export { AIChatConsumer };
