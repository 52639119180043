import './TableData.scss';

import * as alertApi from 'api/cms/alert';
import { Column } from 'components/Grid';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../Loader/Loader';
import TableHeader from '../TableHeader';
import TableRow from '../TableRow';

class TableData extends Component {
  state = {
    data: [],
    selectedCount: 0,
    isDataEmpty: true,
    selectedNotifications: [],
    isSelectAll: false,
    isLoading: true,
    isSuccess: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.getAlerts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAlerts = async () => {
    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
      const data = await alertApi.getAllAlerts();
      if (data !== undefined && data !== null) {
        this.setState({
          data: data.data.data,
          isLoading: false,
          isSuccess: true,
        });
      } else {
        this.setState({ isLoading: false, isSuccess: false });
      }
    }
  };

  selectNotification = (id) => {
    if (this._isMounted) {
      let { selectedNotifications } = this.state;
      if (selectedNotifications.includes(id)) {
        selectedNotifications = selectedNotifications.filter(
          (notification) => notification !== id
        );
      } else {
        selectedNotifications.push(id);
      }
      this.setState({ selectedNotifications });
    }
  };

  selectAllNotifications = () => {
    if (this._isMounted) {
      let { selectedNotifications, isSelectAll, data } = this.state;
      if (isSelectAll) {
        selectedNotifications = [];
      } else {
        selectedNotifications = data.map((tdData) => tdData.id);
      }
      this.setState({ selectedNotifications, isSelectAll: !isSelectAll });
    }
  };

  setData = (selectedNotifications) => {
    if (this._isMounted) {
      this.setState({
        data: this.state.data.filter(
          (tableData) => !selectedNotifications.includes(tableData.id)
        ),
      });
    }
  };

  resetFunction = () => {
    if (this._isMounted) {
      this.setState({
        isChecked: false,
        selectedNotifications: [],
        isSelectAll: false,
      });
    }
  };

  render() {
    let tableContent;
    let tableData = this.state.data;
    const { isLoading, isSuccess } = this.state;
    if (isLoading) {
      tableContent = <Loader />;
    } else if (!isSuccess) {
      tableContent = <div className="table-message">Error</div>;
    } else if (tableData.length === 0) {
      tableContent = <div className="table-message">No data to show!</div>;
    } else {
      tableContent = tableData.map((tdData) => (
        <TableRow
          key={tdData.id}
          tableData={tdData}
          isChecked={this.state.selectedNotifications.includes(tdData.id)}
          selectNotification={() => this.selectNotification(tdData.id)}
          resetFn={this.resetFunction}
        />
      ));
    }
    return (
      <Column>
        <div className="table-data__add-button m-b-2 m-t-4">
          <Link to="/alert/create" className="table-data__link-to">
            + Add Alert
          </Link>
        </div>
        <div className="table-data">
          <div className="table-data__header">
            <TableHeader
              count={tableData}
              isSelectAll={this.state.isSelectAll}
              selectAllNotifications={this.selectAllNotifications}
              selectedNotifications={this.state.selectedNotifications}
              setData={this.setData}
              isChecked={this.state.selectedNotifications.length > 0}
              resetFn={this.resetFunction}
            />
          </div>
          <div className="table-data__body">
            <div>{tableContent}</div>
          </div>
        </div>
      </Column>
    );
  }
}

export default TableData;
