import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import * as feedbackApi from 'api/feedback';
import { Comment, commentsResponseToComments } from 'api/feedback/transformers';
import * as organizationApi from 'api/organization';
import { useMyMoment } from 'components/Context/MyMoment';
import { TypographyWithMaxLines } from 'componentsNew';
import { ReactComponent as AvatarLarge } from 'componentsNew/Icon/AvatarLarge.svg';
import { PageContentCard, PageContentHeader } from 'layoutNew';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

const elementId = 'home-my-moment';

const MyMoment = () => {
  const [comments, setComments] = useState<Comment[]>([]);

  const [profileImageById, setProfileImageUrlById] = useState<
    Record<string, string>
  >({});

  const theme = useTheme();
  const mountedRef = useRef(true);
  const { myMoment } = useMyMoment();

  const listItemBackgroundColors = useMemo(
    () => [
      theme.palette.primary[700],
      theme.palette.secondary[300],
      theme.palette.secondary[200],
      theme.palette.secondary[100],
      theme.palette.neutral[200],
    ],
    [theme.palette]
  );

  const listItemTextColors = useMemo(
    () => [
      theme.palette.common.white,
      theme.palette.neutral[800],
      theme.palette.neutral[800],
      theme.palette.neutral[800],
      theme.palette.neutral[800],
    ],
    [theme.palette]
  );

  useEffect(() => {
    const fetchCommentsAndProfilePictures = async () => {
      try {
        if (!myMoment?.commentsTargetId) {
          return;
        }
        const response = await feedbackApi.getComments(
          myMoment.commentsTargetId,
          '?page[limit]=5'
        );
        const newComments = commentsResponseToComments(response).filter(
          (comment) => comment.published
        );
        if (!mountedRef) {
          return;
        }
        setComments(newComments);

        const profileIds = newComments
          .map((comment) => comment.personId)
          .filter((value, index, array) => array.indexOf(value) === index);

        const newProfileImageById: Record<string, string> = {};

        await Promise.all(
          profileIds.map(async (profileId) => {
            const response = await organizationApi.getProfile(profileId);
            const imageUrl = response?.data?.data?.attributes?.imageUrl;
            if (imageUrl) {
              newProfileImageById[profileId] = imageUrl;
            }
          })
        );
        if (!mountedRef) {
          return;
        }
        setProfileImageUrlById(newProfileImageById);
      } catch (e) {}
    };
    fetchCommentsAndProfilePictures();
  }, [myMoment?.commentsTargetId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (!myMoment || !comments.length) {
    return null;
  }

  return (
    <PageContentCard>
      <PageContentHeader
        text={translations.myMomentLatest}
        sx={{ borderBottom: 'none' }}
      />
      <List>
        {comments.map((comment, index) => (
          <ListItemButton
            key={comment.id}
            id={`${elementId}-item-${index}`}
            to={`/my-moment?targetId=${comment.id}`}
            component={ReactRouterLink}
            sx={(theme) => ({
              minHeight: '4rem',
              padding: `${theme.spacing('xs')} ${theme.spacing('sm')}`,
              paddingRight: '4.5rem',
              backgroundColor: listItemBackgroundColors[index],
              color: listItemTextColors[index],
              overflow: 'hidden',
              '&:hover': {
                backgroundColor: `${listItemBackgroundColors[index]}`,
                filter: 'brightness(95%)',
              },
              '&:focus': {
                backgroundColor: listItemBackgroundColors[index],
                outline: `1px solid ${theme.colors.border.focus}`,
                boxShadow: `0px 0px 0px 3px ${theme.colors.border.surfaceInformative}`,
                zIndex: 2,
              },
            })}
          >
            <TypographyWithMaxLines maxLines={2} variant="body2">
              {comment.text}
            </TypographyWithMaxLines>

            {profileImageById[comment.personId] ? (
              <Avatar
                id={`${elementId}-item-${index}-avatar`}
                src={profileImageById[comment.personId]}
                alt={translations.profileAvatarAlt}
                sx={(theme) => ({
                  position: 'absolute',
                  right: '-0.75rem',
                  top: '0.25rem',
                  height: '4.5rem',
                  width: '4.5rem',
                  filter: 'grayscale(100%)',
                  border: `2px solid ${theme.palette.common.white}`,
                })}
              />
            ) : (
              <Stack
                sx={() => ({
                  position: 'absolute',
                  right: '-0.75rem',
                  top: '0.25rem',
                  height: '4.5rem',
                  width: '4.5rem',
                  border: `2px solid ${theme.palette.common.white}`,
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.neutral[300],
                })}
              >
                <AvatarLarge height="3rem" width="3rem" />
              </Stack>
            )}
          </ListItemButton>
        ))}
      </List>
      <Button
        id={`${elementId}-read-more`}
        variant="linkButton"
        to="/my-moment"
        component={ReactRouterLink}
        sx={(theme) => ({ float: 'right', marginTop: theme.spacing('sm') })}
      >
        {translations.myMomentReadMore}
      </Button>
    </PageContentCard>
  );
};

export { MyMoment };
