import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { DropdownMenu, Snackbar } from 'componentsNew';
import { ReactComponent as ArrowUTurnRightIcon } from 'componentsNew/Icon/ArrowUTurnRight.svg';
import { ReactComponent as CopyIcon } from 'componentsNew/Icon/Copy.svg';
import { ReactComponent as SendIcon } from 'componentsNew/Icon/Send.svg';
import copyToClipboard from 'copy-to-clipboard';
import { useState } from 'react';
import { translations } from 'translations';

type ShareButtonProps = {
  id: string;
  sx?: SxProps<Theme>;
  copyLink: string;
  emailLink: string;
  onCopyLinkClick?: () => void;
  onSendEmailClick?: () => void;
};

const ShareButton = ({
  id,
  sx,
  copyLink,
  emailLink,
  onCopyLinkClick,
  onSendEmailClick,
}: ShareButtonProps) => {
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const [copyLinkConfirmationOpen, setCopyLinkConfirmationOpen] =
    useState<boolean>(false);

  const [sendEmailConfirmationOpen, setSendEmailConfirmationOpen] =
    useState<boolean>(false);

  return (
    <>
      <Snackbar
        type="success"
        open={copyLinkConfirmationOpen}
        text={translations.shareMenuCopyLinkConfirmation}
        autoHideDuration={3000}
        onClose={(_e, reason) => {
          if (reason === 'clickaway') return;
          setCopyLinkConfirmationOpen(false);
        }}
      />
      <Snackbar
        type="success"
        open={sendEmailConfirmationOpen}
        text={translations.shareMenuSendEmailConfirmation}
        autoHideDuration={3000}
        onClose={(_e, reason) => {
          if (reason === 'clickaway') return;
          setSendEmailConfirmationOpen(false);
        }}
      />
      <Link
        id={id}
        component="button"
        onClick={(e) => setMenuAnchorElement(e.currentTarget)}
        sx={[
          (theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing('xxxs'),
            typography: theme.typography.caption,
            textDecoration: 'none',
            '> svg > path': {
              fill: theme.colors.icon.brandBase,
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <ArrowUTurnRightIcon height={20} width={20} />
        {translations.share}
      </Link>
      <DropdownMenu
        id={`${id}-menu`}
        placement="top"
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={() => setMenuAnchorElement(null)}
        items={[
          {
            text: translations.shareMenuCopyLink,
            startIcon: <CopyIcon height={20} width={20} />,
            onClick: () => {
              copyToClipboard(copyLink);
              setCopyLinkConfirmationOpen(true);
              setMenuAnchorElement(null);
              onCopyLinkClick && onCopyLinkClick();
            },
          },
          {
            text: translations.shareMenuSendEmail,
            startIcon: <SendIcon height={20} width={20} />,
            href: emailLink,
            onClick: () => {
              setSendEmailConfirmationOpen(true);
              setMenuAnchorElement(null);
              onSendEmailClick && onSendEmailClick();
            },
          },
        ]}
      />
    </>
  );
};

export { ShareButton };
