import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { ReactComponent as BellAlertFilledIcon } from 'componentsNew/Icon/BellAlertFilled.svg';
import { ReactComponent as CheckCircleFilledIcon } from 'componentsNew/Icon/CheckCircleFilled.svg';
import { ReactComponent as CrossIcon } from 'componentsNew/Icon/Cross.svg';
import { ReactComponent as ExclaimationTriangleFilledIcon } from 'componentsNew/Icon/ExclaimationTriangleFilled.svg';
import { ReactComponent as InformationCircleFilledIcon } from 'componentsNew/Icon/InformationCircleFilled.svg';
import React from 'react';
import { useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

export enum AlertBarType {
  Information = 'information',
  Success = 'success',
  Warning = 'warning',
  Critical = 'critical',
}

export enum AlertBarSize {
  Small = 'small',
  Large = 'large',
}

type AlertBarProps = {
  open: boolean;
  type?: AlertBarType;
  size?: AlertBarSize;
  snackbar?: boolean;
  snackbarAutoHideDuration?: number;
  title?: string;
  text?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
};

const AlertBar = React.forwardRef(
  (
    {
      open,
      type = AlertBarType.Information,
      size = AlertBarSize.Small,
      snackbar,
      snackbarAutoHideDuration = 5000,
      title,
      text,
      icon,
      fullWidth,
      sx,
      children,
      onClose,
    }: AlertBarProps,
    ref
  ) => {
    const theme = useTheme();

    const typeSettings: {
      title: string;
      icon: React.ReactNode;
      backgroundColor: string;
      accentColor: string;
    } = useMemo(() => {
      switch (type) {
        case AlertBarType.Success:
          return {
            title: title ? title : translations.successful,
            icon: icon || <CheckCircleFilledIcon />,
            backgroundColor: theme.colors.surface.success,
            accentColor: theme.colors.icon.success,
          };
        case AlertBarType.Warning:
          return {
            title: title ? title : translations.warning,
            icon: icon || <BellAlertFilledIcon />,
            backgroundColor: theme.colors.surface.warning,
            accentColor: theme.colors.icon.warning,
          };
        case AlertBarType.Critical:
          return {
            title: title ? title : translations.error,
            icon: icon || <ExclaimationTriangleFilledIcon />,
            backgroundColor: theme.colors.surface.critical,
            accentColor: theme.colors.icon.critical,
          };
        default:
          return {
            title: title ? title : translations.information,
            icon: icon || <InformationCircleFilledIcon />,
            backgroundColor: theme.colors.surface.informative,
            accentColor: theme.colors.icon.brandBase,
          };
      }
    }, [type, title, icon, theme.colors]);

    const sizeSettings: {
      typographyTitle: CSSProperties;
      typographyText: CSSProperties;
      iconSize: string;
    } = useMemo(() => {
      switch (size) {
        case AlertBarSize.Large:
          return {
            typographyTitle: theme.typography.body1,
            typographyText: theme.typography.body2,
            iconSize: '32px',
          };
        default:
          return {
            typographyTitle: theme.typography.body2,
            typographyText: theme.typography.body2,
            iconSize: '24px',
          };
      }
    }, [size, theme.typography.body1, theme.typography.body2]);

    const alertBarElement = useMemo(
      () => (
        <Box
          ref={ref}
          sx={[
            () => ({
              backgroundColor: typeSettings.accentColor,
              paddingLeft: '0.25rem',
              borderRadius: '0.5rem',
              width: fullWidth ? '100%' : { xs: '100%', sm: 'fit-content' },
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              columnGap: theme.spacing('xs'),
              padding: theme.spacing('xs'),
              borderRadius: '0.25rem',
              backgroundColor: typeSettings.backgroundColor,
              textAlign: 'start',
            })}
          >
            <Box
              sx={{
                flexShrink: 0,
                svg: {
                  path: { fill: typeSettings.accentColor },
                  height: sizeSettings.iconSize,
                  width: sizeSettings.iconSize,
                },
              }}
            >
              {typeSettings.icon}
            </Box>

            <Stack>
              {title && (
                <Typography
                  sx={(theme) => ({
                    typography: sizeSettings.typographyTitle,
                    color: theme.colors.text.primary,
                    wordBreak: 'break-word',
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  })}
                >
                  {title}
                </Typography>
              )}
              {text && (
                <Typography
                  sx={(theme) => ({
                    typography: sizeSettings.typographyText,
                    color: Boolean(title)
                      ? theme.colors.text.secondary
                      : theme.colors.text.primary,
                    wordBreak: 'break-word',
                    lineHeight: 1.5,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  })}
                >
                  {text}
                </Typography>
              )}
              {children}
            </Stack>
            {onClose && (
              <IconButton
                size="small"
                aria-label={translations.close}
                onClick={onClose}
                sx={{
                  alignSelf: 'baseline',
                  marginLeft: 'auto',
                  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                    backgroundColor: 'unset',
                  },
                }}
              >
                <CrossIcon height={12} width={12} />
              </IconButton>
            )}
          </Stack>
        </Box>
      ),
      [
        children,
        fullWidth,
        onClose,
        ref,
        sizeSettings,
        sx,
        text,
        title,
        typeSettings,
      ]
    );

    if (snackbar) {
      return (
        <Snackbar
          open={open}
          onClose={onClose}
          autoHideDuration={snackbarAutoHideDuration}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          sx={(theme) => ({
            margin: { xs: theme.spacing('xxs'), sm: 'unset' },
          })}
        >
          {alertBarElement}
        </Snackbar>
      );
    }
    return (
      <TransitionGroup>
        {open && <Collapse>{alertBarElement}</Collapse>}
      </TransitionGroup>
    );
  }
);

export { AlertBar };
