import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getPositions, headers } from 'api/jobs';
import { JobOpening } from 'api/jobs/types';
import { UserContext } from 'components/Context/User';
import { LinkList } from 'componentsNew';
import type { LinkListItem } from 'componentsNew/LinkList';
import { PageContentHeader } from 'layoutNew';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonClickHomeJobsItem,
  GAonClickHomeJobsReadMore,
} from 'utils/analytics';
import { loadData } from 'utils/api/handleAPIData';
import makeQueryString from 'utils/misc/makeQueryString';

import { JobOpeningsNoJobs } from './JobOpeningsNojobs';
import { JobOpeningsSkeleton } from './JobOpeningsSkeleton';

const elementId = 'home-job-openings';

const injectDepartmentCountry = (
  departmentName: string,
  countryName: string,
  limit: number
) =>
  makeQueryString({
    filter: {
      categoryname: departmentName,
      locationname: countryName,
    },
    page: {
      limit,
    },
  });

const getHiringManager = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  if (!lastName && !firstName) return 'N/A';
  if (lastName && !firstName) return lastName;
  if (!lastName && firstName) return firstName;
  return `${firstName} ${lastName}`;
};

const JobOpenings = () => {
  const [jobOpeningsData, setjobOpeningsData] = useState<JobOpening[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const context = useContext(UserContext);
  const mountedRef = useRef<boolean>(true);

  const fetchJobOpenings = useCallback(async () => {
    const { departmentName, originalCountryName, firstLoad } = context;
    setIsLoading(true);

    try {
      if (!firstLoad) {
        const response = await loadData({
          apiCall: getPositions,
          reducer: undefined,
          headers: headers,
          param: injectDepartmentCountry(
            departmentName,
            originalCountryName,
            3
          ),
          createLoading: undefined,
          dataState: undefined,
          useModelFactory: undefined,
        }).promise;

        const jobOpenings = response.response as JobOpening[];
        if (!mountedRef.current) return;
        setjobOpeningsData(jobOpenings);
      }
    } finally {
      if (!mountedRef.current) return;
      if (firstLoad) return;
      setIsLoading(false);
    }
  }, [context]);

  useEffect(() => {
    fetchJobOpenings();
  }, [fetchJobOpenings]);

  const items = useMemo(() => {
    return jobOpeningsData
      .map((jobOpening) => {
        if (!jobOpening.id || !jobOpening.name) return null;

        const categoryAndDepartment =
          jobOpening.category?.name && jobOpening.department?.name
            ? `${jobOpening.category.name} ${jobOpening.department.name}`
            : 'N/A';

        const hiringManager = `${translations.hiringManager} ${getHiringManager(
          jobOpening.hiringManager?.firstName,
          jobOpening.hiringManager?.lastName
        )}`;

        const item: LinkListItem = {
          id: jobOpening.id,
          title: jobOpening.name,
          descriptions: [categoryAndDepartment],
          subDescriptions: [hiringManager],
          to: `jobs/${jobOpening.id}`,
          divider: true,
          element: (
            <Box>
              {jobOpening.locations &&
                jobOpening.locations.length &&
                jobOpening.locations.map(
                  (location, index) =>
                    index < 3 && (
                      <Typography
                        key={index}
                        variant="body2"
                        sx={(theme) => ({
                          color: theme.colors.text.tertiary,
                          textAlign: 'right',
                        })}
                      >
                        {location.name}
                      </Typography>
                    )
                )}
            </Box>
          ),
        };
        return item;
      })
      .filter(Boolean) as LinkListItem[];
  }, [jobOpeningsData]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <>
        <PageContentHeader text={translations.jobOpeningsTitle} />
        <JobOpeningsSkeleton />
      </>
    );
  }

  return (
    <>
      <PageContentHeader text={translations.jobOpeningsTitle} />

      {jobOpeningsData.length < 1 ? (
        <JobOpeningsNoJobs />
      ) : (
        <LinkList
          id={elementId}
          items={items}
          onClick={(item) => GAonClickHomeJobsItem(item.title)}
        />
      )}
      <Button
        id={`${elementId}-read-more`}
        to="jobs"
        variant="linkButton"
        component={ReactRouterLink}
        onClick={GAonClickHomeJobsReadMore}
        sx={(theme) => ({
          marginTop: theme.spacing('xs'),
          float: 'right',
          lineHeight: 0.875,
        })}
      >
        {translations.allJobs}
      </Button>
    </>
  );
};

export { JobOpenings };
