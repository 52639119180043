import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import Box from 'components/Box';
import { HeroBannerConsumer } from 'components/Context/HeroBanner';
import Text from 'components/Editable/Text';
import { Column, Container } from 'components/Grid';
import Hero from 'components/Hero';
import OnlyWithRole from 'components/OnlyWithRole';
import PageTitle from 'components/PageTitle';
import PublishSelector from 'components/PublishSelector';
import Radio from 'components/Radio';
import { BannerLarge } from 'pagesNew/Home/Banner/BannerLarge';
import { BannerMedium } from 'pagesNew/Home/Banner/BannerMedium';
import { Component } from 'react';
import { getImageSrc } from 'utils/cms';

import FormButtons from './components/FormButtons';
import Loader from './components/Loader';
import OverrideDivisionalMessage from './components/OverrideDivisionalMessage';

class HeroBannerEdit extends Component {
  onChangeText = (onChangeText) => (preamble) => {
    onChangeText({ preamble });
  };

  render() {
    const isEditing = this.props.match.params.type === 'edit';

    return (
      <>
        <OnlyWithRole viewableByRole="AvenueSuperuser">
          <PageTitle titles={['Hero Banner', isEditing ? 'Edit' : 'Create']} />
          <Container>
            <Column>
              <Box>
                <HeroBannerConsumer>
                  {({
                    isLoading,
                    onChangeDivision,
                    onChange,
                    onCancel,
                    onDelete,
                    onPublish,
                    changeSizeValue,
                    changeTextValue,
                    changeLinkValue,
                    changeLinkButtonValue,
                    changeDescriptionValue,
                    getDefaultValue,
                    onChangeImage,
                    DEFAULT_DIVISION_NAME,
                    size,
                    title,
                    description,
                    linkUrl,
                    linkButtonText,
                    id,
                    values,
                    heroImage,
                    heroAltText,
                    updatedCheckBox,
                    updatedHero,
                  }) => {
                    if (isLoading) {
                      return <Loader />;
                    }
                    return (
                      <div>
                        <div className="m-b-6">
                          <p>Choose banner size</p>
                          <Radio
                            checked={size === HeroBannerSize.Medium}
                            id="hero-banner-size-input-0"
                            onChange={(e) => changeSizeValue(e.value)}
                            label="Medium"
                            value={HeroBannerSize.Medium}
                            name="hero-banner-size"
                          />
                          <Radio
                            checked={size === HeroBannerSize.Large}
                            id="hero-banner-size-input-1"
                            onChange={(e) => changeSizeValue(e.value)}
                            label="Large"
                            value={HeroBannerSize.Large}
                            name="hero-banner-size"
                          />
                        </div>
                        <div>
                          <Text
                            wrapper={{
                              element: 'div',
                              className: 'preamble',
                            }}
                            content={title}
                            onBlur={onChange(changeTextValue)}
                            placeholder="Banner title (max 60 characters)"
                            maxLength={60}
                            onChange={changeTextValue}
                            key={`title${id}`}
                          />
                        </div>
                        <div>
                          <Text
                            wrapper={{
                              element: 'div',
                              className: 'preamble description',
                            }}
                            content={description}
                            onBlur={onChange(changeDescriptionValue)}
                            placeholder="Description (max 270 characters)"
                            maxLength={270}
                            onChange={changeDescriptionValue}
                            key={`description${id}`}
                          />
                        </div>
                        <div>
                          <Text
                            wrapper={{
                              element: 'div',
                              className: 'preamble',
                            }}
                            content={linkButtonText}
                            onBlur={onChange(changeLinkButtonValue)}
                            placeholder="Link Button Text"
                            maxLength={20}
                            onChange={changeLinkButtonValue}
                            key={`linkButtonText${id}`}
                          />
                        </div>
                        <div>
                          <Text
                            wrapper={{
                              element: 'div',
                              className: 'preamble',
                            }}
                            content={linkUrl}
                            onBlur={onChange(changeLinkValue)}
                            placeholder="Link"
                            maxLength={500}
                            onChange={changeLinkValue}
                            key={`linkUrl${id}`}
                          />
                        </div>
                        {size === HeroBannerSize.Large && (
                          <div>
                            <Hero
                              useVideo={false}
                              useAltText={true}
                              onChange={onChangeImage}
                              key={`hero${id}`}
                              aspectRatio={25 / 15.75}
                              heroImage={getImageSrc(heroImage)}
                              heroAltText={heroAltText}
                            />
                          </div>
                        )}

                        <hr className="m-t-6 m-b-6" />
                        <div>
                          <p>Select where the items should be published</p>
                          <PublishSelector
                            data={values}
                            onChange={onChange(onChangeDivision)}
                            publishWithCountry={false}
                            publishWithDepartment={false}
                            publishWithSite={false}
                            publishWithCompany={false}
                            defaultDivisionName={DEFAULT_DIVISION_NAME}
                            defaultValue={getDefaultValue(
                              values.publishedInGlobal,
                              values.publishedInDivisions,
                              values.publishedInCountries
                            )}
                            key={`publishedSelector${id}`}
                          />
                          <hr className="m-t-6 m-b-6" />
                          <OverrideDivisionalMessage
                            defaultChecked={updatedCheckBox}
                            key={`checked${id}`}
                          />
                        </div>
                        <div className="m-b-8">
                          <p>Preview</p>
                          {size === HeroBannerSize.Medium ? (
                            <BannerMedium
                              title={title}
                              description={description}
                              linkText={linkButtonText}
                              linkUrl={linkUrl}
                            />
                          ) : (
                            <BannerLarge
                              title={title}
                              description={description}
                              linkText={linkButtonText}
                              linkUrl={linkUrl}
                              imageUrl={
                                updatedHero?.heroImage?.file || heroImage
                              }
                            />
                          )}
                        </div>
                        <div>
                          <FormButtons
                            submit={onPublish}
                            cancel={onCancel}
                            onDelete={isEditing ? onDelete : undefined}
                          />
                        </div>
                      </div>
                    );
                  }}
                </HeroBannerConsumer>
              </Box>
            </Column>
          </Container>
        </OnlyWithRole>
      </>
    );
  }
}

export default HeroBannerEdit;
