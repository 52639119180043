import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Parser from 'components/Parser';
import { ReactComponent as ExclaimationTriangle } from 'componentsNew/Icon/ExclaimationTriangle.svg';
import { ReactComponent as XMark } from 'componentsNew/Icon/XMark.svg';
import { useAlerts } from 'contextNew/Alerts';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

const elementId = 'alerts';

const Alerts = () => {
  const { visibleAlerts, setVisibleAlerts } = useAlerts();

  return (
    <List
      id={elementId}
      sx={(theme) => ({
        '> div': {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing('xxs'),
          backgroundColor: theme.colors.surface.secondary,
        },
      })}
    >
      <TransitionGroup>
        {visibleAlerts.map((alert, index) => (
          <Collapse
            key={alert.id}
            sx={(theme) => ({
              marginLeft: {
                xs: theme.spacing('sm'),
                sm: theme.spacing('md'),
                xl: theme.spacing('sm'),
              },
              marginRight: {
                xs: theme.spacing('sm'),
                sm: theme.spacing('md'),
                xl: theme.spacing('sm'),
              },
              marginTop: index === 0 ? theme.spacing('xs') : 0,
              marginBottom:
                index === visibleAlerts.length - 1 ? theme.spacing('xs') : 0,
            })}
          >
            <ListItem
              id={`${elementId}-${index}`}
              sx={(theme) => ({
                padding: theme.spacing('xxs'),
                boxShadow: theme.elevation.sm,
                borderRadius: theme.border.radius.md,
                backgroundColor: theme.colors.surface.warningDark,
                gap: theme.spacing('xxs'),
                alignItems: 'flex-start',
              })}
            >
              <Stack
                sx={(theme) => ({
                  padding: theme.spacing('xxs'),
                  backgroundColor: theme.colors.surface.primary,
                  border: `1px solid ${theme.colors.border.surfaceWarning}`,
                  borderRadius: theme.border.radius.lg,
                  '> svg > path': { stroke: theme.colors.icon.primary },
                })}
              >
                <ExclaimationTriangle height={20} width={20} />
              </Stack>
              <Stack
                sx={(theme) => ({
                  flexWrap: 'wrap',
                  alignSelf: 'center',
                  flexDirection: 'row',
                  gap: theme.spacing('xxxxs'),
                  typography: theme.typography.body2,
                  color: theme.colors.text.warning,
                  '.md-inline-link': {
                    color: theme.colors.text.warning,
                  },
                })}
              >
                <b>{alert.title}</b>
                <Parser html={alert.notification} type="Alert" />
              </Stack>
              <IconButton
                id={`${elementId}-close`}
                size="medium"
                aria-label={translations.close}
                sx={(theme) => ({
                  marginLeft: 'auto',
                  flexGrow: 0,
                  '> svg > path': { fill: theme.colors.icon.primary },
                })}
                onClick={() =>
                  setVisibleAlerts((prevAlerts) => [
                    ...prevAlerts.filter(
                      (prevAlert) => prevAlert.id !== alert.id
                    ),
                  ])
                }
              >
                <XMark height={20} width={20} />
              </IconButton>
            </ListItem>
          </Collapse>
        ))}
      </TransitionGroup>
    </List>
  );
};

export { Alerts };
