import { getRedirects } from 'api/redirects';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

interface RedirectsContextType {
  redirectAll: RedirectsItem[];
  redirectExact: RedirectsItem[];
  isLoading: boolean;
}

interface RedirectsItem {
  from: string;
  to: string;
}

type RedirectsProps = {
  children: React.ReactNode;
};

const RedirectsContext = createContext<RedirectsContextType | null>(null);
const { Consumer: RedirectsConsumer } = RedirectsContext;

export function RedirectsProvider({ children }: RedirectsProps) {
  const [context, setContext] = useState<RedirectsContextType>({
    redirectAll: [],
    redirectExact: [],
    isLoading: true,
  });

  const mountedRef = useRef(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await getRedirects();
        if (response && response.status === 200) {
          if (!mountedRef.current) return;

          setContext({
            redirectAll: response.data.data.redirectAll,
            redirectExact: response.data.data.redirectExact,
            isLoading: false,
          });
        }
      } catch (e) {
        if (!mountedRef.current) return;
        setContext({
          redirectAll: [],
          redirectExact: [],
          isLoading: false,
        });
      }
    })();
  }, []);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <RedirectsContext.Provider value={context}>
      {children}
    </RedirectsContext.Provider>
  );
}

export const useRedirects = () => {
  const context = useContext(RedirectsContext);
  if (!context) {
    throw new Error('Redirects is not defined');
  }
  return context;
};

export { RedirectsConsumer };
