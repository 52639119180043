import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const NewsletterBannerFormSkeleton = () => {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <Stack
          key={`newsletter-banner-form-skeleton-${index}`}
          sx={(theme) => ({ gap: theme.spacing('xs') })}
        >
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ height: '1.7rem', marginBottom: '0.5rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ height: '2.5rem', marginBottom: '0.5rem' }}
          />
        </Stack>
      ))}
      <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ height: '1.7rem', marginBottom: '0.5rem' }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ height: '30rem', marginBottom: '0.5rem' }}
        />
      </Stack>
      <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ height: '1.7rem', marginBottom: '0.5rem' }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ height: '2.5rem', marginBottom: '0.5rem' }}
        />
      </Stack>
    </>
  );
};

export { NewsletterBannerFormSkeleton };
