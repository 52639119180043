import './BodyBox.scss';

import { getInsightsArticles } from 'api/cms/portals/';
import classNames from 'classnames';
import Button from 'components/Button';
import Center from 'components/Center';
import CommentLikeSummary from 'components/Comments/CommentLikeSummary';
import { UserConsumer } from 'components/Context/User';
import EnhancedLink from 'components/EnhancedLink';
import Fetcher from 'components/Fetcher';
import Image from 'components/Image';
import TopicsBox from 'components/Portal/InsightsDatabase/TopicsBox';
import TextMediaBoxList from 'components/Portal/TextMediaBox/List';
import { Desktop, Mobile } from 'components/Responsive';
import SortingControl from 'components/SortingControl';
import { TypographyWithMaxLines } from 'componentsNew';
import PropTypes from 'prop-types';
import { Component } from 'react';
import TextClamp from 'react-string-clamp';
import animateScroll from 'utils/misc/animateScroll';
import { getDateOrDaysAgo } from 'utils/misc/time';

class InsightsDatabaseBodyBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchRequest: props.searchRequest,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchRequest !== this.props.searchRequest) {
      this.setState({ searchRequest: this.props.searchRequest });
    }
  }

  filterHandler = (value) => {
    if (
      value === 'all' ||
      value === 'Smart homes' ||
      value === 'Internet of Things'
    ) {
      this.props.showAll({
        type: 'search',
        value: value === 'all' ? '' : value,
      });
    } else {
      this.props.setFilter(value);
    }
  };

  render() {
    const {
      color,
      className,
      isInitialState,
      smartHomesRequest,
      internetOfThingsRequest,
      page,
      sortOptions,
      handleSortChange,
      selectedSortOption,
    } = this.props;
    const { searchRequest } = this.state;
    const useLoadMore = !isInitialState;
    const bodyBoxClassName = classNames('insights-database-body-box', {
      [className]: className,
      [`insights-database-body-box--${color}`]: typeof color !== 'undefined',
    });
    return (
      <div>
        <div className={bodyBoxClassName}>
          <TopicsBox
            setFilter={this.filterHandler}
            isInitialState={isInitialState}
            smartHomesRequest={smartHomesRequest}
            internetOfThingsRequest={internetOfThingsRequest}
          />
        </div>
        {isInitialState && <TextMediaBoxList page={page} />}
        <div className={bodyBoxClassName}>
          <div className="insights-database-body-box__news-box">
            {searchRequest && (
              <Fetcher
                source={getInsightsArticles}
                param={searchRequest}
                useLoadMore={useLoadMore}
              >
                {({ response, meta, links, loadMore, isLoading }) =>
                  meta.total >= 0 && (
                    <>
                      {meta.total === 0 && 'No search results'}
                      {meta.total > 0 && (
                        <>
                          {isInitialState && (
                            <div className="insights-database-body-box__news-top-panel">
                              <span className="insights-database-body-box__news-title">
                                Latest Insights
                              </span>
                              <span
                                className="insights-database-body-box__news-all"
                                onClick={() => {
                                  this.filterHandler('all');
                                }}
                              >
                                View all
                              </span>
                            </div>
                          )}
                          {!isInitialState && (
                            <div className="insights-database-body-box__news-top-panel">
                              <span className="insights-database-body-box__news-count">
                                <span className="insights-database-body-box__result-wrapper">
                                  Results: ({meta.total})
                                </span>{' '}
                                <div className="insights-database-body-box__divider"></div>
                                <div className="insights-database-body-box__sorting-wrapper">
                                  <SortingControl
                                    className="insights-sorting"
                                    sortOptions={sortOptions}
                                    selectedSortOption={selectedSortOption}
                                    handleSortChange={handleSortChange}
                                  />
                                </div>
                              </span>
                            </div>
                          )}
                          <ul className="insights-database-body-box__news-list">
                            {response.map((item) => (
                              <li
                                className="insights-database-body-box__news-item"
                                key={item.id}
                              >
                                <div className="insights-database-body-box__news-inner">
                                  <div className="insights-database-body-box__news-content">
                                    <div className="insights-database-body-box__news-date">
                                      {getDateOrDaysAgo(item.published, false)}
                                    </div>
                                    <Mobile>
                                      {item.imageUrl && (
                                        <EnhancedLink
                                          className="insights-database-body-box__news-link"
                                          to={item.linkUrl}
                                        >
                                          <div className="insights-database-body-box__news-image-holder">
                                            <Image
                                              src={`${item.imageUrl}/450`}
                                              alt={item.imageAltText}
                                              className="insights-database-body-box__news-image"
                                            />
                                          </div>
                                        </EnhancedLink>
                                      )}
                                    </Mobile>
                                    <EnhancedLink
                                      className="insights-database-body-box__news-link"
                                      to={item.linkUrl}
                                    >
                                      <TextClamp
                                        className="insights-database-body-box__news-subtitle"
                                        text={item.title}
                                        lines={2}
                                      />
                                    </EnhancedLink>
                                    {item.summary && (
                                      <div className="insights-database-body-box__news-text">
                                        <TypographyWithMaxLines maxLines={2}>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: item.summary,
                                            }}
                                          />
                                        </TypographyWithMaxLines>
                                      </div>
                                    )}
                                    <div className="insights-database-body-box__news-type-likes-comments">
                                      <div className="insights-database-body-box__news-type">
                                        {item.types &&
                                          item.types.map((type) => (
                                            <span key={type.id}>
                                              {type.title}
                                            </span>
                                          ))}
                                      </div>
                                      <UserConsumer>
                                        {(userContext) => {
                                          return (
                                            <div className="insights-database-body-box__news-likes-and-comments">
                                              <CommentLikeSummary
                                                id={item.id}
                                                title={item.title}
                                                type={item.type}
                                                loggedInUserId={
                                                  userContext.userId
                                                }
                                                article={item}
                                              />
                                            </div>
                                          );
                                        }}
                                      </UserConsumer>
                                    </div>
                                  </div>
                                  <Desktop>
                                    {item.imageUrl && (
                                      <EnhancedLink
                                        className="insights-database-body-box__news-link"
                                        to={item.linkUrl}
                                      >
                                        <div className="insights-database-body-box__news-image-holder">
                                          <Image
                                            src={`${item.imageUrl}/250`}
                                            alt={item.imageAltText}
                                            className="insights-database-body-box__news-image"
                                          />
                                        </div>
                                      </EnhancedLink>
                                    )}
                                  </Desktop>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {isInitialState && (
                            <span
                              className="insights-database-body-box__news-all-mobile"
                              onClick={() => {
                                this.filterHandler('all');
                              }}
                            >
                              View all
                            </span>
                          )}
                          {useLoadMore && links && links.next && (
                            <Center className="m-t-4 m-b-4">
                              <Button
                                onClick={() => {
                                  animateScroll(220, 50);
                                  loadMore();
                                }}
                                disabled={isLoading}
                                color="black"
                              >
                                Load more
                              </Button>
                            </Center>
                          )}
                        </>
                      )}
                    </>
                  )
                }
              </Fetcher>
            )}
          </div>
        </div>
      </div>
    );
  }
}

InsightsDatabaseBodyBox.propTypes = {
  searchRequest: PropTypes.string,
  smartHomesRequest: PropTypes.string,
  internetOfThingsRequest: PropTypes.string,
  isInitialState: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'blue']),
  className: PropTypes.string,
};

InsightsDatabaseBodyBox.defaultProps = {
  color: 'white',
};

export default InsightsDatabaseBodyBox;
