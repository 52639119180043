import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import * as organizationApi from 'api/organization';
import { useUser } from 'components/Context/User';
import { AlertBar, FormFieldWrapper } from 'componentsNew';
import { AlertBarType } from 'componentsNew/AlertBar';
import { useCallback, useEffect, useRef, useState } from 'react';
import { translations } from 'translations';
import { RequestStatus } from 'types';
import { GAonClickProfileSubscriptionsSave } from 'utils/analytics';

import * as helpers from './helpers';
import { SubscriptionsForm } from './helpers';
import { SubscriptionsSkeleton } from './SubscriptionsSkeleton';

const elementId = 'profile-subscriptions';

const Subscriptions = () => {
  const [formInput, setFormInput] = useState<SubscriptionsForm>(
    helpers.getInitialFormInput()
  );
  const [updateProfileStatus, setUpdateProfileStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  );

  const user = useUser();
  const theme = useTheme();
  const mountedRef = useRef<boolean>(true);

  const onSubscribeToInsightsDatabaseChange = useCallback(
    async (value: boolean) => {
      setUpdateProfileStatus(RequestStatus.Loading);
      setFormInput({ ...formInput, subscribesToInsightsDatabase: value });
      GAonClickProfileSubscriptionsSave('subscribesToInsightsDatabase', value);
      try {
        await organizationApi.updateProfileSettings(user.userId, {}, value);
      } catch {
        if (!mountedRef.current) return;
        setFormInput({ ...formInput, subscribesToInsightsDatabase: !value });
        setUpdateProfileStatus(RequestStatus.Failure);
        return;
      }
      try {
        await user.fetchUser();
      } catch {
      } finally {
        if (!mountedRef.current) return;
        setUpdateProfileStatus(RequestStatus.Success);
      }
    },
    [formInput, user]
  );

  useEffect(() => {
    if (user.firstLoad) return;
    setFormInput(helpers.getInitialFormInput(user));
  }, [user]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (user.firstLoad) {
    return <SubscriptionsSkeleton />;
  }

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing('md'),
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Grid
        container
        id={elementId}
        spacing={theme.spacing('md')}
        flexWrap="wrap-reverse"
      >
        <Grid xs={12} md={7}>
          <Stack sx={(theme) => ({ rowGap: theme.spacing('sm') })}>
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: theme.spacing('md'),
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.colors.text.tertiary,
                  fontWeight: 'bold',
                })}
              >
                {translations.profileSubscriptionsInsightsDatabase}
              </Typography>
              <FormFieldWrapper
                id={`${elementId}-subscribesToInsightsDatabase`}
                label={translations.profileSubscriptionsInsightsDatabase}
              >
                <Switch
                  checked={formInput.subscribesToInsightsDatabase}
                  disabled={updateProfileStatus === RequestStatus.Loading}
                  onChange={(_e, checked) =>
                    onSubscribeToInsightsDatabaseChange(checked)
                  }
                />
              </FormFieldWrapper>
            </Stack>
            <Divider />
          </Stack>
          <AlertBar
            snackbar
            open={updateProfileStatus === RequestStatus.Failure}
            type={AlertBarType.Critical}
            text={translations.profileUpdateError}
            onClose={() => setUpdateProfileStatus(RequestStatus.Idle)}
          />
          <AlertBar
            snackbar
            open={updateProfileStatus === RequestStatus.Success}
            type={AlertBarType.Success}
            text={translations.profileUpdateSuccess}
            onClose={() => setUpdateProfileStatus(RequestStatus.Idle)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { Subscriptions };
