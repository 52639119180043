import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import * as someApi from 'api/cms/some';
import OnlyWithRole from 'components/OnlyWithRole';
import { ReactComponent as LinkedInLogo } from 'componentsNew/Icon/LinkedInLogo.svg';
import nicoImg from 'componentsNew/Icon/NicoDelvaux.svg';
import HTMLParser from 'html-react-parser';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import settings from 'settings';
import { translations } from 'translations';
import {
  GAonClickHomeSomeCykelbralla,
  GAonClickHomeSomePost,
} from 'utils/analytics';

const elementId = 'home-some';

interface SomePost {
  publishedDate?: string;
  postLinkUrl?: string;
  authorLinkUrl?: string;
  title?: string;
  body?: string;
  heroImage?: string;
  heroAltText?: string;
}

const { cdnTokenKey } = settings.misc;
const { cms } = settings.cmsBaseImagePath;

const Some = () => {
  const [somePost, setSomePost] = useState<SomePost | null>(null);

  const mountedRef = useRef<boolean>(true);

  const isExpired = useCallback((publishedDate: string) => {
    const expirationDate = new Date(publishedDate);
    expirationDate.setDate(expirationDate.getDate() + 7);
    return expirationDate < new Date();
  }, []);

  const formatText = useCallback((text: string) => {
    const textArr: string[] = [];
    HTMLParser(text, {
      replace: (node) => {
        if (node.type === 'text' && node.data !== ' ') {
          textArr.push(node.data);
        }
      },
    });
    text = textArr.join(' ').trim();
    return text.length > 200 ? `${text.substring(0, 200)}...` : text;
  }, []);

  useEffect(() => {
    async function fetchSomePost() {
      try {
        const response = await someApi.getSome();
        if (!response?.data?.data) return;
        const data = response.data.data as SomePost;
        if (!mountedRef.current) return;
        setSomePost(data);
      } catch {}
    }
    fetchSomePost();
  }, []);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (
    !somePost ||
    !somePost.publishedDate ||
    isExpired(somePost.publishedDate)
  ) {
    return null;
  }

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.informative,
        padding: theme.spacing('xxs'),
        borderRadius: theme.border.radius.md,
      })}
    >
      <Stack
        sx={(theme) => ({
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.sm,
          border: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
          padding: theme.spacing('xxs'),
        })}
        direction="row"
        justifyContent="space-between"
      >
        {somePost.body && (
          <Link
            variant="body2"
            id={`${elementId}-post-text-link`}
            href={somePost.postLinkUrl}
            target="_blank"
            referrerPolicy="no-referrer"
            onClick={() => GAonClickHomeSomePost(somePost.body)}
            sx={(theme) => ({
              wordBreak: 'break-word',
              paddingRight: theme.spacing('xs'),
              color: theme.colors.text.tertiary,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
                color: theme.colors.text.actionHover,
              },
            })}
          >
            {formatText(somePost.body)}
          </Link>
        )}
        <Link
          id={`${elementId}-post-image-link`}
          href={somePost.postLinkUrl}
          target="_blank"
          referrerPolicy="no-referrer"
          onClick={() => GAonClickHomeSomePost(somePost.body)}
          sx={{
            flexShrink: 0,
            alignSelf: 'baseline',
            width: '30%',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'block',
              aspectRatio: 1,
              objectFit: 'cover',
              borderRadius: theme.border.radius.sm,
            })}
            component="img"
            src={`${cms}${somePost.heroImage}/300?${cdnTokenKey}`}
            alt={somePost.heroAltText || ''}
            title={somePost.heroAltText || ''}
          />
        </Link>
      </Stack>
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: theme.spacing('xxs'),
        })}
      >
        <Box
          sx={() => ({
            display: 'flex',
            alignItems: 'center',
            gap: '14px',
          })}
        >
          <Avatar src={nicoImg} alt="" sx={{ width: 48, height: 48 }} />
          <Link
            id={`${elementId}-profile-link`}
            href={somePost.authorLinkUrl}
            target="_blank"
            referrerPolicy="no-referrer"
            onClick={GAonClickHomeSomeCykelbralla}
            sx={(theme) => ({
              textDecoration: 'none',
              color: theme.colors.text.actionHover,
            })}
          >
            {translations.followNicoDelvauxOnLinkedIn}
          </Link>
        </Box>
        <LinkedInLogo>{LinkedInLogo}</LinkedInLogo>
      </Stack>
      <OnlyWithRole viewableByRole="AvenueAdmin">
        <Link
          to="/some/edit"
          component={ReactRouterLink}
          sx={() => ({
            alignSelf: 'end',
            top: '-12px',
            left: '4px',
            height: 0,
            position: 'relative',
          })}
        >
          {translations.edit}
        </Link>
      </OnlyWithRole>
    </Stack>
  );
};

export { Some };
