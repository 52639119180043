import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

import { LatestArticlesSkeleton } from './LatestArticles/LatestArticlesSkeleton';
import { ShortcutsSkeleton } from './Shortcuts/ShortcutsSkeleton';

const HomeSkeleton = () => {
  const theme = useTheme();
  const GRID_SPACING = theme.spacing('md');
  const GRID_SPACING_MOBILE = theme.spacing('xl');
  const SIDE_SPACING_MOBILE = theme.spacing('sm');

  return (
    <Grid
      container
      spacing={{
        xs: GRID_SPACING_MOBILE,
        md: GRID_SPACING,
      }}
      sx={() => ({
        padding: {
          xs: `${GRID_SPACING} ${SIDE_SPACING_MOBILE}`,
          sm: GRID_SPACING,
          xxl: `${GRID_SPACING} 0`,
        },
      })}
    >
      <Grid xs={12} md={8}>
        <LatestArticlesSkeleton />
      </Grid>
      <Grid xs={12} md={4}>
        <Stack
          spacing={{
            xs: GRID_SPACING_MOBILE,
            md: GRID_SPACING,
          }}
        >
          <ShortcutsSkeleton />
        </Stack>
      </Grid>
    </Grid>
  );
};

export { HomeSkeleton };
