import { getWhitelistedUrls } from 'api/organization';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

interface WhitelistedUrlsContextType {
  urls: string[];
}

const WhitelistedUrlsContext = createContext<WhitelistedUrlsContextType | null>(
  null
);
const { Consumer: WhitelistedUrlsConsumer, Provider } = WhitelistedUrlsContext;

const WhitelistedUrlsProvider: FC<ReactNode> = ({ children }) => {
  const [whitelistedUrls, setWhitelistedUrls] =
    useState<WhitelistedUrlsContextType>({ urls: [] });

  useEffect(() => {
    (async () => {
      const response = await getWhitelistedUrls();
      if (response && response.status === 200) {
        setWhitelistedUrls(response.data.data);
      }
    })();
  }, []);
  return <Provider value={whitelistedUrls}>{children}</Provider>;
};

export const useWhitelistedUrls = () => {
  const context = useContext(WhitelistedUrlsContext);
  if (!context) {
    throw new Error('WhitelistedUrlsContext is not defined');
  }
  return context;
};

export { WhitelistedUrlsContext };
export default WhitelistedUrlsProvider;
export { WhitelistedUrlsConsumer };
