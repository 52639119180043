import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

export type ValidationResult = {
  valid: boolean;
  messages: string[];
};

const mandatory = (value: any, displayName: string): ValidationResult => {
  const valid = value !== null && value !== undefined && value !== '';
  if (valid) {
    return { valid, messages: [] };
  }
  const message = textUtils.replaceTranslationAliases(
    translations.formValidationRequired,
    { displayName }
  );
  return { valid, messages: [message] };
};

export const validators = { mandatory } as const;

export type ValidatorName = keyof typeof validators;

export const validateField = (
  value: any,
  validatorNames: ValidatorName[],
  displayName: string
): ValidationResult => {
  const result: ValidationResult = { valid: true, messages: [] };

  validatorNames.forEach((validatorName) => {
    const validatorFunction = validators[validatorName];
    const validatorResult = validatorFunction(value, displayName);
    if (!validatorResult.valid) {
      result.valid = false;
      result.messages = [...result.messages, ...validatorResult.messages];
    }
  });
  return result;
};
