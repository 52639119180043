import './EventsListBlockBox.scss';

import { getCalendarEvents } from 'api/events';
import EnhancedLink from 'components/EnhancedLink';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import { ReactComponent as Arrow } from 'components/Icon/Arrow.svg';
import { FC, ReactNode } from 'react';

import EventsListLink from '../EventsListLink';
import NoEventsListLink from '../NoEventListLink';
import ContentLoader from './ContentLoader';

interface EventsListBlockBoxProps {
  title: string;
  linkUrl: string;
  linkButtonText: string;
  id: string;
  showAllEvents: boolean;
  maxListedCount: string;
}

const onError = (status: number, message: any): ReactNode => {
  return (
    <Error
      message={status === 404 ? message['404'] : message['*']}
      status={status}
    />
  );
};

const EventsListBlockBox: FC<EventsListBlockBoxProps> = ({
  title,
  linkUrl,
  linkButtonText,
  showAllEvents,
  maxListedCount,
}) => {
  return (
    <Fetcher
      source={getCalendarEvents}
      useModelFactory={false}
      param={showAllEvents ? 0 : maxListedCount}
      onError={onError}
      onLoading={ContentLoader}
    >
      {({ response }: any) =>
        response.length > 0 ? (
          <div className="events-list-block-box">
            <div className="events-list-block-box__title-link-wrapper">
              <p className="events-list-block-box__title">{title}</p>

              <div className="events-list-block-box__links">
                {response.map((data: any) => (
                  <EventsListLink data={data} key={data.id} />
                ))}
              </div>

              {!showAllEvents ? (
                <EnhancedLink
                  style={{ textDecoration: 'none' }}
                  to={linkUrl}
                  className="events-list-block-box__link-button-wrapper"
                >
                  {' '}
                  <span className="events-list-block-box__link-button">
                    {linkButtonText}
                  </span>
                  <div className="events-list-block-box__arrow-wrapper">
                    <Arrow />
                  </div>
                </EnhancedLink>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="events-list-block-box">
            <div className="events-list-block-box__title-link-wrapper">
              <p className="events-list-block-box__title">{title}</p>
              <NoEventsListLink />
            </div>
          </div>
        )
      }
    </Fetcher>
  );
};

export default EventsListBlockBox;
