import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms } = settings.api;

export const getPublishedArticles = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/feed/published${query}`,
    errorMessages: {
      '*': 'Failed to get published articles, please contact support for assistance.',
    },
  });

export const getPublishedArticlesForAdmin = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/feed/published/admin${query}`,
    errorMessages: {
      '*': 'Failed to get published articles, please contact support for assistance.',
    },
  });

export const getDraftArticles = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/feed/draft${query}`,
    errorMessages: {
      '*': 'Failed to get draft articles, please contact support for assistance.',
    },
  });

export const getScheduledArticles = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/feed/scheduled${query}`,
    errorMessages: {
      '*': 'Failed to get scheduled articles, please contact support for assistance.',
    },
  });

export const getPublishedLibraryPages = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/published${query}`,
    errorMessages: {
      '*': 'Failed to get published pages, please contact support for assistance.',
    },
  });

export const getUnpublishedLibraryPages = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/unpublished${query}`,
    errorMessages: {
      '*': 'Failed to get unpublished pages, please contact support for assistance.',
    },
  });

export const getDraftLibraryPages = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/draft${query}`,
    errorMessages: {
      '*': 'Failed to get draft pages, please contact support for assistance.',
    },
  });

export const getPublishedLibraryPagesForAdmin = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/published/admin${query}`,
    errorMessages: {
      '*': 'Failed to get published pages, please contact support for assistance.',
    },
  });

export const getUnpublishedLibraryPagesForAdmin = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/unpublished/admin${query}`,
    errorMessages: {
      '*': 'Failed to get unpublished pages, please contact support for assistance.',
    },
  });

export const getDraftLibraryPagesForAdmin = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/draft/admin${query}`,
    errorMessages: {
      '*': 'Failed to get draft pages, please contact support for assistance.',
    },
  });

export const getOwnerlessLibraryPagesForAdmin = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/library/ownerless/admin${query}`,
    errorMessages: {
      '*': 'Failed to get ownerless pages, please contact support for assistance.',
    },
  });

export const unpublishLibraryPages = (ids: string[]) => {
  const idsParam = encodeURIComponent(JSON.stringify(ids));
  return createRequest({
    method: 'put',
    url: `${cms}/content-management/library/unpublish?ids=${idsParam}`,
    errorMessages: {
      '*': 'Failed to unpublish pages, please contact support for assistance.',
    },
  });
};

export const republishLibraryPages = (ids: string[]) => {
  const idsParam = encodeURIComponent(JSON.stringify(ids));
  return createRequest({
    method: 'put',
    url: `${cms}/content-management/library/republish?ids=${idsParam}`,
    errorMessages: {
      '*': 'Failed to republish pages, please contact support for assistance.',
    },
  });
};

export const renewLibraryPages = (ids: string[]) => {
  const idsParam = encodeURIComponent(JSON.stringify(ids));
  return createRequest({
    method: 'put',
    url: `${cms}/content-management/library/renew?ids=${idsParam}`,
    errorMessages: {
      '*': 'Failed to renew pages, please contact support for assistance.',
    },
  });
};

export const reassignLibraryPages = (
  ids: string[],
  user: { name: string; oid: string }
) => {
  const idsParam = encodeURIComponent(JSON.stringify(ids));
  const userParam = encodeURIComponent(JSON.stringify(user));

  return createRequest({
    method: 'put',
    url: `${cms}/content-management/library/reassign?ids=${idsParam}&user=${userParam}`,
    errorMessages: {
      '*': 'Failed to reassign pages, please contact support for assistance.',
    },
  });
};

export const deleteLibraryPages = (ids: string[]) => {
  const idsParam = encodeURIComponent(JSON.stringify(ids));
  return createRequest({
    method: 'delete',
    url: `${cms}/content-management/library?ids=${idsParam}`,
    errorMessages: {
      '*': 'Failed to delete pages, please contact support for assistance.',
    },
  });
};

export const unpublishArticles = (ids: string[]) => {
  const idsParam = encodeURIComponent(JSON.stringify(ids));
  return createRequest({
    method: 'put',
    url: `${cms}/content-management/feed/unpublish?ids=${idsParam}`,
    errorMessages: {
      '*': 'Failed to unpublish articles, please contact support for assistance.',
    },
  });
};

export const getPublishedInsightsDatabaseArticles = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/insights-database/published${query}`,
    errorMessages: {
      '*': 'Failed to get published insights database articles, please contact support for assistance.',
    },
  });

export const getDraftInsightsDatabaseArticles = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/insights-database/draft${query}`,
    errorMessages: {
      '*': 'Failed to get draft insights database articles, please contact support for assistance.',
    },
  });

export const getBanners = (query: string) =>
  createRequest({
    method: 'get',
    url: `${cms}/content-management/banners${query}`,
    errorMessages: {
      '*': 'Failed to get banners, please contact support for assistance.',
    },
  });
