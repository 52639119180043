import isProduction from 'utils/misc/isProduction';

enum GlobalWebcastTestParams {
  GlobalWebcastTestEnabled = 'global_webcast_test_enabled',
  GlobalWebcastIframeUrl = 'global_webcast_iframe_url',
}

const getTestParamsFromUrl = () => {
  if (isProduction()) {
    return { enabled: false, iframeUrl: null };
  }
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const enabled =
    params.get(GlobalWebcastTestParams.GlobalWebcastTestEnabled) === 'true';

  const iframeUrl = params.get(GlobalWebcastTestParams.GlobalWebcastIframeUrl);

  const isValidIframeUrl =
    iframeUrl && new URL(iframeUrl).host.endsWith('creomediamanager.com');

  return {
    enabled,
    iframeUrl: isValidIframeUrl ? iframeUrl : null,
  };
};

export { getTestParamsFromUrl };
