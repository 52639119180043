import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import { ReactNode } from 'react';

import { Consumer } from '..';
import DeleteButton from './DeleteButton';
import PublishButton from './PublishButton';
import SaveAsDraftButton from './SaveAsDraftButton';

interface PublishOptionsOnlyProps {
  children?: ReactNode;
  onCancel?: string;
  subject?: string;
  hideDelete?: boolean;
  deleteModalChildren?: boolean;
  deleteButtonText?: 'Unpublish' | 'Delete';
  showDraftButton?: boolean;
  showTitle?: boolean;
}

const yesButton = {
  color: 'black',
  title: 'Yes',
};

const noButton = {
  color: 'white',
  title: 'No',
};

const PublishOptionsOnly = ({
  children,
  onCancel,
  subject,
  hideDelete,
  deleteModalChildren,
  deleteButtonText,
  showDraftButton,
}: PublishOptionsOnlyProps) => (
  <Consumer>
    {({
      updatedArticle: {
        publishedInDivisions,
        publishedInSegments,
        publishedInCountries,
        publishedInDepartments,
        publishedInSites,
        publishedInCompanies,
      },
      isCreating,
      onDeleteArticle,
      onSaveArticle,
      onAfterCancel,
    }) => (
      <>
        {children ? <>{children}</> : <></>}
        <div className="article-button--wrapper">
          <PublishButton
            countries={publishedInCountries}
            divisions={publishedInDivisions}
            segments={publishedInSegments}
            departments={publishedInDepartments}
            sites={publishedInSites}
            companies={publishedInCompanies}
            onSaveArticle={() => onSaveArticle(false)}
            isCreating={isCreating || showDraftButton}
            buttonStyle="article-button--wrapper--publish"
          />
          {showDraftButton && (
            <SaveAsDraftButton
              countries={publishedInCountries}
              divisions={publishedInDivisions}
              segments={publishedInSegments}
              departments={publishedInDepartments}
              sites={publishedInSites}
              companies={publishedInCompanies}
              onSaveArticle={() => onSaveArticle(true)}
              buttonStyle="article-button--wrapper--saveasdraft"
            />
          )}
          <ButtonModalWrapper
            title="Are you sure you want to leave this page before saving?"
            modalChildren={null}
            yesButton={yesButton}
            noButton={noButton}
          >
            <Button
              onClick={onAfterCancel}
              className="article-button--wrapper--cancel"
              color="transparent"
            >
              Cancel
            </Button>
          </ButtonModalWrapper>
          {hideDelete !== true && showDraftButton !== true && (
            <DeleteButton
              onDeleteArticle={onDeleteArticle}
              modalChildren={deleteModalChildren}
              onCancel={onCancel}
              isCreating={isCreating}
              subject={subject}
              buttonStyle="article-button--wrapper--delete"
              text={deleteButtonText}
            />
          )}
        </div>
      </>
    )}
  </Consumer>
);

export default PublishOptionsOnly;
