import * as newsletterBannerApi from 'api/cms/newsletterBanner';
import { NewsletterBannerGetResponseData } from 'api/cms/newsletterBanner/types';
import { AlertDialog } from 'componentsNew';
import { AlertDialogType } from 'componentsNew/AlertDialog';
import { AvenueRoute } from 'constants/routes';
import { useSnackbar } from 'contextNew/Snackbar';
import { Page } from 'layoutNew';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'translations';
import * as attachmentUtils from 'utils/cms/attachment';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import {
  DEFAULT_VALUES,
  FormValues,
  NewsletterBannerForm,
} from './NewsletterBannerForm';

const NewsletterBannerEdit = () => {
  const [originalData, setOriginalData] =
    useState<NewsletterBannerGetResponseData | null>(null);

  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    isLoading: boolean;
  }>({ open: false, isLoading: false });

  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const { showSnackbar } = useSnackbar();

  const getDefaultValues = useCallback(async () => {
    try {
      if (!params.id) {
        throw Error();
      }
      const response = await newsletterBannerApi.getNewsletterBanner(params.id);
      const data: NewsletterBannerGetResponseData | undefined =
        response?.data?.data;

      if (!data) {
        throw Error();
      }
      setOriginalData(data);
      return helpers.transformGetResponseToFormValues(data);
    } catch (e: any) {
      if (e?.response?.status === 404) {
        history.push(AvenueRoute.NotFound);
      } else {
        showSnackbar({
          type: 'error',
          text: translations.newsletterBannerGetError,
        });
      }
      return DEFAULT_VALUES;
    }
  }, [history, params.id, showSnackbar]);

  const methods = useForm<FormValues>({
    defaultValues: async () => await getDefaultValues(),
    shouldFocusError: true,
  });

  const { reset, setError, setValue, getValues } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      if (!originalData) {
        setFormError(translations.newsletterBannerEditError);
        return;
      }

      if (formValues.heroImage.raw) {
        try {
          formValues.heroImage.uploaded = await attachmentUtils.uploadImage(
            formValues.heroImage.raw
          );
        } catch {
          setFormError(translations.imageUploadError);
          return;
        }
      }
      const oldHeroImageSrc =
        originalData.heroImage &&
        originalData.heroImage !== formValues.heroImage.uploaded
          ? originalData.heroImage
          : null;

      try {
        const payload = {
          ...helpers.transformFormValuesToCreateOrEditPayload(formValues),
          id: originalData.id,
        };
        const response = await newsletterBannerApi.updateNewsletterBanner(
          payload
        );
        const newOriginalData: NewsletterBannerGetResponseData | undefined =
          response?.data?.data;

        if (!newOriginalData) {
          throw Error();
        }
        const newFormValues =
          helpers.transformGetResponseToFormValues(newOriginalData);

        reset(newFormValues);
        setOriginalData(newOriginalData);
        showSnackbar({
          type: 'success',
          text: translations.newsletterBannerEditSuccess,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch {
        if (
          formValues.heroImage.uploaded &&
          formValues.heroImage.uploaded !== oldHeroImageSrc
        ) {
          try {
            await attachmentUtils.deleteImage(formValues.heroImage.uploaded);
          } catch {}
        }
        setFormError(translations.newsletterBannerEditError);
        return;
      }

      if (oldHeroImageSrc) {
        try {
          await attachmentUtils.deleteImage(oldHeroImageSrc);
        } catch {}
      }
    },
    [originalData, reset, setFormError, showSnackbar]
  );

  const handleDelete = useCallback(async () => {
    try {
      if (!originalData) {
        throw Error();
      }
      setDeleteDialog({ ...deleteDialog, isLoading: true });
      if (originalData.heroImage) {
        await attachmentUtils.deleteImage(originalData.heroImage);
      }
      await newsletterBannerApi.deleteNewsletterBanner(originalData.id);
      showSnackbar({
        type: 'success',
        text: translations.newsletterBannerDeleteSuccess,
      });
      history.push(AvenueRoute.ProfileManageBanners);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.newsletterBannerDeleteError,
      });
    } finally {
      setDeleteDialog({ open: false, isLoading: false });
    }
  }, [deleteDialog, history, originalData, showSnackbar]);

  return (
    <Page
      title={[translations.newsletterBanner, translations.edit]}
      breadcrumbs={[
        {
          text: translations.pageTitleHome,
          to: AvenueRoute.Home,
        },
        {
          text: translations.newsletterBanner,
          to: AvenueRoute.ProfileManageBanners,
        },
        { text: translations.edit },
      ]}
    >
      <FormProvider {...methods}>
        <NewsletterBannerForm
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          onDelete={() => setDeleteDialog({ ...deleteDialog, open: true })}
        />
      </FormProvider>
      <AlertDialog
        open={deleteDialog.open}
        type={AlertDialogType.Critical}
        title={translations.newsletterBannerDeleteTitle}
        paragraphs={[translations.newsletterBannerDeleteText]}
        primaryButton={{
          loading: deleteDialog.isLoading,
          disabled: deleteDialog.isLoading,
          text: translations.delete,
          onClick: handleDelete,
        }}
        secondaryButton={{
          disabled: deleteDialog.isLoading,
          text: translations.cancel,
          onClick: () => setDeleteDialog({ ...deleteDialog, open: false }),
        }}
      />
    </Page>
  );
};

export { NewsletterBannerEdit };
