import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {
  GlobalWebcastMode,
  useGlobalWebcast,
} from 'components/Context/GlobalWebcast';
import { useMemo } from 'react';
import { translations } from 'translations';

import { GlobalWebcastLinkBox } from './GlobalWebcastLinkBox';

const FALLBACK_IFRAME_HEIGHT = 960;

const GlobalWebcast = () => {
  const {
    isGlobalWebcastActivated,
    isGlobalWebcastIframeMessageLoading,
    isGlobalWebcastBlockingHomeFeed,
    iframeUrl,
    iframeLayoutMode,
    iframeHeight,
  } = useGlobalWebcast();

  const containerMinHeight = useMemo(() => {
    if (
      isGlobalWebcastBlockingHomeFeed &&
      !isGlobalWebcastIframeMessageLoading
    ) {
      // If the home feed has not been loaded we set the
      // webcast to cover the page from header to footer
      const headerElement = document.getElementById('avenue-header');
      const headerHeight = headerElement ? headerElement.clientHeight : 0;
      const footerElement = document.getElementById('avenue-footer');
      const footerHeight = footerElement ? footerElement.clientHeight : 0;
      return `calc(100vh - ${headerHeight + footerHeight}px)`;
    }
    // If the home feed has been loaded, we make sure the
    // webcast banner covers the top blue background on home
    return { xs: '405px', xl: '370px' };
  }, [isGlobalWebcastBlockingHomeFeed, isGlobalWebcastIframeMessageLoading]);

  const isLinkBoxVisible = useMemo(
    () =>
      !isGlobalWebcastIframeMessageLoading && isGlobalWebcastBlockingHomeFeed,
    [isGlobalWebcastBlockingHomeFeed, isGlobalWebcastIframeMessageLoading]
  );

  const isRefreshButtonVisible = useMemo(
    () =>
      !isGlobalWebcastIframeMessageLoading &&
      isGlobalWebcastBlockingHomeFeed &&
      iframeLayoutMode === GlobalWebcastMode.PostLive,
    [
      isGlobalWebcastIframeMessageLoading,
      isGlobalWebcastBlockingHomeFeed,
      iframeLayoutMode,
    ]
  );

  if (!isGlobalWebcastActivated || !iframeUrl) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        overflow: 'hidden',
        background: theme.colors.surface.actionPrimaryDefault,
        // To make blue background float from left
        // to right (beyond its container width)
        width: '200%',
        marginLeft: '-50%',
        marginRight: '-50%',
        minHeight: containerMinHeight,
      })}
    >
      <Box
        sx={{
          width: '50%',
          margin: 'auto',
          padding: '2rem 1rem',
        }}
      >
        <Box
          sx={() => ({
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0 auto',
            maxWidth:
              iframeLayoutMode === GlobalWebcastMode.PreLive ||
              iframeLayoutMode === GlobalWebcastMode.PostLive
                ? '1024px'
                : '900px',
          })}
        >
          {isGlobalWebcastIframeMessageLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                color: 'white',
                flexShrink: 0,
                margin: 'auto',
                left: 'calc(50% - 12px)',
              }}
            />
          )}
          <iframe
            title="Avenue Global Webcast"
            allowFullScreen={true}
            src={iframeUrl}
            style={{
              border: 'none',
              minWidth: '1px',
              width: '100%',
              height: `${
                isGlobalWebcastIframeMessageLoading
                  ? 0
                  : iframeHeight
                  ? iframeHeight + 30
                  : FALLBACK_IFRAME_HEIGHT
              }px`,
            }}
          />
          {isLinkBoxVisible && <GlobalWebcastLinkBox />}
          {isRefreshButtonVisible && (
            <Button
              variant="contained"
              sx={{ margin: '1rem 0', alignSelf: 'baseline' }}
              onClick={() => document.location.reload()}
            >
              {translations.globalWebcastRefresh}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { GlobalWebcast };
