import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as InformationCircleFilledIcon } from 'componentsNew/Icon/InformationCircleFilled.svg';
import { TypographyWithLink } from 'componentsNew/TypographyWithLink';
import { translations } from 'translations';
import { GAonAIFeedbackClick } from 'utils/analytics';

const AIChatDisclaimerWithFeedbackLink = () => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xxs'),
        color: theme.colors.text.tertiary,
        '> svg': {
          flexShrink: 0,
          '> path': { fill: theme.colors.icon.brandLight },
        },
      })}
    >
      <InformationCircleFilledIcon height={20} width={20} />
      <Stack>
        <Typography variant="caption">
          {translations.aiChatResultInfoText}
        </Typography>
        <TypographyWithLink
          variant="caption"
          target="_blank"
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=ycG98EhRhk-sQO3ZduGBTPyMUsgW0WBMjdyPw1IVrlBURThXVkRaVEowMzNTOVJQNjlQNVBRWEhBTy4u"
          onClick={GAonAIFeedbackClick}
        >
          {translations.aiChatResultInfoTextWithFeedbackLink}
        </TypographyWithLink>
      </Stack>
    </Stack>
  );
};

export { AIChatDisclaimerWithFeedbackLink };
