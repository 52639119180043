import { UserContext } from 'components/Context/User';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { GAonPageTitleChange } from 'utils/analytics';

const setTitle = ({ titles }) => {
  const title = ['Avenue', ...titles].filter(Boolean).join(' | ');

  if (document.title !== title) {
    document.title = title;
    GAonPageTitleChange(title, window.location.pathname);
  }
};

class PageTitle extends PureComponent {
  componentDidMount() {
    setTitle(this.props, this.context);
  }

  componentDidUpdate() {
    setTitle(this.props, this.context);
  }

  render() {
    return null;
  }
}

PageTitle.propTypes = {
  titles: PropTypes.array.isRequired,
  track: PropTypes.bool,
};

PageTitle.defaultProps = {
  track: true,
};

PageTitle.contextType = UserContext;

export default PageTitle;
