import { translations } from 'translations';

import {
  ManageInsightsDatabaseTableField,
  ManageInsightsDatabaseTableItem,
  SortOrder,
} from './ManageInsightsDatabaseTable';

const getFormattedDate = (dateStr: string, includeTime?: boolean) => {
  const date = new Date(dateStr);
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    return translations.notAvailable;
  }

  return includeTime
    ? date.toLocaleString(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
      })
    : date.toLocaleDateString();
};

const sortTableItems = (
  items: ManageInsightsDatabaseTableItem[],
  sortField: ManageInsightsDatabaseTableField,
  sortOrder: SortOrder
) => {
  switch (sortField) {
    case ManageInsightsDatabaseTableField.Title:
      return items.sort((a, b) => {
        const _a = a.title || '';
        const _b = b.title || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageInsightsDatabaseTableField.PublishedDate:
      return items.sort((a, b) => {
        const _a = new Date(a.publishedDate || '');
        const _b = new Date(b.publishedDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageInsightsDatabaseTableField.ModifiedDate:
      return items.sort((a, b) => {
        const _a = new Date(a.modifiedDate || '');
        const _b = new Date(b.modifiedDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageInsightsDatabaseTableField.InformationOwner:
      return items.sort((a, b) => {
        const _a = a.informationOwner?.name || '';
        const _b = b.informationOwner?.name || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    default:
      return items;
  }
};

const getArticleRequestQueryParams = (
  sort: { field: ManageInsightsDatabaseTableField; order: SortOrder },
  pagination: { page: number; rowsPerPage: number }
) => {
  let sortParam = `sort=`;

  if (sort.order === SortOrder.Desc) {
    sortParam = `${sortParam}-`;
  }
  switch (sort.field) {
    case ManageInsightsDatabaseTableField.Title:
      sortParam = `${sortParam}title`;
      break;
    case ManageInsightsDatabaseTableField.PublishedDate:
      sortParam = `${sortParam}publishedDate`;
      break;
    case ManageInsightsDatabaseTableField.ModifiedDate:
      sortParam = `${sortParam}modifiedDate`;
      break;
    case ManageInsightsDatabaseTableField.InformationOwner:
      sortParam = `${sortParam}informationOwner`;
      break;
  }
  const limitParam = `page[limit]=${pagination.rowsPerPage}`;
  const offsetParam = `page[offset]=${pagination.page - 1}`;
  return `?${sortParam}&${limitParam}&${offsetParam}`;
};

export { getArticleRequestQueryParams, getFormattedDate, sortTableItems };
