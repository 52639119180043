import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { ReactComponent as BulletIcon } from 'componentsNew/Icon/Bullet.svg';

type BadgeProps = { type?: 'label' | 'status' } & MuiChipProps;

const Badge = (props: BadgeProps) => {
  return (
    <MuiChip
      {...props}
      {...(props.type === 'status' && { icon: <BulletIcon /> })}
      sx={[
        (theme) => ({
          '&.MuiChip-sizeSmall': {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            '.MuiChip-icon': {
              height: '10px',
              width: '10px',
              marginLeft: '8px',
            },
            '.MuiChip-icon:has(ellipse)': {
              height: '6px',
              width: '6px',
            },
          },
          '&.MuiChip-sizeMedium': {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            '.MuiChip-icon': {
              height: '13px',
              width: '13px',
              marginLeft: '12px',
            },
            '.MuiChip-icon:has(ellipse)': {
              height: '8px',
              width: '8px',
            },
          },
          '&.MuiChip-filled': {
            '&.MuiChip-filledDefault': {
              color: theme.colors.text.inversePrimary,
              backgroundColor: theme.colors.surface.inverseSecondary,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.text.inversePrimary,
              },
            },
            '&.MuiChip-filledPrimary': {
              color: theme.colors.text.inversePrimary,
              backgroundColor: theme.colors.surface.actionPrimaryDefault,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.text.inversePrimary,
              },
            },
            '&.MuiChip-filledSuccess': {
              color: theme.colors.text.inversePrimary,
              backgroundColor: theme.colors.icon.success,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.text.inversePrimary,
              },
            },
            '&.MuiChip-filledWarning': {
              color: theme.colors.text.primary,
              backgroundColor: theme.colors.icon.warning,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.text.primary,
              },
            },
            '&.MuiChip-filledError': {
              color: theme.colors.text.inversePrimary,
              backgroundColor: theme.colors.icon.critical,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.text.inversePrimary,
              },
            },
          },
          '&.MuiChip-outlined': {
            '&.MuiChip-outlinedDefault': {
              color: theme.colors.text.secondary,
              backgroundColor: theme.colors.surface.secondary,
              borderColor: theme.colors.border.surfacePrimary,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.icon.secondary,
              },
            },
            '&.MuiChip-outlinedPrimary': {
              color: theme.colors.text.brand,
              backgroundColor: theme.colors.surface.informative,
              borderColor: theme.colors.border.surfaceInformative,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.icon.brandBase,
              },
            },
            '&.MuiChip-outlinedSuccess': {
              color: theme.colors.text.success,
              backgroundColor: theme.colors.surface.success,
              borderColor: theme.colors.border.surfaceSuccess,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.icon.success,
              },
            },
            '&.MuiChip-outlinedWarning': {
              color: theme.colors.text.warning,
              backgroundColor: theme.colors.surface.warning,
              borderColor: theme.colors.border.surfaceWarning,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.icon.warning,
              },
            },
            '&.MuiChip-outlinedError': {
              color: theme.colors.text.critical,
              backgroundColor: theme.colors.surface.critical,
              borderColor: theme.colors.border.surfaceCritical,
              '.MuiChip-icon > path, .MuiChip-icon > ellipse': {
                fill: theme.colors.icon.critical,
              },
            },
          },
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};

export { Badge };
