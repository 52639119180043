import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

import { AlertFormData } from './types';

const { cms } = settings.api;
const route = `${cms}/alert`;

export const getActiveAlerts = (data: {
  divisionId: string;
  countryId?: string;
}) => {
  const { divisionId, countryId } = data;
  return createRequest({
    method: 'get',
    url: `${route}/${divisionId}${countryId ? `/${countryId}` : ''}`,
    errorMessages: {
      '*': 'Failed to get Alerts, please contact support for assistance.',
    },
  });
};

export const getAllAlerts = () => {
  return createRequest({
    method: 'get',
    url: route,
    errorMessages: {
      '*': 'Failed to get Alerts, please contact support for assistance.',
    },
  });
};

export const createAlert = (data: AlertFormData) => {
  return createRequest({
    method: 'post',
    url: route,
    data,
    errorMessages: {
      '*': 'Failed to create Alert, please contact support for assistance.',
    },
  });
};

export const deleteAlert = (id: string) => {
  return createRequest({
    method: 'delete',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to get Alerts, please contact support for assistance.',
    },
  });
};
