import './UserMenu.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { UserConsumer } from 'components/Context/User';
import OnlyWithRole from 'components/OnlyWithRole';
import UserImage from 'components/UserImage';
import { KeyboardKey } from 'constants/keyboard';
import { AvenueRoute } from 'constants/routes';
import { useState } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { logout } from 'utils/auth';

const UserMenu = () => {
  const userButtonRef = useRef<HTMLButtonElement | null>(null);

  const [open, setOpen] = useState(false);
  const [userInfoBoxOpen, setUserInfoBoxOpen] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (open && e.key === KeyboardKey.Escape) {
      e.stopPropagation();
      if (userButtonRef.current) {
        userButtonRef.current.focus();
      }
      toggleMenu();
    }
  };

  const toggleMenu = () => {
    setUserInfoBoxOpen(false);
    setOpen(!open);
  };

  const closeMenu = () => setOpen(false);

  const InfoMessageBox = ({
    display,
    displayName,
    divisionName,
    countryName,
  }: any) => {
    if (!display) return null;
    return (
      <div className="user-menu__info-box">
        <p className="user-menu__info-box--name">{displayName}</p>
        <p className="user-menu__info-box--location">
          {divisionName} | {countryName}
        </p>
      </div>
    );
  };

  return (
    <UserConsumer>
      {({ displayName, imageUrl, initials, countryName, divisionName }) => (
        <div className="user-menu" onKeyDown={handleKeyDown}>
          <InfoMessageBox
            display={userInfoBoxOpen}
            displayName={displayName}
            divisionName={divisionName}
            countryName={countryName}
          />
          <button
            className="user-menu__button"
            onClick={toggleMenu}
            aria-expanded={open}
            aria-haspopup="true"
            aria-label="Toggle profile menu"
            ref={userButtonRef}
            onMouseEnter={() => setUserInfoBoxOpen(true)}
            onMouseLeave={() => setUserInfoBoxOpen(false)}
          >
            <div className="user-menu__user">
              <UserImage
                src={imageUrl}
                name={displayName}
                initials={initials}
              />
            </div>
          </button>

          {open ? (
            <ClickAwayListener onClickAway={toggleMenu}>
              <div className="user-menu__popup">
                <div className="user-menu__details__wrapper">
                  <div className="user-menu__details__container">
                    <p className="user-menu__details--name">{displayName}</p>
                    <p className="user-menu__details--location">
                      {divisionName} | {countryName}
                    </p>
                  </div>
                </div>
                <ul className="user-menu__items">
                  <li className="user-menu__item">
                    <Link
                      className="user-menu__link"
                      to={AvenueRoute.Profile}
                      onClick={closeMenu}
                    >
                      Profile Settings
                    </Link>
                  </li>
                  <li className="user-menu__item">
                    <Link
                      className="user-menu__link"
                      to={AvenueRoute.ProfileManageLibrary}
                      onClick={closeMenu}
                    >
                      Lifecycle Management
                    </Link>
                  </li>
                  <OnlyWithRole
                    isAllRolesRequired={false}
                    viewableByRole={[
                      'AvenueNotificationEditor',
                      'AvenueSuperuser',
                    ]}
                  >
                    <li className="user-menu__item">
                      <Link
                        className="user-menu__link"
                        to="/alert"
                        onClick={closeMenu}
                      >
                        Alerts
                      </Link>
                    </li>
                  </OnlyWithRole>
                  <hr className="user-menu__divider" />
                  <li className="user-menu__item">
                    <span className="user-menu__link" onClick={logout}>
                      Log Out
                    </span>
                  </li>
                </ul>
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
      )}
    </UserConsumer>
  );
};

export default UserMenu;
