import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Tooltip } from 'componentsNew';
import { ReactComponent as ChatBubbleIcon } from 'componentsNew/Icon/ChatBubble.svg';
import { ReactComponent as ChatBubbleFilledIcon } from 'componentsNew/Icon/ChatBubbleFilled.svg';
import { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

type CommentButtonProps = {
  id: string;
  commentsCount: number;
  isCommentedByMe: boolean;
  to?: string;
  href?: string;
  sx?: SxProps<Theme>;
  color?: string;
  onClick?: () => void;
};

const CommentButton = ({
  id,
  commentsCount,
  isCommentedByMe,
  to,
  href,
  sx,
  color,
  onClick,
}: CommentButtonProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const useFilledIcon = isCommentedByMe || isHovering;

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xxxs'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Tooltip title={translations.comment}>
        <IconButton
          {...(to ? { to: to, component: ReactRouterLink } : { href: href })}
          onClick={onClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          id={`${id}-button`}
          sx={(theme) => ({
            padding: 0,
            '> svg > path': {
              ...(useFilledIcon && {
                fill: color || theme.colors.icon.brandBase,
              }),
              ...(!useFilledIcon &&
                commentsCount > 0 && {
                  stroke: color || theme.colors.icon.brandBase,
                }),
            },
            '&:hover': {
              backgroundColor: 'unset',
              '> svg > path': {
                fill: color || theme.colors.icon.brandBase,
                ...(!useFilledIcon && {
                  stroke: color || theme.colors.icon.brandBase,
                }),
              },
            },
          })}
        >
          {useFilledIcon ? <ChatBubbleFilledIcon /> : <ChatBubbleIcon />}
        </IconButton>
      </Tooltip>
      <Typography
        id={`${id}-count`}
        variant="caption"
        sx={(theme) => ({
          color: color || theme.colors.text.tertiary,
        })}
      >
        {commentsCount}
      </Typography>
    </Stack>
  );
};

export { CommentButton };
